import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getConfigsByCompany = createAsyncThunk('report/get', async () => {
    return axios
        .get(getApiEndpoint('report', `config`))
        .then(res => res.data);
});

export const insertConfig = createAsyncThunk('report/post', async (config) => {
    const response = await axios.post(getApiEndpoint('report', 'config'), config);
    return response.data;
});

export const deleteConfig = createAsyncThunk('report/config', async (id) => {
    console.log(id);
    return axios
        .delete(getApiEndpoint('report', `config/${id}`))
        .then(res => res.json());
});

const reportSlice = createSlice({
    name: 'report',
    initialState: {
        configs: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConfigsByCompany.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getConfigsByCompany.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.configs = action.payload;
            })
            .addCase(getConfigsByCompany.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(insertConfig.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(insertConfig.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.configs.push(action.payload);
            })
            .addCase(insertConfig.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(deleteConfig.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteConfig.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.configs.push(action.payload);
            })
            .addCase(deleteConfig.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default reportSlice.reducer;
