import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getListChartData = createAsyncThunk('listChart/getListChartData', async () => {
	return axios
		.get(getApiEndpoint('stats', `listcharts`))
		.then(res => res.data);
});

export const getListChartDataByCompanyId = createAsyncThunk('listChart/getListChartDataByCompanyId', async (companyId) => {
	return axios
		.get(getApiEndpoint('stats', `listchartsbycompany/${companyId}`))
		.then(res => res.data);
});

export const resetListChartData = createAction('listChart/resetListChartData');

const listChartSlice = createSlice({
	name: 'listChart',
	initialState: {
		loading: false,
		data: [],
		error: '',
		isSuccess: ''
	},
	extraReducers: builder => {
		builder.addCase(getListChartData.pending, state => {
			state.loading = true;
		});

		builder.addCase(getListChartData.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.error = '';
		});

		builder.addCase(getListChartData.rejected, (state, action) => {
			state.loading = false;
			state.data = [];
			state.error = action.error.message;
		});

		builder.addCase(getListChartDataByCompanyId.pending, state => {
			state.loading = true;
		});

		builder.addCase(getListChartDataByCompanyId.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
			state.error = '';
		});

		builder.addCase(getListChartDataByCompanyId.rejected, (state, action) => {
			state.loading = false;
			state.data = [];
			state.error = action.error.message;
		});

		builder.addCase(resetListChartData, state => {
			state.loading = false;
            state.data = [];
        });
	}
});

export default listChartSlice.reducer;
