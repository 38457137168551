import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "../../icons";
import { useSelector } from 'react-redux';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import "./NumberDetails.css";

const NumberDetails = ({ title, onCloseModal }) => {
    const navigate = useNavigate();
    const loading = useSelector(state => state.dashboardPhones.loading);
    const spamNumbers = useSelector(state => state.dashboardPhones.spamNumbers);
    const ftcFlaggedNumbers = useSelector(state => state.dashboardPhones.ftcFlaggedNumbers);
    const [numbers, setNumbers] = useState(null);
    const [isPhoneAscending, setPhoneAscending] = useState(false);
    const [isStateAscending, setStateAscending] = useState(false);

    useEffect(() => {
        setNumbers(spamNumbers);
    }, [spamNumbers]);

    useEffect(() => {
        setNumbers(ftcFlaggedNumbers);
    }, [ftcFlaggedNumbers]);

    const sortByPhone = () => {
        const sortedData = [...numbers].sort((a, b) => {
            return isPhoneAscending ? a.tfn - b.tfn : b.tfn - a.tfn;
        });

        setPhoneAscending(!isPhoneAscending);
        setNumbers(sortedData);
    };

    const sortByState = () => {
        const sortedData = [...numbers].sort((a, b) => {
            return isStateAscending
                ? a.state.localeCompare(b.state)
                : b.state.localeCompare(a.state);
        });

        setStateAscending(!isStateAscending);
        setNumbers(sortedData);
    };

    const redirectNumber = number => {
        navigate(`/phone/${number}`);
    };    

    return(
        <div className="numbers-details-modal">
            {loading ? (
                 <Loader />
            ) : (
                <div className="numbers-details-content">
                    <div className="numbers-details-header">
                        <div className="numbers-details-header-title">
                            <span className="title">{title}</span>
                        </div>
                        <button className="numbers-details-close-button" onClick={() => onCloseModal()}>
                            <IoMdCloseCircle />
                        </button>
                    </div>
                    {numbers?.length > 0 ? (
                        <div className="table">
                            <table className="dash-table">
                                <thead>
                                    <tr>
                                        <th onClick={sortByPhone}>Phone Number {isPhoneAscending ? '▲' : '▼'}</th>
                                        <th onClick={sortByState}>State {isStateAscending ? '▲' : '▼'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {numbers?.map((value, key) => {
                                        return (
                                            <tr key={key} onClick={() => redirectNumber(value.tfn)}>
                                                <td>{value.tfn}</td>
                                                <td>{value.state}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="state-empty">This carrier does not have any spam marked numbers.</div>
                    )}
                </div>
            )}
        </div>
    )
}

export default NumberDetails;