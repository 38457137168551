import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getUsaMapData = createAsyncThunk('usaMapData/getUsaMapData', async () => {
    return axios
        .get(getApiEndpoint('stats', `usa`))
        .then(res => res.data);
});

export const getUsaMapDataByCompany = createAsyncThunk('usaMapData/getUsaMapDataByCompany', async (compnyId) => {
    return axios
        .get(getApiEndpoint('stats', `usabycompany/${compnyId}`))
        .then(res => res.data);
});


export const getUsaMapStateData = createAsyncThunk('usaMapData/getUsaMapStateData', async (state) => {
    return axios
        .get(getApiEndpoint('stats', `usa/state/${state}`))
        .then(res => res.data);
});

export const getUsaMapStateDataByCompany = createAsyncThunk('usaMapData/getUsaMapStateDataByCompany', async ({data}) => {
    return axios
        .get(getApiEndpoint('stats', `usa/statebycompany/${data.companyId}/${data.state}`))
        .then(res => res.data);
});

export const resetStateData = createAction('dashboardPhones/resetStateData');

const usaMapDataSlice = createSlice({
    name: 'usaMapData',
    initialState: {
        loading: false,
        data: [],
        stateData: [],
        error: '',
        isSuccess: ''
    },
    extraReducers: builder => {
        builder.addCase(getUsaMapData.pending, state => {
            state.loading = true;
        });

        builder.addCase(getUsaMapData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = '';
        });

        builder.addCase(getUsaMapData.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });

        builder.addCase(getUsaMapDataByCompany.pending, state => {
            state.loading = true;
        });

        builder.addCase(getUsaMapDataByCompany.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = '';
        });

        builder.addCase(getUsaMapDataByCompany.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });

        builder.addCase(getUsaMapStateData.pending, state => {
            state.loading = true;
        });

        builder.addCase(getUsaMapStateData.fulfilled, (state, action) => {
            state.loading = false;
            state.stateData = action.payload;
            state.error = '';
        });

        builder.addCase(getUsaMapStateData.rejected, (state, action) => {
            state.loading = false;
            state.stateData = [];
            state.error = action.error.message;
        });

        builder.addCase(getUsaMapStateDataByCompany.pending, state => {
            state.loading = true;
        });

        builder.addCase(getUsaMapStateDataByCompany.fulfilled, (state, action) => {
            state.loading = false;
            state.stateData = action.payload;
            state.error = '';
        });

        builder.addCase(getUsaMapStateDataByCompany.rejected, (state, action) => {
            state.loading = false;
            state.stateData = [];
            state.error = action.error.message;
        });

        builder.addCase(resetStateData, state => {
            state.loading = true;
            state.stateData = [];
        });
    }
});

export default usaMapDataSlice.reducer;
