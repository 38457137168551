import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, insertNotificationConfiguration } from "../../slice/notificationsSlice";
import './NotificationsComponent.css';
import Loader from "../Loader";
import toast, { Toaster } from 'react-hot-toast';

const NotificationsComponent = ({ onCloseModal }) => {
    const dispatch = useDispatch();

    const [daily, setDaily] = useState(false);
    const [weekly, setWeekly] = useState(false);
    const [monthly, setMonthly] = useState(false);
    const [att, setAtt] = useState(false);
    const [verizon, setVerizon] = useState(false);
    const [tmobile, setTmobile] = useState(false);
    const [ftc, setFtc] = useState(false);
    const notificationsDto = useSelector(state => state.notifications.config);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotifications = async () => {
            await dispatch(getNotifications());
            setLoading(false);
        }
        fetchNotifications();
    }, [dispatch]);

    useEffect(() => {
        if (!loading) {
            const updatedNotificationsCall = {
                daily: daily,
                weekly: weekly,
                monthly: monthly,
                att: att,
                verizon: verizon,
                tmobile: tmobile,
                ftc: ftc
            };
            dispatch(insertNotificationConfiguration({ notificationDTO: updatedNotificationsCall }));
        }
    }, [daily, weekly, monthly, att, verizon, tmobile, ftc]);

    useEffect(() => {
        if (notificationsDto) {
            setDaily(notificationsDto.daily);
            setWeekly(notificationsDto.weekly);
            setMonthly(notificationsDto.monthly);
            setAtt(notificationsDto.att);
            setVerizon(notificationsDto.verizon);
            setTmobile(notificationsDto.tmobile);
            setFtc(notificationsDto.ftc);
        }
    }, [notificationsDto]);

    const handleSave = () => {
        if ((daily || weekly || monthly) && !(att || verizon || tmobile || ftc)) {
            toast.error('Please select at least one type of update!', {
                position: 'top-center',
                duration: 3000
            });
            return;
        }
        onCloseModal();
    };

    return (
        <div className="notifications-popup">
            <Toaster position="top-right" reverseOrder={false} />
            {loading ? (
                <Loader />
            ) : (
                <div className="notifications-popup-content">
                    <div className="notifications-popup-header">
                        <span className="title">Please select your notifications preferences</span>
                    </div>
                    <div className="content">
                        <div className="item">
                            <label className="switch">
                                <input type="checkbox" checked={daily} onChange={() => setDaily(!daily)} />
                                <span className="slider round"></span>
                            </label>
                            <div className="item-text">
                                Daily Notifications
                            </div>
                        </div>
                        <div className="item">
                            <label className="switch">
                                <input type="checkbox" checked={weekly} onChange={() => setWeekly(!weekly)} />
                                <span className="slider round"></span>
                            </label>
                            <div className="item-text">
                                Weekly Notifications
                            </div>
                        </div>
                        <div className="item">
                            <label className="switch">
                                <input type="checkbox" checked={monthly} onChange={() => setMonthly(!monthly)} />
                                <span className="slider round"></span>
                            </label>
                            <div className="item-text">
                                Monthly Notifications
                            </div>
                        </div>
                        {(daily || weekly || monthly) && (
                            <>
                                <div className="item">
                                    <label className="switch">
                                        <input type="checkbox" checked={att} onChange={() => setAtt(!att)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="item-text">
                                        Att
                                    </div>
                                </div>
                                <div className="item">
                                    <label className="switch">
                                        <input type="checkbox" checked={verizon} onChange={() => setVerizon(!verizon)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="item-text">
                                        Verizon
                                    </div>
                                </div>
                                <div className="item">
                                    <label className="switch">
                                        <input type="checkbox" checked={tmobile} onChange={() => setTmobile(!tmobile)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="item-text">
                                        TMobile
                                    </div>
                                </div>
                                <div className="item">
                                    <label className="switch">
                                        <input type="checkbox" checked={ftc} onChange={() => setFtc(!ftc)} />
                                        <span className="slider round"></span>
                                    </label>
                                    <div className="item-text">
                                        FTC strikes
                                    </div>
                                </div>
                            </>
                        )}
                        <button type="submit" className="login-submit" onClick={handleSave}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationsComponent;
