import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './ClientFeedbackBox.css';

const ClientFeedbackBox = ({ clientFeedbacks }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.userInfo);
    const companyContract = useSelector(state => state.dashboard.companyContract);

    const redirectClientFeedbackPage = () => {
        if (user?.admin ?? false) {
            navigate(`/client-view/${id}/client-feedback`);
            return;
        }

        navigate(`/client-view/${companyContract.companyId}/client-feedback`);
    };

    return (
        <div className="verizon-feedback-button-container">
            <div className="wrapper-chart" onClick={() => redirectClientFeedbackPage()}>
                <div className="container-feedback-info">
                    <span className="feeback-title-client">Client Feedback:</span>
                    <span className="feeback-value-client">{clientFeedbacks.length}</span>
                </div>
            </div>
        </div>
    );
};

export default ClientFeedbackBox;