import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getVerizonFeedbacks = createAsyncThunk('verizonfeedback/getVerizonFeedbacks', async (id) => {
    return axios
        .get(getApiEndpoint('verizonfeedback', `listbycompanyid/${id}`))
        .then(res => res.data);
});

export const approvedVerizonFeedbacks = createAsyncThunk('verizonfeedback/approvedVerizonFeedbacks', async (id) => {
    return axios
        .get(getApiEndpoint('verizonfeedback', `approvedverizonfeedbacks/${id}`))
        .then(res => res.data);
});

export const reproveNumber = createAsyncThunk('verizonfeedback/reproveNumber', async (id) => {
    return axios
        .post(getApiEndpoint('verizonfeedback', `reprove/${id}`))
        .then(res => res.data);
});

export const approveNumber = createAsyncThunk('verizonfeedback/approveNumber', async (id) => {
    return axios
        .post(getApiEndpoint('verizonfeedback', `approve/${id}`))
        .then(res => res.data);
});

export const reproveAll = createAsyncThunk('verizonfeedback/reproveAll', async (id) => {
    return axios
        .post(getApiEndpoint('verizonfeedback', `reproveAll/${id}`))
        .then(res => res.data);
});

export const approveAll = createAsyncThunk('verizonfeedback/approveAll', async (id) => {
    return axios
        .post(getApiEndpoint('verizonfeedback', `approveAll/${id}`))
        .then(res => res.data);
});

export const resetInfo = createAction('verizonfeedback/resetInfo');

const verizonfeedback = createSlice({
    name: 'verizonfeedback',
    initialState: {
        loading: false,
        uploadResult: null,
        verizonFeedbacks: [],
        isError: false,
        isUploadError: false
    },

    extraReducers: builder => {
        builder.addCase(getVerizonFeedbacks.pending, state => {
            state.loading = true;
            state.isError = false;
        });

        builder.addCase(getVerizonFeedbacks.fulfilled, (state, action) => {
            state.loading = false;
            state.verizonFeedbacks = action.payload;
            state.isError = false;
        });

        builder.addCase(getVerizonFeedbacks.rejected, (state) => {
            state.loading = false;
            state.verizonFeedbacks = [];
            state.isError = true;
        });

        builder.addCase(approvedVerizonFeedbacks.pending, state => {
            state.loading = true;
            state.isError = false;
        });

        builder.addCase(approvedVerizonFeedbacks.fulfilled, (state, action) => {
            state.loading = false;
            state.verizonFeedbacks = action.payload;
            state.isError = false;
        });

        builder.addCase(approvedVerizonFeedbacks.rejected, (state) => {
            state.loading = false;
            state.verizonFeedbacks = [];
            state.isError = true;
        });

        builder.addCase(resetInfo, state => {
			state.loading = false;
            state.isError = false;
            state.uploadResult = null;
            state.isUploadError = false;
        });

        builder.addCase(reproveNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(reproveNumber.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(reproveNumber.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(approveNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(approveNumber.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(approveNumber.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(reproveAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(reproveAll.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(reproveAll.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(approveAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(approveAll.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(approveAll.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });
    }
});

export default verizonfeedback.reducer;
