import React, {
    useEffect,
    useState
} from 'react';

import {
    useDispatch,
    useSelector}
from "react-redux";

import {
    setVerizonAutoApproveConfiguration,
    setClientAutoApproveConfiguration,
    getVerizonAutoApproveConfiguration,
    getClientAutoApproveConfiguration
} from "../../slice/systemConfigSlice";

import './NotificationsComponent.css';
import Loader from "../Loader";

const SystemConfigComponent = ( {onCloseModal} ) => {
    const dispatch = useDispatch();

    const verizonAutoApprove = useSelector(state => state.configurations.verizonautoapprove);
    const clientAutoApprove = useSelector(state => state.configurations.clientautoapprove);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loading)return;
        const fetchNotifications = async () => {
            setLoading(true);
            await dispatch(getClientAutoApproveConfiguration());
            await dispatch(getVerizonAutoApproveConfiguration());
            setLoading(false);
        };
        fetchNotifications();
    }, [dispatch,  verizonAutoApprove, clientAutoApprove, loading]);

    return(
        <div className="notifications-popup">
            {loading ? (
                <Loader />
            ) : (
            <div className="notifications-popup-content">
                <div className="notifications-popup-header">
                    <span className="title">Please select wide system preferences</span>
                </div>
                <div className="notifications-content">
                    <div className="item">
                        <label className="switch">
                            <input type="checkbox" checked={verizonAutoApprove}  onChange={ async (e) =>{
                                setLoading(true);
                                await dispatch(setVerizonAutoApproveConfiguration(e.target.checked));
                                await dispatch(getVerizonAutoApproveConfiguration());
                                setLoading(false);
                                }
                            } />
                            <span className="slider round"></span>
                        </label>
                        <div className="item-text">
                            Verizon feedbacks auto approve
                        </div>
                    </div>
                    <div className="item">
                        <label className="switch">
                            <input type="checkbox"  checked={clientAutoApprove} onChange={async (e) => {
                                setLoading(true);
                                await dispatch(setClientAutoApproveConfiguration(e.target.checked));
                                await dispatch(getClientAutoApproveConfiguration());
                                setLoading(false);
                            }}  />
                            <span className="slider round"></span>
                        </label>
                        <div className="item-text">
                            Client feedbacks auto approve
                        </div>
                    </div>
                    <button type="submit" className="login-submit" onClick={() => onCloseModal()}>
                        Close
                    </button>
                </div>
            </div>
            )}
        </div>
    );
};

export default SystemConfigComponent;