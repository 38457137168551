import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClientFeedbacks, approvedClientFeedbacks, reproveNumber, approveNumber, reproveAll, approveAll } from '../../slice/clientFeedbackSlice';
import { fetchDashboardById } from '../../slice/dashboardSlice';
import { BsSearch } from '../../icons';
import './ClientFeedback.css';
import Loader from '../Loader';

const ClientFeedback = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const clientFeedbacks = useSelector(state => state.clientFeedback.clientFeedbacks);
    const company = useSelector(state => state.dashboard.dashboard);
    const loading = useSelector(state => state.clientFeedback.loading);
    const user = useSelector(state => state.user.userInfo);
    const [numbersFeedback, setNumbersFeedback] = useState([]);
    const [hideProfanity, setHideProfanity] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNumberAscending, setIsNumberAscending] = useState(false);
    const [isDateAscending, setIsDateAscending] = useState(false);
    const [isSpamCategoryAscending, setIsSpamCategoryAscending] = useState(false);

    useEffect(() => {
        if (user?.admin) {
            dispatch(getClientFeedbacks(id));
        } else {
            dispatch(approvedClientFeedbacks(id));
        }

        dispatch(fetchDashboardById({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        setNumbersFeedback(clientFeedbacks);
    }, [clientFeedbacks]);

    const handleHideProfanityChange = (e) => {
        setHideProfanity(e.target.checked);
    };

    const handleApprove = async (feedbackId) => {
        await dispatch(approveNumber(feedbackId));
        await dispatch(getClientFeedbacks(id));
    }

    const handleDeny = async (feedbackId) => {
        await dispatch(reproveNumber(feedbackId));
        await dispatch(getClientFeedbacks(id));
    }

    const handleApproveAll = async () => {
        await dispatch(approveAll(id));
        await dispatch(getClientFeedbacks(id));
    }

    const handleDenyAll = async () => {
        await dispatch(reproveAll(id));
        await dispatch(getClientFeedbacks(id));
    }

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredFeedbacks = () => {
        if (searchQuery === '') {
            return numbersFeedback;
        }

        return numbersFeedback.filter(feedback => feedback.feedbackText.toLowerCase().includes(searchQuery.toLowerCase()));
    };

    const formatFeedbackDate = (dateString) => {
        const createdAt = new Date(dateString);
        return createdAt.toISOString().split('T')[0];
    };

    const handleSortByNumber = () => {
        const sortedData = [...numbersFeedback].sort((a, b) => {
            if (isNumberAscending) {
                return a.tfn.localeCompare(b.tfn);
            } else {
                return b.tfn.localeCompare(a.tfn);
            }
        });

        setNumbersFeedback(sortedData);
        setIsNumberAscending(!isNumberAscending);
    };

    const handleSortByDateTime = () => {
        const sortedData = [...numbersFeedback].sort((a, b) => {
            const dateA = new Date(a.createAt);
            const dateB = new Date(b.createAt);

            if (isDateAscending) {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        setNumbersFeedback(sortedData);
        setIsDateAscending(!isDateAscending);
    };

    const handleSortBySpamCategory = () => {
        const sortedData = [...numbersFeedback].sort((a, b) => {
            if (isSpamCategoryAscending) {
                return a.spamCategory.localeCompare(b.spamCategory);
            } else {
                return b.spamCategory.localeCompare(a.spamCategory);
            }
        });
        setNumbersFeedback(sortedData);
        setIsSpamCategoryAscending(!isSpamCategoryAscending);
    };

    const redirectNumber = number => {
        navigate(`/phone/${number}`);
    };

    const feedbacksNotEvaluated = () => {
        return numbersFeedback.filter((feedback) => !feedback.approved).length
    }

    return (
        <div className="client-feedback">
            <span className="client-title">Client Feedback</span>
            <span className="company-name">{company?.companyName}</span>
            <div className="actions-container">
                {feedbacksNotEvaluated() > 0 && user?.admin && <div className="approvers-buttons">
                    <button className="approve-all-button-feedback" onClick={() => handleApproveAll()}>Approve All {feedbacksNotEvaluated()} Numbers</button>
                    <span>/</span>
                    <button className="deny-all-button-feedback" onClick={() => handleDenyAll()}>Deny All {feedbacksNotEvaluated()} Numbers</button>
                </div>}
                <div className="hide-profanity-container">
                    <input
                        type="checkbox"
                        id="hide-profanity"
                        checked={hideProfanity}
                        onChange={handleHideProfanityChange}
                    />
                    <label htmlFor="hide-profanity">Hide Profanity</label>
                </div>
                <div className="feedback-search-container">
                    <input type="text" id="search-feedback" placeholder="Search Client Feedback..." onChange={handleSearchChange} />
                    <BsSearch className="icon" onClick={() => null} />
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="feedback-table-wrapper">
                    {filteredFeedbacks().length > 0 ? (
                        <table className="feedback-dash-table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSortByNumber()}>Number {isNumberAscending ? '▲' : '▼'}</th>
                                    <th onClick={() => handleSortByDateTime()}>Date {isDateAscending ? '▲' : '▼'}</th>
                                    <th onClick={() => handleSortBySpamCategory()}>Category {isSpamCategoryAscending ? '▲' : '▼'}</th>
                                    <th>Comment</th>
                                    {user?.admin && <th>Actions</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredFeedbacks().map((feedback, index) => (
                                    <tr key={index} onClick={() => redirectNumber(feedback.tfn)}>
                                        <td>{feedback.tfn}</td>
                                        <td>{formatFeedbackDate(feedback.createAt)}</td>
                                        <td>{feedback.spamCategory}</td>
                                        <td>{(hideProfanity && feedback.badWord === true) ? '**********' : feedback.feedbackText}</td>
                                        {user?.admin && <td onClick={(e) => e.stopPropagation()}>
                                            {!feedback.approved && <button className="approve-button-modal" onClick={() => handleApprove(feedback.id)}>Approve</button>}
                                            {!feedback.approved && <span>/</span>}
                                            {!feedback.approved && <button className="deny-button-modal" onClick={() => handleDeny(feedback.id)}>Deny</button>}
                                            {feedback.approved && <span>Feedback approved</span>}
                                        </td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className="any-feedback-messages">There are no feedbacks associated with this company.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ClientFeedback;
