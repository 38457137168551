import React, { useEffect } from 'react';
import './Login.css';
import logo from '../../logo.svg';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../slice/UserSlice';
import Loader from '../../components/Loader';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.user.loading);
    const user = useSelector(state => state.user.user);
    const isError = useSelector(state => state.user.isError);
    const loginRejectedReason = useSelector(state => state.user.loginRejectedReason);
    const loginIsSuccess = useSelector(state => state.user.isSuccess);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = data => {
        dispatch(login(data));
        window.localStorage.setItem('isLoggedOut', 'false');
    };

    useEffect(() => {
        if (loginIsSuccess) {
            let lastUrl = localStorage.getItem('lastUrl') || '/dashboard';
            if (lastUrl === '/login') {
                lastUrl = '/dashboard';
                localStorage.setItem('lastUrl', '/dashboard');
            }
			navigate(lastUrl);
        }
    }, [loginIsSuccess] );

    const renderErrorMessage = () => {
        if (errors.email || errors.password) {
            return <span className="login-error">All fields are required</span>;
        }

        if (loginRejectedReason === 402 && user?.payload === 'RegularUser') {
            return <span className="login-error">Login failed due to missing payment, please contact your account manager.</span>;
        }

        if (loginRejectedReason === 402 && user?.payload !== 'RegularUser') {
            window.open(user?.payload, '_blank');
            return <span className="login-error">You will be redirected to the Stripe page.</span>;
        }

        if (loginRejectedReason === 403) {
            return <span className="login-error">User is no longer a customer.</span>;
        }

        if (isError) {
            return <span className="login-error">Login failed, please check your credentials.</span>;
        }
    };

    return (
        <div className="login-wrapper">
            <img src={logo} alt="logo" />
            {!loading ? (
                <>
                    <h2>Login</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                        <div className="login-input-item">
                            <div className="login-input-title">
                                Email<p className="input-star">&nbsp;*</p>
                            </div>
                            <input type="email" {...register('email', { required: true })} className="login-add-input" />
                        </div>
                        <div className="login-input-item">
                            <div className="login-input-title">
                                Password<p className="input-star">&nbsp;*</p>
                            </div>
                            <input type="password" {...register('password', { required: true })} className="login-add-input" />
                        </div>
                        {renderErrorMessage()}
                        <button type="submit" className="login-submit">
                            Login
                        </button>
                    </form>
                    <button type="submit" className="login-submit forgot-password" onClick={() => navigate('/forgot-password')}>
                        Forgot Password
                    </button>
                </>
            ) : (
                <div className="loader-wrapper">
                    <Loader />
                </div>
            )}
        </div>
    );
};

export default Login;