import React from 'react';
import './PopupConfirmation.css';
import { IoMdAlert } from "../../icons";

const PopupConfirmation = ({selectedPhoneInfo, onClosePopup, onDelete, title}) => {
    return(
        <div className="popup">
            <div className="popup-content">
                <div className="popup-header">
                        <IoMdAlert/>
                        <span className="title">Please Confirm Deletion</span>
                </div>
                <div className="body">
                    <span className="title">{title}</span>
                    {selectedPhoneInfo && <span className="title dynamic">{`Client Data: ${selectedPhoneInfo.tfn.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}`}</span>}
                </div>
                <div className="buttons-region">
                    <button type="submit" className="button" onClick={() => onDelete(selectedPhoneInfo?.id)}>
                        Confirm
                    </button>
                    <button type="submit" className="button cancel" onClick={() => onClosePopup()}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupConfirmation;