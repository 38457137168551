import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getTotalSpamNumbers = createAsyncThunk('companies/getTotalSpamNumbers', async () => {
    return axios
        .get(getApiEndpoint('numberinfo', `spam`))
        .then(res => res.data);
});

export const getTotalSpamNumbersByCompanyId = createAsyncThunk('companies/getTotalSpamNumbersByCompanyId', async (companyId) => {
    return axios
        .get(getApiEndpoint('numberinfo', `spambycompany/${companyId}`))
        .then(res => res.data);
});

const companiesSlice = createSlice({
    name: 'companies',
    initialState: {
        loading: false,
        companiesData: [],
        error: '',
        isSuccess: ''
    },
    extraReducers: builder => {
        builder.addCase(getTotalSpamNumbers.pending, state => {
            state.loading = true;
        });

        builder.addCase(getTotalSpamNumbers.fulfilled, (state, action) => {
            state.loading = false;
            state.companiesData = action.payload;
            state.error = '';
        });

        builder.addCase(getTotalSpamNumbers.rejected, (state, action) => {
            state.loading = false;
            state.companiesData = [];
            state.error = action.error.message;
        });

        builder.addCase(getTotalSpamNumbersByCompanyId.pending, state => {
            state.loading = true;
        });

        builder.addCase(getTotalSpamNumbersByCompanyId.fulfilled, (state, action) => {
            state.loading = false;
            state.companiesData = action.payload;
            state.error = '';
        });

        builder.addCase(getTotalSpamNumbersByCompanyId.rejected, (state, action) => {
            state.loading = false;
            state.companiesData = [];
            state.error = action.error.message;
        });
    }
});

export default companiesSlice.reducer;
