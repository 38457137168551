import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const getPhoneByNumber = createAsyncThunk('phones/getPhoneByNumber', async ({ tfnNumber }) => {
    return axios
        .get(getApiEndpoint('numberinfo', tfnNumber))
        .then(res => res.data);
});

export const findByNumber = createAsyncThunk('phones/findByNumber', async ({ tfnNumber }) => {
    return axios
        .get(getApiEndpoint('numberinfo', `find/${tfnNumber}`))
        .then(res => res.data);
});

export const findByCurrentUserCompany = createAsyncThunk('phones/findByCurrentUserCompany', async ({ tfnNumber }) => {
    return axios
        .get(getApiEndpoint('numberinfo', `findbycurrentusercompany/${tfnNumber}`))
        .then(res => res.data);
});

export const findNumberByCurrentUserCompany = createAsyncThunk('phones/findNumberByCurrentUserCompany', async ({ tfnNumber }) => {
    return axios
        .get(getApiEndpoint('numberinfo', `findNumberByCurrentUserCompany/${tfnNumber}`))
        .then(res => res.data);
});


export const resetPhoneData = createAction('resetPhoneData')

const phoneSlice = createSlice({
    name: 'phone',
    initialState: {
        loading: false,
        phones: [],
        phone: '',
        error: '',
        isSuccess: ''
    },

    extraReducers: builder => {
        builder.addCase(resetPhoneData, state => {
            state.loading = false;
            state.phones = [];
            state.phone = '';
            state.error = '';
            state.isSuccess = '';
        });

        builder.addCase(getPhoneByNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(getPhoneByNumber.fulfilled, (state, action) => {
            state.loading = false;
            state.phone = action.payload;
            state.error = '';
        });

        builder.addCase(getPhoneByNumber.rejected, (state, action) => {
            state.loading = false;
            state.phone = '';
            state.error = action.error.message;
        });

        builder.addCase(findByNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(findByNumber.fulfilled, (state, action) => {
            state.loading = false;
            state.phones = action.payload;
            state.error = '';
        });

        builder.addCase(findByNumber.rejected, (state, action) => {
            state.loading = false;
            state.phones = [];
            state.error = action.error.message;
        });

        builder.addCase(findByCurrentUserCompany.pending, state => {
            state.loading = true;
        });

        builder.addCase(findByCurrentUserCompany.fulfilled, (state, action) => {
            state.loading = false;
            state.phones = action.payload;
            state.error = '';
        });

        builder.addCase(findByCurrentUserCompany.rejected, (state, action) => {
            state.loading = false;
            state.phones = [];
            state.error = action.error.message;
        });

        builder.addCase(findNumberByCurrentUserCompany.pending, state => {
            state.loading = true;
        });

        builder.addCase(findNumberByCurrentUserCompany.fulfilled, (state, action) => {
            state.loading = false;
            state.phone = action.payload;
            state.error = '';
        });

        builder.addCase(findNumberByCurrentUserCompany.rejected, (state, action) => {
            state.loading = false;
            state.phone = '';
            state.error = action.error.message;
        });
    }
});

export default phoneSlice.reducer;
