/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';
import { geoAlbersUsaPr } from '../../features/geoAlbersUsaPr.js';
import { TbZoomInFilled, TbZoomOutFilled } from '../../icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUsaMapData, getUsaMapDataByCompany } from '../../slice/usaMapDataSlice.js';
import './UsaMap.css';
import StateDetails from '../StateDetails/index.jsx';
import { useParams } from 'react-router-dom';

const UsaMap = () => {
    const usaMapRef = useRef();
	const [usJson, setUsJson] = useState(null);
	const [mapScale, setMapScale] = useState(1);
    const [newWidth, setNewWidth] = useState(0);
	const dispatch = useDispatch();
	const usaMapData = useSelector(state => state.usaMapData.data);
	const colors = ['#E1E1E1', '#5FAEE3', '#2B81C0', '#00517D', '#3C5163'];
	const newEngland = ['09', '10', '11', '24', '34', '44', '25', '33', '50'];
	const vertOffset = ['50', '33'];
    const FONT_SIZE_RATIO = 0.00025;
    const { id } = useParams();

    const width = usaMapRef?.current?.clientWidth;
	const height = (2 / 3) * width;
	const fontSize = width * FONT_SIZE_RATIO;
	const projection = geoAlbersUsaPr()
		.scale(width)
		.translate([width / 2, height / 2]);
    const path = d3.geoPath().projection(projection);
	const colorScale = d3
		.scaleQuantize()
		.domain([
			0,
			d3.max(usaMapData, d => {
				if (!d?.label) return 0;
				return Number(d?.label?.split('/')[0]);
			})
		])
		.range(colors);
    const [showStateDetailsModal, setShowStateDetailsModal] = useState(false);
    const [selectedStateDetails, setSelectedStateDetails] = useState(null);

    const handleResize = () => {
        setNewWidth(usaMapRef.current.clientWidth);
    };

    useEffect(() => {
        getUsData();
		getPromise();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
	}, []);

	const getPromise = async () => {
        if (id) {
            await dispatch(getUsaMapDataByCompany(id));
            return;
        }

		await dispatch(getUsaMapData(id));
	};

    useEffect(() => {
        if (usaMapData.length === 0 || !usJson) return;

        d3.select(usaMapRef.current).select('svg').remove();
        createMap(mapScale);
    }, [usaMapData, usJson, newWidth]);

    const handleStateClick = (stateDetails) => {
        setSelectedStateDetails(stateDetails);
        setShowStateDetailsModal(true);
    };

    const handleZoomIn = () => {
        const newScale = mapScale * 1.2;
        setMapScale(newScale);
        updateScale(newScale);
    };

    const handleZoomOut = () => {
        const newScale = mapScale * 0.8;
        setMapScale(newScale);
        updateScale(newScale);
    };

    const getUsData = async () => {
		try {
			const response = await fetch('/us.json');
			const data = await response.json();
			setUsJson(data);
		} catch (error) {
		}
	};

    const handleMapItemClick = (event, d) => {
        const stateDetails = usaMapData.find(data => data.name === d.properties.NAME);
        if (stateDetails) {
            handleStateClick(stateDetails);
        }
    };

    const createStates = g => {
        g.selectAll('path')
            .data(topojson.feature(usJson, usJson.objects.states_20m_2017).features)
            .enter()
            .append('path')
            .attr('d', path)
            .style('fill', d => {
                const state = usaMapData.find(data => data.name === d.properties.NAME);
                if (!state) return;
                return state.color;
            });

        g.append('path')
            .attr('class', 'state-borders')
            .attr(
                'd',
                path(
                    topojson.mesh(usJson, usJson.objects.states_20m_2017, (a, b) => {
                        return a !== b;
                    })
                )
            )
            .on('click', handleMapItemClick);
    };

    const createStatesName = g => {
        g.append('g')
            .attr('class', 'states')
            .selectAll('text')
            .data(topojson.feature(usJson, usJson.objects.states_20m_2017).features)
            .enter()
            .append('text')
            .html(d => {
                if (d.properties.NAME === 'West Virginia') {
                    return `<tspan dy="-1em">West</tspan><tspan x="0" dy="1.2em">Virginia</tspan>`;
                }
                if (d.properties.NAME === 'South Carolina') {
                    return `<tspan dy="-1em">South</tspan><tspan x="0" dy="1.1em">Carolina</tspan>`;
                }
                return d.properties.NAME;
            })
            .attr('transform', d => {
                if (!d.properties.dx) {
                    return `translate(${path.centroid(d)})`;
                } else {
                    return `translate(${
                        parseInt(path.centroid(d)[0]) + parseInt(d.properties.dx * (width / 800) * mapScale)
                    },
              ${parseInt(path.centroid(d)[1]) + parseInt(d.properties.dy * (width / 800) * mapScale)})`;
                }
            })
            .attr('dx', d => {
                if (d.properties.textdx) return d.properties.textdx * (width / 800) * mapScale;
                if (newEngland.includes(d.properties.STATEFP) && !vertOffset.includes(d.properties.STATEFP))
                    return 10 * (width / 800) * mapScale;
            })
            .attr('dy', d => {
                if (d.properties.textdy) return d.properties.textdy * (width / 800) * mapScale;
                if (vertOffset.includes(d.properties.STATEFP)) {
                    return -15 * (width / 800) * mapScale;
                }
                return -2 * (width / 800) * mapScale;
            })
            .attr('text-anchor', 'middle')
            .attr('fill', d => {
                const state = usaMapData.find(data => data.name === d.properties.NAME);
                if (!state) return;
                return state.fontColor;
            })
            .attr('font-size', `${(fontSize / 0.65) * mapScale}em`)
            .style('user-select', 'none')
            .on('click', handleMapItemClick);
    };

    const createLines = g => {
        g.append('g')
            .attr('class', 'connectinglines')
            .selectAll('text')
            .data(topojson.feature(usJson, usJson.objects.states_20m_2017).features)
            .enter()
            .filter(d => newEngland.includes(d.properties.STATEFP))
            .append('line')
            .attr('y1', d => path.centroid(d)[1])
            .attr('y2', d => parseFloat(d.properties.dy * (width / 800) * mapScale) + path.centroid(d)[1])
            .attr('x1', d => path.centroid(d)[0])
            .attr('x2', d => parseFloat(d.properties.dx * (width / 800) * mapScale) + path.centroid(d)[0])
            .attr('stroke', '#3C5163')
            .attr('stroke-width', '1px');
    };

    const createDataStates = g => {
        g.append('g')
            .attr('class', 'states')
            .selectAll('text')
            .data(topojson.feature(usJson, usJson.objects.states_20m_2017).features)
            .enter()
            .append('text')
            .text(d => usaMapData.find(data => data.name === d.properties.NAME)?.label)
            .attr('transform', d => {
                if (!d.properties.dx) {
                    return `translate(${path.centroid(d)})`;
                } else {
                    return `translate(${
                        parseInt(path.centroid(d)[0]) + parseInt(d.properties.dx * (width / 800) * mapScale)
                    },
              ${parseInt(path.centroid(d)[1]) + parseInt(d.properties.dy * (width / 800) * mapScale)})`;
                }
            })
            .attr('dx', d => {
                if (d.properties.datadx) return d.properties.datadx * (width / 800) * mapScale;
            })
            .attr('dy', d => {
                if (d.properties.datady) return d.properties.datady * (width / 800) * mapScale;
                if (vertOffset.includes(d.properties.STATEFP)) {
                    return -3 * (width / 800) * mapScale;
                }
                return 11 * (width / 800) * mapScale;
            })
            .attr('font-weight', '600')
            .attr('text-anchor', 'middle')
            .attr('font-size', d => {
                let font = 0.72;
                if (d.properties.font) font = d.properties.font;
                return `${((fontSize * 1.8) / font) * mapScale}em`;
            })
            .attr('fill', d => {
                const state = usaMapData.find(data => data.name === d.properties.NAME);
                if (!state) return;
                return state.fontColor;
            })
            .style('user-select', 'none')
            .on('click', handleMapItemClick);
    };

    const updateScale = scale => {
        projection.scale(width * scale);
        d3.select(usaMapRef.current).select('svg').remove();
        createMap();
    };

    const createMap = () => {
        const g = d3
            .select(usaMapRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('class', 'states')
            .call(
                d3
                    .zoom()
                    .scaleExtent([1, 10])
                    .on('zoom', function (event) {
                        if (event.sourceEvent.type === 'wheel') return;
                        g.transition().duration(100).attr('transform', event.transform);
                    })
            );

        createStates(g);
        createStatesName(g);
        createLines(g);
        createDataStates(g);

        g.selectAll('path').on('click', handleMapItemClick);
    };

    return (
        <>
            <div className="map-container">
                <div ref={usaMapRef} className="map-usa" />
                <div className="zoom-buttons">
                    <button onClick={handleZoomIn} className="zoom-button">
                        <TbZoomInFilled />
                    </button>
                    <button onClick={handleZoomOut} className="zoom-button">
                        <TbZoomOutFilled />
                    </button>
                </div>
            </div>

            {showStateDetailsModal && 
                <StateDetails
                    stateDetails={selectedStateDetails}
                    onCloseModal={() => setShowStateDetailsModal(false)}
                />
            }
        </>
    );
};

export default UsaMap;
