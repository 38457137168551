export const ClientActivationStatus = {
    1: "Clean",
    2: "Spam",
    3: "DNO",
};

export function getClientActivationStatusString(number) {
    return ClientActivationStatus[number] || 'Unknown';
}

export function isSpamStatus(number) {
    return ClientActivationStatus[number] === "Spam";
}

export function getClientActivationStatusNumber(string) {
    const key = Object.keys(ClientActivationStatus).find((key) => ClientActivationStatus[key] === string);
    return key ? parseInt(key, 10) : null;
}

export function getClientFilter(string, prefix) {
    const key = Object.keys(ClientActivationStatus).find((key) => ClientActivationStatus[key] === string);
    return key
        ? prefix + 'Client' + string + '=true&'
        : null;
}