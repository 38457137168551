import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPhoneByNumber, findNumberByCurrentUserCompany } from '../../slice/phoneSlice';
import './PhoneDetails.css'
import { isActive } from '../../models/activationStatus';
import { getClientActivationStatusString } from '../../models/clientActivationStatus';

const PhoneDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState('');
    const user = useSelector(state => state.user.userInfo);
    const phoneInfo = useSelector(state => state.phones.phone);

    useEffect(() => {
        if (user?.admin) {
            dispatch(getPhoneByNumber({ tfnNumber: id }));
            return;
        }

        dispatch(findNumberByCurrentUserCompany({ tfnNumber: id }));
    }, [id]);

    useEffect(() => {
        setData(phoneInfo);
    }, [phoneInfo]);

    const isBranded = (data) => {
        return data?.aTandTBranded === 2 || data?.tmobileBranded === 2 || data?.verizonBranded === 2;
    }

    const getStatusColor = (status) => {
        var clientStatus = getClientActivationStatusString(status);
        if (clientStatus === 'Clean') {
            return { color: '#3ed48c' };
        }

        return clientStatus === 'DNO' ? { color: '#f2c056' } : { color: '#f56666' };
    };

    return (
        <div className="phone-detail-wrapper">
            <div className="search-result">
                Search Result:&nbsp;
                <p className="search-result-title">{data?.tfn} ({data?.company?.companyName})</p>
            </div>
            <div className='phone-table'>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>Number:</p>
                    <p className='phone-table-item'>{data?.tfn}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>State:</p>
                    <p className='phone-table-item'>{data?.state}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>Region:</p>
                    <p className='phone-table-item'>{data?.region}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>AT&T Status:</p>
                    <p className='phone-table-item'style={getStatusColor(data?.aTandTStatus)}>{getClientActivationStatusString(data?.aTandTStatus)}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>T-Mobile Status:</p>
                    <p className='phone-table-item'style={getStatusColor(data?.tmobileStatus)}>{getClientActivationStatusString(data?.tmobileStatus)}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>Verizon Status:</p>
                    <p className='phone-table-item'style={getStatusColor(data?.verizonStatus)}>{getClientActivationStatusString(data?.verizonStatus)}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>FTC Strikes:</p>
                    <p className='phone-table-item'style={data?.ftcStrike ? {color: '#F56666'} :{color: '#3ED48C'}}>{data?.ftcStrike ? 'Yes' : 'No'} {data?.ftcStrike ? '- ' + new Date(data?.ftcStrikeDate)?.toLocaleDateString('en-US') : ''}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>Branded Caller ID:</p>
                    <p className='phone-table-item' style={isBranded(data) ? {color: '#3ED48C'} : {color: '#F56666'}}>{isBranded(data) ? 'Yes' : 'No'}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>AT&T Activation:</p>
                    <p className='phone-table-item'style={isActive(data?.aTandT) ? {color: '#3ED48C'} : {color: '#F56666'}}>{isActive(data?.aTandT) ? 'Yes': 'No'}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>T-Mobile Activation:</p>
                    <p className='phone-table-item'style={isActive(data?.tmobile) ? {color: '#3ED48C'} : {color: '#F56666'}}>{isActive(data?.tmobile) ? 'Yes': 'No'}</p>
                </div>
                <div className='phone-table-cell'>
                    <p className='phone-table-item'>Verizon Activation:</p>
                    <p className='phone-table-item'style={isActive(data?.verizon) ? {color: '#3ED48C'} : {color: '#F56666'}}>{isActive(data?.verizon) ? 'Yes': 'No'}</p>
                </div>
            </div>
        </div>
    );
};

export default PhoneDetails;
