import {
    createSlice,
    createAsyncThunk,
    createAction
} from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const addNumbers = createAsyncThunk('dashboardPhones/addNumbers', async ({ id, file }, { rejectWithValue }) => {
    try {
        const response = await axios.post(getApiEndpoint('numberinfo', `upload/${id}`), file);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            throw error;
        }
    }
});

export const uploadNumbersForCurrentClient = createAsyncThunk('dashboardPhones/uploadNumbersForCurrentClient', async ({ file }, { rejectWithValue }) => {
    try {
        const response = await axios.post(getApiEndpoint('numberinfo', 'uploadnumbersforcurrentclient'), file);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            throw error;
        }
    }
});

export const insert = createAsyncThunk('dashboardPhones/insert', async ({ id, phoneData }, { rejectWithValue }) => {
    try {
        const body = {
            companyId: id,
            tfn: phoneData.telephoneNumber.replace(/\D/g, ''),
            aTandTStatus: parseInt(phoneData.atandTStatus),
            tmobileStatus: parseInt(phoneData.tmobileStatus),
            verizonStatus: parseInt(phoneData.verizonStatus),
            ftcStrike: phoneData.ftcStrikes === "true",
            aTandT: parseInt(phoneData.atandTActivation),
            tmobile: parseInt(phoneData.tmobileActivation),
            verizon: parseInt(phoneData.verizonActivation),
        };

        console.log(body);

        const response = await axios.post(getApiEndpoint('numberinfo', `insert`), body);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            throw error;
        }
    }
});

export const insertMany = createAsyncThunk('dashboardPhones/insertMany', async (phoneData, { rejectWithValue }) => {
    try {
        const response = await axios.post(getApiEndpoint('numberinfo', `insertMany`), phoneData);
        return response.data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            throw error;
        }
    }
});

export const getOverrideNumbers = createAsyncThunk('dashboardPhones/getOverrideNumbers', async ({ id }) => {
    return axios
        .get(getApiEndpoint('numberinfo', `pendingoverride/${id}`))
        .then(res => res.data);
});

export const pendingOverrideByCurrentClient = createAsyncThunk('dashboardPhones/pendingOverrideByCurrentClient', async () => {
    return axios
        .get(getApiEndpoint('numberinfo', 'pendingoverridebycurrentclient'))
        .then(res => res.data);
});

export const overrideNumbers = createAsyncThunk('dashboardPhones/overrideNumbers', async ({ numberIds }) => {
    try {
        const response = await axios.post(
            getApiEndpoint('numberinfo', 'overridenumbers'),
            numberIds
        );
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const discardOverrideNumbers = createAsyncThunk('dashboardPhones/discardOverrideNumbers', async ({ numberIds }) => {
    try {
        const response = await axios.post(
            getApiEndpoint('numberinfo', 'discardoverridenumbers'),
            numberIds
        );
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const downloadNumbers = createAsyncThunk('dashboardPhones/downloadNumbers', async ({ id }) => {
    return axios
        .get(getApiEndpoint('numberinfo', `download/${id}`))
        .then(response => {
            const csvData = response.data
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.csv';
            link.click();
            URL.revokeObjectURL(url);
        });
});

export const downloadNumbersForCurrentCompany = createAsyncThunk('dashboardPhones/downloadNumbersForCurrentCompany', async () => {
    return axios
        .get(getApiEndpoint('numberinfo', 'downloadnumbersforcurrentcompany'))
        .then(response => {
            const csvData = response.data
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'data.csv';
            link.click();
            URL.revokeObjectURL(url);
        });
});

export const getPhonesById = createAsyncThunk('dashboardPhones/getPhonesById', async ({ page, pageSize, sorterInfo, branded }) => {
    const urlSufix = sorterInfo ? `${page}/${pageSize ? pageSize : 10}/${branded}?${sorterInfo}` : `${page}/${pageSize ? pageSize : 10}/${branded}`;

    return axios
        .get(getApiEndpoint('numberinfo', urlSufix))
        .then(res => res.data);
});

export const clientSearch = createAsyncThunk('dashboardPhones/clientSearch', async ({ page, pageSize, sorterInfo, branded }) => {
    let path = `clientsearch/${branded}?page=${page ? page : 0}&pageSize=${pageSize ? pageSize : 10}`;
    if (sorterInfo) {
        path += `&${sorterInfo}`;
    }

    return axios
        .get(getApiEndpoint('numberinfo', path + '&noFilter=true'))
        .then(res => res.data);
});

export const deletePhone = createAsyncThunk('dashboardPhones/deletePhone', async (phoneId) => {
    return axios
        .delete(getApiEndpoint('numberinfo', `delete/${phoneId}`))
        .then(res => res.data);
});

export const deleteAllPhones = createAsyncThunk('dashboardPhones/deleteAllPhones', async ({ id }) => {
    return axios
        .delete(getApiEndpoint('numberinfo', `deleteall/${id}`))
        .then(res => res.data);
});

export const deleteAllForCurrentCompany = createAsyncThunk('dashboardPhones/deleteAllForCurrentCompany', async () => {
    return axios
        .delete(getApiEndpoint('numberinfo', 'deleteallforcurrentcompany'))
        .then(res => res.data);
});

export const deleteMultiplePhones = createAsyncThunk('dashboardPhones/deletemultiple', async (data) => {
    return axios
        .delete(getApiEndpoint('numberinfo', `deletemultiple`), { data })
        .then(res => res.data);
});

export const updatePhone = createAsyncThunk('dashboardPhones/updatePhone', async (data) => {
    return axios
        .put(getApiEndpoint('numberinfo', 'update'), {
            id: data.id,
            companyId: data.companyId,
            tfn: data.tfn,
            areaCode: data.areaCode,
            state: data.state,
            region: data.region,
            top15AreaCode: data.top15AreaCode,
            aTandT: data.aTandT,
            aTandTBranded: data.aTandTBranded,
            aTandTStatus: data.aTandTStatus,
            tmobile: data.tmobile,
            tmobileBranded: data.tmobileBranded,
            tmobileStatus: data.tmobileStatus,
            verizon: data.verizon,
            verizonBranded: data.verizonBranded,
            verizonStatus: data.verizonStatus,
            businessCategory: data.businessCategory,
            ftcStrike: data.ftcStrike,
            ftcStrikeDate: data.ftcStrikeDate,
        })
        .then(res => res.json());
});

export const searchClientPhones = createAsyncThunk('dashboardPhones/searchClientPhones', async (data) => {
    const {
        companyId,
        pageSize,
        page,
        atandtAdminStatus,
        tmobileAdminStatus,
        verizonAdminStatus,
        atandtClientStatus,
        tmobileClientStatus,
        verizonClientStatus,
        atandtBrandedStatus,
        tmobileBrandedStatus,
        verizonBrandedStatus,
        state,
        branded,
        ftcStatus,
        sorterInfo
    } = data;

    const filters = {
        atandt: [...atandtAdminStatus || [], ...atandtClientStatus || [], ...atandtBrandedStatus || []].join(''),
        verizon: [...verizonAdminStatus || [], ...verizonClientStatus || [], ...verizonBrandedStatus || []].join(''),
        tmobile: [...tmobileAdminStatus || [], ...tmobileClientStatus || [], ...tmobileBrandedStatus || []].join('')
    };

    let path = `search/${companyId}/${branded}?page=${page ? page : 0}&pageSize=${pageSize ? pageSize : 10}&state=${state ? state : ''}&ftcStatus=${ftcStatus ? ftcStatus : ''}&`;
    if (sorterInfo) {
        path += `${sorterInfo}&`;
    }

    const urlFilters = Object.values(filters).join('');
    const fullPath = urlFilters || 'noFilter=true';
    const fullUrl = getApiEndpoint('numberinfo', path + fullPath);

    return axios
        .get(fullUrl)
        .then(res => res.data);
});

export const getSpamNumbersAdmin = createAsyncThunk('dashboardPhones/getSpamNumbersAdmin', async ({ id, branded, carrier }) => {
    const updatedCarrier = carrier === 'AT&T' ? 'ATT' : carrier;

    return axios
        .get(getApiEndpoint('stats', `companyspam/${id}/${branded}/${updatedCarrier}`))
        .then(res => res.data);
});

export const getSpamNumbersNonAdmin = createAsyncThunk('dashboardPhones/getSpamNumbersNonAdmin', async ({ branded, carrier }) => {
    const updatedCarrier = carrier === 'AT&T' ? 'ATT' : carrier;

    return axios
        .get(getApiEndpoint('stats', `companyspam/${branded}/${updatedCarrier}`))
        .then(res => res.data);
});

export const getFtcFlaggedNumbersAdmin = createAsyncThunk('dashboardPhones/getFtcFlaggedNumbersAdmin', async ({ id, branded }) => {
    return axios
        .get(getApiEndpoint('stats', `companyftc/${id}/${branded}`))
        .then(res => res.data);
});

export const getFtcFlaggedNumbersNonAdmin = createAsyncThunk('dashboardPhones/getFtcFlaggedNumbersNonAdmin', async ({ branded }) => {
    return axios
        .get(getApiEndpoint('stats', `companyftc/${branded}`))
        .then(res => res.data);
});

export const resetAddNumbersState = createAction('dashboardPhones/resetAddNumbersState');

export const updatePageSize = createAction('dashboardPhones/updatePageSize');

export const resetDuplicatedNumbers = createAction('resetDuplicatedNumbers');

const dashboardPhoneSlice = createSlice({
    name: 'phone',
    initialState: {
        loading: false,
        dashboardPhones: [],
        totalRegisters: 0,
        error: '',
        isSuccess: '',
        isError: false,
        pageSize: 10,
        spamNumbers: [],
        ftcFlaggedNumbers: [],
        insertManyAdded: null,
        duplicatedNumbers: [],
        isLoadingDuplicatedNumbers: false,
        isDuplicatedNumbersLoaded: false
    },

    extraReducers: builder => {
        //add numbers
        builder.addCase(addNumbers.pending, state => {
            state.loading = true;
        });

        builder.addCase(addNumbers.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(addNumbers.rejected, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = "An error occurred";
            }
            state.isError = true;
        });

        builder.addCase(uploadNumbersForCurrentClient.pending, state => {
            state.loading = true;
        });

        builder.addCase(uploadNumbersForCurrentClient.fulfilled, (state) => {
            state.loading = false;
            state.dashboardPhones = [];
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(uploadNumbersForCurrentClient.rejected, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            if (action.payload) {
                state.error = action.payload;
            } else {
                state.error = "An error occurred";
            }
            state.isError = true;
        });

        builder.addCase(resetAddNumbersState, state => {
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
        });

        //get phones by id
        builder.addCase(getPhonesById.pending, state => {
            state.loading = true;
            state.error = '';
        });

        builder.addCase(getPhonesById.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardPhones = action.payload;
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(getPhonesById.rejected, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            state.error = action.error.message;
        });

        // client search
        builder.addCase(clientSearch.pending, state => {
            state.loading = true;
            state.error = '';
        });

        builder.addCase(clientSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardPhones = action.payload.data;
            state.totalRegisters = action.payload.totalRegisters;
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(clientSearch.rejected, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            state.totalRegisters = 0;
            state.error = action.error.message;
        });

        //download numbers
        builder.addCase(downloadNumbers.pending, state => {
            state.loading = true;
            state.error = '';
        });

        builder.addCase(downloadNumbers.fulfilled, (state, action) => {
            state.loading = false;
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(downloadNumbers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // Delete Phone
        builder.addCase(deletePhone.pending, state => {
            state.loading = true;
        });

        builder.addCase(deletePhone.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
        });

        builder.addCase(deletePhone.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // Delete all Phones
        builder.addCase(deleteAllPhones.pending, state => {
            state.loading = true;
        });

        builder.addCase(deleteAllPhones.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
        });

        builder.addCase(deleteAllPhones.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // Update Phone
        builder.addCase(updatePhone.pending, state => {
            state.loading = true;
        });

        builder.addCase(updatePhone.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
        });

        builder.addCase(updatePhone.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // Search Phones
        builder.addCase(searchClientPhones.pending, state => {
            state.loading = true;
        });

        builder.addCase(searchClientPhones.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardPhones = action.payload.data;
            state.totalRegisters = action.payload.totalRegisters;
            state.isSuccess = true;
        });

        builder.addCase(searchClientPhones.rejected, (state, action) => {
            state.loading = false;
            state.dashboardPhones = [];
            state.totalRegisters = 0;
            state.error = action.error.message;
        });

        // Page Size
        builder.addCase(updatePageSize, (state, action) => {
            state.pageSize = action.payload;
        });

        // Spam Numbers for admin
        builder.addCase(getSpamNumbersAdmin.pending, state => {
            state.loading = true;
        });

        builder.addCase(getSpamNumbersAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.spamNumbers = action.payload;
            state.isSuccess = true;
        });

        builder.addCase(getSpamNumbersAdmin.rejected, (state, action) => {
            state.loading = false;
            state.spamNumbers = [];
            state.error = action.error.message;
        });

        // Spam Numbers for non admin users
        builder.addCase(getSpamNumbersNonAdmin.pending, state => {
            state.loading = true;
        });

        builder.addCase(getSpamNumbersNonAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.spamNumbers = action.payload;
            state.isSuccess = true;
        });

        builder.addCase(getSpamNumbersNonAdmin.rejected, (state, action) => {
            state.loading = false;
            state.spamNumbers = [];
            state.error = action.error.message;
        });

        // FTC Numbers for admin
        builder.addCase(getFtcFlaggedNumbersAdmin.pending, state => {
            state.loading = true;
        });

        builder.addCase(getFtcFlaggedNumbersAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.ftcFlaggedNumbers = action.payload;
            state.isSuccess = true;
        });

        builder.addCase(getFtcFlaggedNumbersAdmin.rejected, (state, action) => {
            state.loading = false;
            state.ftcFlaggedNumbers = [];
            state.error = action.error.message;
        });

        // FTC Numbers for non admin users
        builder.addCase(getFtcFlaggedNumbersNonAdmin.pending, state => {
            state.loading = true;
        });

        builder.addCase(getFtcFlaggedNumbersNonAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.ftcFlaggedNumbers = action.payload;
            state.isSuccess = true;
        });

        builder.addCase(getFtcFlaggedNumbersNonAdmin.rejected, (state, action) => {
            state.loading = false;
            state.ftcFlaggedNumbers = [];
            state.error = action.error.message;
        });

        builder.addCase(insertMany.pending, state => {
            state.loading = true;
        });

        builder.addCase(insertMany.fulfilled, (state) => {
            state.loading = false;
            state.insertManyAdded = true;
        });

        builder.addCase(insertMany.rejected, (state) => {
            state.loading = false;
            state.insertManyAdded = false;
        });

        builder.addCase(insert.pending, state => {
            state.loading = true;
        });

        builder.addCase(insert.fulfilled, (state) => {
            state.loading = false;
            state.insertManyAdded = true;
        });

        builder.addCase(insert.rejected, (state) => {
            state.loading = false;
            state.insertManyAdded = false;
        });

        builder.addCase(getOverrideNumbers.pending, state => {
            state.isLoadingDuplicatedNumbers = true;
        });

        builder.addCase(getOverrideNumbers.fulfilled, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.duplicatedNumbers = action.payload;
            state.isDuplicatedNumbersLoaded = false;
        });

        builder.addCase(getOverrideNumbers.rejected, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.duplicatedNumbers = [];
            state.error = action.error.message;
        });

        builder.addCase(pendingOverrideByCurrentClient.pending, state => {
            state.isLoadingDuplicatedNumbers = true;
        });

        builder.addCase(pendingOverrideByCurrentClient.fulfilled, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.duplicatedNumbers = action.payload;
            state.isDuplicatedNumbersLoaded = false;
        });

        builder.addCase(pendingOverrideByCurrentClient.rejected, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.duplicatedNumbers = [];
            state.error = action.error.message;
        });

        builder.addCase(resetDuplicatedNumbers, state => {
            state.isLoadingDuplicatedNumbers = false;
            state.duplicatedNumbers = [];
            state.isDuplicatedNumbersLoaded = false;
            state.isSuccess = false;
            state.isError = false;
        });

        builder.addCase(overrideNumbers.pending, state => {
            state.isLoadingDuplicatedNumbers = true;
        });

        builder.addCase(overrideNumbers.fulfilled, (state) => {
            state.isLoadingDuplicatedNumbers = false;
            state.isDuplicatedNumbersLoaded = true;
        });

        builder.addCase(overrideNumbers.rejected, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.error = action.error.message;
            state.isDuplicatedNumbersLoaded = false;
        });

        builder.addCase(discardOverrideNumbers.pending, state => {
            state.isLoadingDuplicatedNumbers = true;
        });

        builder.addCase(discardOverrideNumbers.fulfilled, (state) => {
            state.isLoadingDuplicatedNumbers = false;
            state.isDuplicatedNumbersLoaded = true;
        });

        builder.addCase(discardOverrideNumbers.rejected, (state, action) => {
            state.isLoadingDuplicatedNumbers = false;
            state.error = action.error.message;
            state.isDuplicatedNumbersLoaded = false;
        });

        //Delete Multiple numbers
        builder.addCase(deleteMultiplePhones.pending, state => {
            state.loading = true;
        });

        builder.addCase(deleteMultiplePhones.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
        });

        builder.addCase(deleteMultiplePhones.rejected, (state, action) => {
            state.loading = false;
            state.isSuccess = false;
            state.error = action.error.message;
        });

        builder.addCase(downloadNumbersForCurrentCompany.pending, state => {
            state.loading = true;
            state.error = '';
        });

        builder.addCase(downloadNumbersForCurrentCompany.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(downloadNumbersForCurrentCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        builder.addCase(deleteAllForCurrentCompany.pending, state => {
            state.loading = true;
            state.error = '';
        });

        builder.addCase(deleteAllForCurrentCompany.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
            state.error = '';
        });

        builder.addCase(deleteAllForCurrentCompany.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
});

export default dashboardPhoneSlice.reducer;
