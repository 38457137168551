import { React, useEffect, useState } from 'react';
import PureCallerID from '../PureCallerID';
import './ListCharts.css';
import PureBrandedCallerID from '../PureBrandedCallerID';
import VerizonFeedbackBox from '../VerizonFeedbackBox';
import ClientFeedbackBox from '../ClientFeedbackBox';
import OverallTelephone from '../OverallTelephone';
import FederalTradeCommission from '../FederalTradeCommission';
import { getListChartData, getListChartDataByCompanyId, resetListChartData } from '../../slice/listChartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { approvedVerizonFeedbacks } from '../../slice/verizonFeedbackSlice';
import { approvedClientFeedbacks } from '../../slice/clientFeedbackSlice';

const ListCharts = () => {
    const dispatch = useDispatch();
    const listChart = useSelector(state => state.listChart.data);
    const { id } = useParams();

    const companyContract = useSelector(state => state.dashboard.companyContract);
    const verizonFeedbacks = useSelector(state => state.verizonFeedback.verizonFeedbacks);
    const clientFeedbacks = useSelector(state => state.clientFeedback.clientFeedbacks);
    const user = useSelector(state => state.user.userInfo);

    useEffect(() => {
        dispatch(resetListChartData());
        getPromise();
    }, []);

    useEffect(() => {
        if (user?.admin ?? false) {
            dispatch(approvedVerizonFeedbacks(id));
            dispatch(approvedClientFeedbacks(id));
        }
    }, [id]);

    useEffect(() => {
        if (!user?.admin ?? false) {
            dispatch(approvedVerizonFeedbacks(companyContract.companyId));
            dispatch(approvedClientFeedbacks(companyContract.companyId));
        }
    }, [companyContract]);

    const getPromise = async () => {
        if (id) {
            await dispatch(getListChartDataByCompanyId(id));
            return;
        }

        await dispatch(getListChartData());
    };

    return (
        <div className="list-charts">
            <div className="content-wrapper content-chart">
                <PureCallerID data={listChart[0]} />
            </div>
            <div className="content-wrapper content-chart">
                <OverallTelephone data={listChart[1]} />
            </div>
            <div className="content-wrapper content-chart">
                <FederalTradeCommission data={listChart[4]} />
            </div>
            <div className="content-wrapper content-chart">
                <PureBrandedCallerID data={listChart[3]} />
            </div>
            {verizonFeedbacks?.length > 0 && <div className="content-wrapper content-chart chart-button">
                <VerizonFeedbackBox verizonFeedbacks={verizonFeedbacks} />
            </div>}
            {clientFeedbacks?.length > 0 && <div className="content-wrapper content-chart chart-button">
                <ClientFeedbackBox clientFeedbacks={clientFeedbacks} />
            </div>}
        </div>
    );
};

export default ListCharts;
