import React from 'react';
import HalfPieChart from '../HalfPieChart';

const OverallTelephone = ({ data }) => {
	const width = 800;
	const height = Math.min(width, 500);
	const value = data?.[0].value;

	return data ? (
		<div className="wrapper-chart">
			<div className="chart">
				<HalfPieChart value={value} percentage width={width} height={height} colors={['#5FAEE3', '#F56666']} />
			</div>
			<div className="label-half-chart">
				<span className="percentage-half-chart">{value}%</span>
				<div className="title-half-chart">
					Overall Telephone Number
					<br />
					Health Score
				</div>
			</div>
		</div>
	) : null;
};

export default OverallTelephone;
