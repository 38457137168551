import React, { useEffect, useState } from "react";
import "./StateDetails.css";
import { IoMdCloseCircle } from "../../icons";
import { useDispatch, useSelector } from 'react-redux';
import { getUsaMapStateData, resetStateData, getUsaMapStateDataByCompany } from "../../slice/usaMapDataSlice";
import Loader from '../Loader';
import { useParams, useNavigate } from 'react-router-dom';

const StateDetails = ({ stateDetails, onCloseModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stateData = useSelector(state => state.usaMapData.stateData);
    const loading = useSelector(state => state.usaMapData.loading);
    const [currentStateData, setCurrentStateData] = useState(stateData);
    const [isAreaCodeAscending, setAreaCodeAscending] = useState(false);
    const [isSpamAscending, setSpamAscending] = useState(false);
    const [isTop15AreaCodeAscending, setTop15AreaCodeAscending] = useState(false);

    const { id } = useParams();

    const redirectNumber = number => {
        navigate(`/phone/${number}`);
    };

    useEffect(() => {
        dispatch(resetStateData());

        if (id) {
            const data = {
                companyId: id,
                state: stateDetails.name
            };
            dispatch(getUsaMapStateDataByCompany({data}));
            return;
        }

        dispatch(getUsaMapStateData(stateDetails.name));
    }, []);

    useEffect(() => {
        setCurrentStateData(stateData);
    }, [stateData]);

    const sortByAreaCode = () => {
        const sortedData = [...currentStateData].sort((a, b) => {
            return isAreaCodeAscending ? a.areaCode - b.areaCode : b.areaCode - a.areaCode;
        });

        setAreaCodeAscending(!isAreaCodeAscending);
        setCurrentStateData(sortedData);
    };

    const sortByTop15AreaCode = () => {
        const sortedData = [...currentStateData].sort((a, b) => {
            return isTop15AreaCodeAscending ? a.top15AreaCode - b.top15AreaCode : b.top15AreaCode - a.top15AreaCode;
        });

        setTop15AreaCodeAscending(!isTop15AreaCodeAscending);
        setCurrentStateData(sortedData);
    };

    const sortBySpam = () => {
        const sortedData = [...currentStateData].sort((a, b) => {
            const aIsSpam = a.aTandTStatus === 2 || a.verizonStatus === 2 || a.tmobileStatus === 2;
            const bIsSpam = b.aTandTStatus === 2 || b.verizonStatus === 2 || b.tmobileStatus === 2;
            return isSpamAscending ? aIsSpam - bIsSpam : bIsSpam - aIsSpam;
        });

        setSpamAscending(!isSpamAscending);
        setCurrentStateData(sortedData);
    };

    return(
        <div className="state-details-modal">
            {loading ? (
                 <Loader />
            ) : (
                <div className="state-details-content">
                    <div className="state-details-header">
                        <div className="state-details-header-title">
                            <span className="title">{stateDetails.name}</span>
                        </div>
                        <button className="state-details-close-button" onClick={() => onCloseModal()}>
                            <IoMdCloseCircle />
                        </button>
                    </div>
                    {currentStateData.length > 0 ? (
                        <div className="table">
                            <table className="dash-table">
                                <thead>
                                    <tr>
                                        <th>Telephone Number</th>
                                        <th onClick={sortByAreaCode}>Area Code {isAreaCodeAscending ? '▲' : '▼'}</th>
                                        <th>Region</th>
                                        <th onClick={sortByTop15AreaCode}>Top 15 Area Code {isTop15AreaCodeAscending ? '▲' : '▼'}</th>
                                        <th onClick={sortBySpam}>Spam {isSpamAscending ? '▲' : '▼'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentStateData?.map((value, key) => {
                                        return (
                                            <tr key={key} onClick={() => redirectNumber(value.tfn)}>
                                                <td>{value.tfn}</td>
                                                <td>{value.areaCode}</td>
                                                <td>{value.region}</td>
                                                <td
                                                    className={value.top15AreaCode ? 'health-style' : 'flagged-style'}>
                                                        {value.top15AreaCode ? 'Yes' : 'No'}
                                                </td>
                                                <td
                                                    className={(value.aTandTStatus === 2 || value.verizonStatus === 2 || value.tmobileStatus === 2) ? 'flagged-style' : 'health-style'}>
                                                        {(value.aTandTStatus === 2 || value.verizonStatus === 2 || value.tmobileStatus === 2) ? 'Yes' : 'No'}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="state-empty">This state does not have any registered numbers.</div>
                    )}
                </div>
            )}
        </div>
    )
}

export default StateDetails;