import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {getApiEndpoint} from "../utils/apiUtils";

export const setVerizonAutoApproveConfiguration = createAsyncThunk('configuration/getverizon', async value => {
    return axios
        .post(getApiEndpoint('configurations', `verizonautoapprove/${value}`))
        .then(res => res.data);
});

export const setClientAutoApproveConfiguration = createAsyncThunk('configuration/getclient', async value => {
    return axios
        .post(getApiEndpoint('configurations', `clientautoapprove/${value}`))
        .then(res => res.data);
});

export const getVerizonAutoApproveConfiguration = createAsyncThunk('configuration/verizon', async () => {
    return axios
        .get(getApiEndpoint('configurations', `verizonautoapprove`))
        .then(res => res.data);
});

export const getClientAutoApproveConfiguration = createAsyncThunk('configuration/clientautoapprove', async () => {
    return axios
        .get(getApiEndpoint('configurations', `clientautoapprove`))
        .then(res => res.data);
});

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState: {
        clientautoapprove: false,
        verizonautoapprove: false,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getVerizonAutoApproveConfiguration.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(getVerizonAutoApproveConfiguration.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.verizonautoapprove = action.payload;
        });
        builder.addCase(getVerizonAutoApproveConfiguration.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        builder.addCase(getClientAutoApproveConfiguration.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(getClientAutoApproveConfiguration.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.clientautoapprove = action.payload;
        });
        builder.addCase(getClientAutoApproveConfiguration.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        builder.addCase(setVerizonAutoApproveConfiguration.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(setVerizonAutoApproveConfiguration.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.verizonautoapprove = action.payload;
        });
        builder.addCase(setVerizonAutoApproveConfiguration.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        builder.addCase(setClientAutoApproveConfiguration.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(setClientAutoApproveConfiguration.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.clientautoapprove = action.payload;
        });
        builder.addCase(setClientAutoApproveConfiguration.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export default configurationsSlice.reducer;