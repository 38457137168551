import React, { useEffect, useState } from 'react';
import './CompanyBranded.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import OverusedTable from '../OverusedTable';
import Loader from '../Loader';
import {
    deletePhone,
    updatePhone,
    searchClientPhones,
    deleteMultiplePhones
} from '../../slice/dashboardPhoneSlice';
import { getFilters } from '../../service/filterService';
import useSorter from '../../service/sorterService';
import { findByNumber } from '../../slice/phoneSlice';
import { AiFillDelete } from '../../icons';
import PopupConfirmation from '../PopupConfirmation';
import toast, { Toaster } from 'react-hot-toast';

const CompanyBranded = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [page, setCurrentPage] = useState(0);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const { id } = useParams();
    const data = useSelector(state => state.dashboardPhones.dashboardPhones);
    const loading = useSelector(state => state.dashboardPhones.loading);
    const totalRegisters = useSelector(state => state.dashboardPhones.totalRegisters);
    const pageSize = useSelector(state => state.dashboardPhones.pageSize);
    const company = useSelector(state => state.dashboard.dashboard);
    const { activeFilter } = getFilters();
    const { sortingOptions } = useSorter();
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const deleteMultipleNumbersSuccess = useSelector(state => state.dashboardPhones.isSuccess);

    const redirectNumber = async (number) => {
        dispatch(findByNumber({ tfnNumber: number }));
        navigate(`/phone/${number}`);
    };

    const setPage = value => {
        setCurrentPage(page + value);
    };

    const handleDelete = (phoneId) => {
        dispatch(deletePhone(phoneId)).then(() => {
            handleSearchClientNumbers();
        });
    };

    const handleUpdate = (phoneInfo) => {
        dispatch(updatePhone(phoneInfo)).then(() => {
            handleSearchClientNumbers();
        });
    }

    const handleSearchClientNumbers = () => {
        dispatch(searchClientPhones({ ...activeFilter, companyId: id, branded: true, page: page, sorterInfo: sortingOptions.currentSorter }));
    }

    const handleSelectAll = () => {
        const allNumbers = data
            .filter(obj => !obj.hasOwnProperty('companyName'))
            .map(obj => obj.id);
        
        if (!allNumbers) {
            return;
        } 

        if (selectedNumbers?.length === data.length) {
            setSelectedNumbers([]);
            return;
        }

        setSelectedNumbers(allNumbers);
    };

    const handleDeleteSelectedNumbers = () => {
        if (!deleteMultipleNumbersSuccess) {
            toast.error('There are no selected numbers to delete!', {
                position: 'top-center',
                duration: 3000
            });
            return;
        }

        setPopupConfirmation(true);
    };

    const deleteSelectedNumbers = () => {
        dispatch(deleteMultiplePhones(selectedNumbers)).then(() => {
            handleSearchClientNumbers();
        });
        setPopupConfirmation(false);
    };

    useEffect(() => {
        handleSearchClientNumbers();
    }, [dispatch, id, page, pageSize, company]);

    return (
        <div>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="selected-buttons">
                <label className="delete-selected-numbers" onClick={handleDeleteSelectedNumbers}>
                    Delete Selected Numbers
                    <AiFillDelete className='delete-numbers-icon' />
                </label>
            </div>
            {data?.length > 0 && totalRegisters !== 0 && !loading ? (
                <OverusedTable
                    config={{
                        callerNumber: { title: 'Caller Number' },
                        state: { title: 'State' },
                        atAndT: { title: 'AT&T' },
                        tMobile: { title: 'T Mobile' },
                        verizon: { title: 'Verizon' },
                        ftcStrike: { title: 'FTC Strike' },
                        action: { title: 'Action' }
                    }}
                    data={data}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    selectedNumbers={selectedNumbers}
                    handleSelectedNumbers={setSelectedNumbers}
                    redirectNumber={redirectNumber}
                    totalRegisters={totalRegisters}
                    onDelete={handleDelete}
                    onUpdate={handleUpdate}
                    onSort={handleSearchClientNumbers}
                    onSelectAll={handleSelectAll}
                />
            ) : loading ? (
                <Loader />
            ) : (
                <div className="empty">No Numbers Under Management For Branded Calling</div>
            )}
            <div>
                {popupConfirmation &&
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={deleteSelectedNumbers}
                                title={'Are you sure you want to delete all selected numbers from this client?'}
                            />
                        )
                    )
                }
            </div>
        </div>
    );
};

export default CompanyBranded;
