import React, { useEffect, useState } from 'react';
import './Details.css';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet, useParams, useLocation } from 'react-router-dom';
import { fetchDashboardById } from '../../slice/dashboardSlice';
import {
    resetAddNumbersState,
    downloadNumbers,
    searchClientPhones,
    deleteAllPhones,
    updatePageSize
} from '../../slice/dashboardPhoneSlice';
import { AiFillPlusCircle, LiaDownloadSolid, IoIosFunnel, IoIosSearch, AiFillDelete } from '../../icons';
import Filters from '../Filters';
import Dropdown from '../Dropdown';
import { ActivationStatus } from '../../models/activationStatus';
import { ClientActivationStatus } from '../../models/clientActivationStatus';
import { BrandedActivationStatus } from '../../models/brandedActivationStatus';
import { updateFilters, getFilters, resetFilter } from '../../service/filterService';
import FileUploadModal from '../FileUploadModal';
import PopupConfirmation from '../PopupConfirmation';

const CompanyDetails = () => {
    const [data, setData] = useState('');
    const dispatch = useDispatch();
    const { id } = useParams();
    const company = useSelector(state => state.dashboard.dashboard);
    const [showFilters, setShowFilters] = useState(false);
    const location = useLocation();
    const initialState = {
        'AT&T': [],
        'T Mobile': [],
        'Verizon': [],
    };
    const [adminFilters, setAdminFilters] = useState(initialState);
    const [clientFilters, setClientFilters] = useState(initialState);
    const [brandedFilters, setBrandedFilters] = useState(initialState);
    const [selectedState, setSelectedState] = useState('');
    const [ftcFilter, setFtcFilter] = useState('');
    const [showUploadFileModal, setShowUploadFileModal] = useState(false);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const states = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California",
        "Colorado", "Connecticut", "Delaware", "Florida", "Georgia",
        "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa",
        "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri",
        "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
        "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont",
        "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];
    const [pageSize, setPageSize] = useState(10);

    const updateSelectedFilters = (filterType, company, filters) => {
        switch (filterType) {
            case 'Admin':
                setAdminFilters((prevSelectedFilters) => ({
                    ...prevSelectedFilters,
                    [company]: filters,
                }));
                break;

            case 'Client':
                setClientFilters((prevSelectedFilters) => ({
                    ...prevSelectedFilters,
                    [company]: filters,
                }));
                break;

            case 'Branded':
                setBrandedFilters((prevSelectedFilters) => ({
                    ...prevSelectedFilters,
                    [company]: filters,
                }));
                break;

            default:
        }
    };

    const handleSearchClick = () => {
        updateFilters(id, pageSize, 0, adminFilters, clientFilters, brandedFilters, selectedState, isBranded(), ftcFilter);

        const filters = getFilters();
        dispatch(searchClientPhones(filters.activeFilter));
    };

    const handleResetClick = () => {
        setAdminFilters(initialState);
        setClientFilters(initialState);
        setBrandedFilters(initialState);
        setSelectedState('');
        setFtcFilter('');

        updateFilters(id, pageSize, 0, adminFilters, clientFilters, brandedFilters, selectedState, isBranded(), ftcFilter);
        dispatch(searchClientPhones({ companyId: id, pageSize: pageSize, branded: isBranded() }));
    }

    const handleNavLinkClick = () => {
        setAdminFilters(initialState);
        setClientFilters(initialState);
        setBrandedFilters(initialState);
        resetFilter();
        setShowFilters(false);

        dispatch(updatePageSize(pageSize));
    };

    const setNewPageSize = async (value) => {
        setPageSize(value);
        await dispatch(updatePageSize(value));
    }

    const handleFileUpload = () => {
        dispatch(resetAddNumbersState());
        setShowUploadFileModal(true);
    };

    const handleDownload = () => {
        dispatch(downloadNumbers({ id }));
    };

    const handleFilterClick = () => {
        setShowFilters(!showFilters);
    };

    const handleDeleteAllNumbers = () => {
        setPopupConfirmation(true);
    };

    const deleteAllNumbers = async () => {
        await dispatch(deleteAllPhones({ id }));
        await dispatch(fetchDashboardById({ id }));
        setPopupConfirmation(false);
    };

    const isNumbersScreen = () => {
        return (location.pathname.includes('/not-branded') || location.pathname.includes('/branded'));
    }

    const isBranded = () => {
        return location.pathname.includes('/branded');
    }

    const onCloseUploadFile = () => {
        dispatch(fetchDashboardById({ id }));
        setShowUploadFileModal(false);
    }

    useEffect(() => {
        dispatch(fetchDashboardById({ id }));
    }, [id]);

    useEffect(() => {
        setData(company);
    }, [company]);

    useEffect(() => {
        dispatch(searchClientPhones({ companyId: id, pageSize: pageSize, branded: isBranded() }));
    }, [pageSize]);

    return (
        <div className="details-wrapper">
            <div className="search-result">
                Search Result:&nbsp;
                <p className="search-result-title">{data?.companyName}</p>
            </div>
            <div className="details-button-wrapper">
                <div className="details-buttons">
                    <NavLink
                        to={'./edit'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        Client Data
                    </NavLink>
                    <NavLink
                        to={'./manage-telephones'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        Manage Telephones
                    </NavLink>
                    <NavLink
                        to={'./not-branded'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        TFN Pure CID
                    </NavLink>
                    <NavLink
                        to={'./branded'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        TFN Pure Branded CID
                    </NavLink>
                    <NavLink
                        to={'./client-view'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        Client View
                    </NavLink>
                    <NavLink
                        to={'./client-feedback'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        Client Feedback
                    </NavLink>
                    <NavLink
                        to={'./verizon-feedback'}
                        onClick={handleNavLinkClick}
                        className={({ isActive, isPending }) =>
                            isPending ? 'details-button' : isActive ? 'active-details details-button' : 'details-button'
                        }>
                        Verizon Feedback
                    </NavLink>
                </div>
            </div>
            {isNumbersScreen() && <div className="numbers-buttons">
                <button onClick={handleFilterClick} className={`filter-options-button ${showFilters ? 'button-pressed' : ''}`}>
                    <span className="filter-icon"><IoIosFunnel /></span> Filter
                </button>
                <label className="download-numbers" onClick={handleDownload}>
                    Download Numbers
                    <LiaDownloadSolid className='download-icon' />
                </label>
                <label className="add-numbers" onClick={handleFileUpload}>
                    Add Numbers
                    <AiFillPlusCircle className='add-numbers-icon' />
                </label>
                <label className="delete-numbers" onClick={handleDeleteAllNumbers}>
                    Delete All Numbers
                    <AiFillDelete className='delete-numbers-icon' />
                </label>
            </div>}
            {showFilters &&
                <div className='filters-container'>
                    <div className='filters-wrapper'>
                        <div className="filter-container">
                            <span className="filter-name">Admin Filter</span>
                            <div class='filters-area'>
                                <Filters
                                    className="filters"
                                    title={'AT&T Status:'}
                                    filters={Object.values(ActivationStatus)}
                                    selectedFilters={adminFilters['AT&T']}
                                    setSelectedFilters={(filters) => updateSelectedFilters('Admin', 'AT&T', filters)}
                                />
                                <Filters
                                    className="filters"
                                    title={'T Mobile Status:'}
                                    filters={Object.values(ActivationStatus)}
                                    selectedFilters={adminFilters['T Mobile']}
                                    setSelectedFilters={(filters) => updateSelectedFilters('Admin', 'T Mobile', filters)}
                                />
                                <Filters
                                    className="filters"
                                    title={'Verizon Status:'}
                                    filters={Object.values(ActivationStatus)}
                                    selectedFilters={adminFilters['Verizon']}
                                    setSelectedFilters={(filters) => updateSelectedFilters('Admin', 'Verizon', filters)}
                                />
                            </div>
                        </div>
                        {!isBranded() &&
                            (
                                <div className="filter-container">
                                    <span className="filter-name">Client Filter</span>
                                    <div class='filters-area'>
                                        <Filters
                                            className="filters"
                                            title={'AT&T Status:'}
                                            filters={Object.values(ClientActivationStatus)}
                                            selectedFilters={clientFilters['AT&T']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Client', 'AT&T', filters)}
                                        />
                                        <Filters
                                            className="filters"
                                            title={'T Mobile Status:'}
                                            filters={Object.values(ClientActivationStatus)}
                                            selectedFilters={clientFilters['T Mobile']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Client', 'T Mobile', filters)}
                                        />
                                        <Filters
                                            className="filters"
                                            title={'Verizon Status:'}
                                            filters={Object.values(ClientActivationStatus)}
                                            selectedFilters={clientFilters['Verizon']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Client', 'Verizon', filters)}
                                        />
                                    </div>
                                </div>
                            )}
                        {isBranded() &&
                            (
                                <div className="filter-container">
                                    <span className="filter-name">Branded Filter</span>
                                    <div class='filters-area'>
                                        <Filters
                                            className="filters"
                                            title={'AT&T Status:'}
                                            filters={Object.values(BrandedActivationStatus)}
                                            selectedFilters={brandedFilters['AT&T']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Branded', 'AT&T', filters)}
                                        />
                                        <Filters
                                            className="filters"
                                            title={'T Mobile Status:'}
                                            filters={Object.values(BrandedActivationStatus)}
                                            selectedFilters={brandedFilters['T Mobile']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Branded', 'T Mobile', filters)}
                                        />
                                        <Filters
                                            className="filters"
                                            title={'Verizon Status:'}
                                            filters={Object.values(BrandedActivationStatus)}
                                            selectedFilters={brandedFilters['Verizon']}
                                            setSelectedFilters={(filters) => updateSelectedFilters('Branded', 'Verizon', filters)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        <div class='dropdown-area'>
                            <Dropdown
                                title={'Filter by State:'}
                                options={states}
                                selectedOption={selectedState}
                                setSelectedOption={setSelectedState}
                            />
                            <Dropdown
                                title={'Filter by FTC:'}
                                options={['Yes', 'No']}
                                selectedOption={ftcFilter}
                                setSelectedOption={setFtcFilter}
                            />
                        </div>
                        <div className='filter-action-buttons'>
                            <button onClick={handleSearchClick} className='filter-button'>
                                <span className="filter-icon"><IoIosSearch /></span> Filter
                            </button>
                            <button onClick={handleResetClick} className='filter-button'>
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            }
            <div>
                {showUploadFileModal &&
                    (
                        <FileUploadModal
                            companyId={id}
                            onCloseModal={onCloseUploadFile}
                        />
                    )
                }
            </div>
            <div>
                {popupConfirmation &&
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={deleteAllNumbers}
                                title={'Are you sure you want to delete all numbers from this client?'}
                            />
                        )
                    )
                }
            </div>
            {isNumbersScreen() &&
                <div className='pagination-container'>
                    <span className="pagination-text">Results Per Page:</span>
                    <Dropdown
                        options={[10, 25, 50, 100]}
                        selectedOption={pageSize}
                        setSelectedOption={setNewPageSize}
                        useDefaultOption={false}
                    />
                </div>
            }
            <Outlet />
        </div>
    );
};

export default CompanyDetails;
