import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import './CompanyEdit.css';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { IoIosSave, AiFillDelete } from '../../icons';
import { fetchDashboardById, updateDashboardItem, zipCodeSearch, deleteDashboardItem } from '../../slice/dashboardSlice';
import Loader from '../Loader';
import { BsSearch } from '../../icons';
import toast, { Toaster } from 'react-hot-toast';
import PopupConfirmation from '../PopupConfirmation';

const CompanyEdit = () => {
    const [data, setData] = useState('');
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const company = useSelector(state => state.dashboard.dashboard);
    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeNotFound, setZipCodeNotFound] = useState(false);
    const [zipCodeLoading, setZipCodeLoading] = useState(false);
    const navigate = useNavigate();
    const [popupConfirmation, setPopupConfirmation] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        setValue
    } = useForm();

    const onSubmit = async () => {
        setUpdateInProgress(true);
        await dispatch(updateDashboardItem({ data }));
        await dispatch(fetchDashboardById({ id }));
        setUpdateInProgress(false);

        toast.success('The client has been successfully updated.', {
            position: 'top-center',
            duration: 3000
        });
    };

    useEffect(() => {
        dispatch(fetchDashboardById({ id }));
    }, [id]);

    useEffect(() => {
        if (updateInProgress) {
            return;
        }

        setData(company);
        reset({ ...company });
    }, [company]);

    const onSearch = async () => {
        const zipCode = getValues('zipCode');
        if (!validateZipCode(zipCode)) {
            setZipCodeError(true);
            return;
        }

        setZipCodeLoading(true);
        setZipCodeNotFound(false);
        setZipCodeError(false);

        const response = await dispatch(zipCodeSearch(zipCode));
        if (!response.payload) {
            setZipCodeNotFound(true);
            setZipCodeLoading(false);
            return;
        }

        const zipCodeInfo = response.payload;
        setValue('city', zipCodeInfo.city.replace(/^"|"$/g, ''));
        setValue('state', zipCodeInfo.state);
        setZipCodeLoading(false);
    };

    const validateZipCode = value => {
        return /^\d{5}$/.test(value);
    };

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSearch();
        }
    };

    const handleDeleteClick = async () => {
        await dispatch(deleteDashboardItem({ id }));
        navigate('/dashboard');
    };

    return (
        <div>
            <Toaster position="top-right" reverseOrder={false} />
            {data ? (
                <form onSubmit={handleSubmit(onSubmit)} className="form-add">
                    <div className="input-item">
                        <div className="input-title">Company Name</div>
                        <div className="input-wrapper">
                            <input type="text" {...register('companyName', { required: true })} className="add-input" />
                        </div>
                        {errors.companyName && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">Address</div>
                        <div className="input-wrapper">
                            <input type="text" {...register('address', { required: true })} className="add-input" />
                        </div>
                        {errors.address && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">
                            Zip Code <p className="input-star">&nbsp;*</p>
                        </div>
                        <div className="input-wrapper">
                            <div className="zip-code">
                                <InputMask
                                    mask="99999"
                                    placeholder="12345"
                                    type="text"
                                    {...register('zipCode', { required: true, validate: validateZipCode })}
                                    className="input"
                                    onKeyDown={onKeyDown}
                                />
                                <BsSearch className="icon" onClick={() => onSearch()} />
                            </div>
                        </div>
                        {errors.zipCode && <span className="error-span">This field is required</span>}
                        {zipCodeError && <span className="error-span">Zip Code must have 5 digits</span>}
                        {zipCodeNotFound && <span className="error-span">Zip Code not found</span>}
                        {zipCodeLoading && <Loader />}
                    </div>
                    <div className="input-item">
                        <div className="input-title">City</div>
                        <div className="input-wrapper">
                            <input type="text" {...register('city', { required: true })} className="add-input" />
                        </div>
                        {errors.city && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">State</div>
                        <div className="input-wrapper">
                            <input type="text" {...register('state', { required: true })} className="add-input" />
                        </div>
                        {errors.state && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">Contact Person</div>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                {...register('contactPerson', { required: true })}
                                className="add-input"
                            />
                        </div>
                        {errors.contactPerson && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">Phone</div>
                        <div className="input-wrapper">
                            <InputMask
                                mask="999-999-9999"
                                maskChar="_"
                                placeholder="123-456-7890"
                                type="phone"
                                {...register('number', { required: true })}
                                className="add-input"
                            />
                        </div>
                        {errors.number && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">E-mail</div>
                        <div className="input-wrapper">
                            <input type="email" {...register('email', { required: true })} className="add-input" />
                        </div>
                        {errors.email && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">Registration Date</div>
                        <div className="input-wrapper">
                            <input
                                type="date"
                                {...register('registrationDate', { required: true })}
                                className="add-input"
                            />
                        </div>
                        {errors.registrationDate && <span className="error-span">This field is required</span>}
                    </div>
                    <div className="input-item">
                        <div className="input-title">Status</div>
                        <div className="input-wrapper">
                            <select
                                name="status"
                                {...register('status')}
                                value={data.status || ''}
                                onChange={e => setData({ ...data, status: e.target.value })}
                                className="selector">
                                <option value="inactive" className="option">
                                    Inactive
                                </option>
                                <option value="churned" className="option">
                                    Churned
                                </option>
                                <option value="active" className="option">
                                    Active
                                </option>
                            </select>
                        </div>
                    </div>
                    <button type="submit" className="save">
                        Save
                        <IoIosSave />
                    </button>
                    <button type="button" className="delete" onClick={() => setPopupConfirmation(true)}>
                        Delete Client
                        <AiFillDelete />
                    </button>
                </form>
            ) : (
                <Loader />
            )}
            <div>
                { popupConfirmation && 
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={handleDeleteClick}
                                title={`Are you sure you want to delete the ${data.companyName} client?`}
                            />
                        )
                    )
                }
            </div>
        </div>
    );
};

export default CompanyEdit;
