export const ActivationStatus = {
    1: "Inactive",
    2: "Active",
    3: "Pending",
};

export function getActivationStatusString(number) {
    return ActivationStatus[number] || 'Unknown';
}

export function getActivationStatusNumber(string) {
    const key = Object.keys(ActivationStatus).find((key) => ActivationStatus[key] === string);
    return key ? parseInt(key, 10) : null;
}

export function getAdminFilter(string, prefix) {
    const key = Object.keys(ActivationStatus).find((key) => ActivationStatus[key] === string);
    return key
        ? prefix + 'Admin' + string + '=true&'
        : null;
}

export function isActive(number) {
    return ActivationStatus[number] === "Active";
}