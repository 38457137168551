import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { searchClients } from '../../slice/dashboardSlice';
import { registerUser } from '../../slice/UserSlice';
import { BsSearch } from '../../icons';
import { FaSpinner } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import styles from './AddNewUserByClient.css';
import { getUserInformation } from '../../slice/UserSlice';

const AddNewUserByClient = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const searchItems = useSelector(state => state.dashboard.data);
    const loading = useSelector(state => state.dashboard.loading);
    const registerUserSuccess = useSelector(state => state.user.registerUserSuccess);
    const registerUserError = useSelector(state => state.user.registerUserError);
    const [searchVariables, setSearchVariables] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [passwordError, setPasswordError] = useState(null);
    const [searchError, setSearchError] = useState(false);

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue
    } = useForm();

    const onChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchClick();
        }
        setDropdownVisible(false);
    };

    const onSearch = (searchTerm, id) => {
        setValue('company', searchTerm);
        setCompanyId(id);
        setDropdownVisible(false);
    };

    const handleSearchClick = async () => {
        if (searchEmpty()) {
            return;
        }
        setDropdownVisible(true);
        const searchText = getValues('company');
        dispatch(searchClients({ clientName: searchText }));
    };

    const searchEmpty = () => {
        const searchText = getValues('company');
        return searchText?.length === 0;
    };

    const onSubmit = async (data) => {
        const passwordError = validatePassword(data.password);
        setPasswordError(passwordError);
        if (passwordError) {
            return;
        }

        if (!companyId) {
            setSearchError(true);
            return;
        }
        setSearchError(false);

        await dispatch(registerUser({ id: companyId, userData: data }));
    };

    const validatePassword = (password) => {
        if (!password) {
            return "Password is required";
        } else if (password.length < 8) {
            return "Password must be at least 8 characters";
        } else if (!/\d/.test(password)) {
            return "Password must contain at least one number";
        } else if (!/[A-Za-z]/.test(password)) {
            return "Password must contain at least one letter";
        } else if (!/[@$!%*?&]/.test(password)) {
            return "Password must contain at least one special character";
        }
        return null;
    };

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    useEffect(() => {
        if (searchItems?.length > 0) {
            setSearchVariables(searchItems);
            (getValues('company') === '') ? setDropdownVisible(false) : setDropdownVisible(true);
            return;
        }
        navigate(`/add-new-user`);
    }, [searchItems]);

    useEffect(() => {
        if (registerUserError) {
            toast.error('The User has not been created.', {
                position: 'top-center',
                duration: 3000
            });
        }
    }, [registerUserError]);

    useEffect(() => {
        if (registerUserSuccess) {
            toast.success('The User has been successfully created.', {
                position: 'top-center',
                duration: 3000
            });

            reset();
            setValue('');
        }
    }, [registerUserSuccess]);

    return (
        <div className="content-wrapper">
            <Toaster position="top-right" reverseOrder={false} />
            <h2>Add New User By Client</h2>
            <form className='form-add' onSubmit={handleSubmit(onSubmit)}>
                <div className='input-item'>
                    <div className="input-title">
                        Company <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className={styles.searchWrapper}>
                        <div className="search">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                {...register('company', { required: true })}
                                onKeyDown={onChange}
                                style={{ backgroundColor: 'rgb(248, 248, 248)' }}
                            />
                            {loading ? (
                                <FaSpinner className="search-loading-icon" />
                            ) : (
                                <BsSearch className={`search-icon ${searchEmpty() ? 'search-icon-disabled' : ''}`} onClick={handleSearchClick} />
                            )}
                        </div>
                        {errors.company && <span className="error-span">This field is required</span>}
                        {dropdownVisible && <div className="search-results-dropdown">
                            {searchVariables?.length > 0 && (
                                searchVariables.map(value => (
                                    <div
                                        className="dropdown-row"
                                        key={value.id}
                                        onClick={() => onSearch(value.companyName, value.id)}>
                                        {value.companyName}
                                    </div>
                                ))
                            )
                            }
                        </div>}
                    </div>
                    {searchError && <span className="error-span">The search input must match one existing company</span>}
                </div>
                <div className='input-item' />
                <div className="input-item">
                    <div className="input-title">
                        First Name <p className="input-star">&nbsp;*</p>
                    </div>
                    <input
                        placeholder="John Smith"
                        type="text"
                        {...register('firstName', { required: true })}
                        className="add-input"
                    />
                    {errors.firstName && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Last Name <p className="input-star">&nbsp;*</p>
                    </div>
                    <input
                        placeholder="John Smith"
                        type="text"
                        {...register('lastName', { required: true })}
                        className="add-input"
                    />
                    {errors.lastName && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        E-mail <p className="input-star">&nbsp;*</p>
                    </div>
                    <input
                        placeholder="john@example.com"
                        type="email"
                        {...register('email', { required: true })}
                        className="add-input"
                    />
                    {errors.email && <span className="error-span">This field is required</span>}
                </div>

                <div className="input-item">
                    <div className="input-title">
                        Password <p className="input-star">&nbsp;*</p>
                    </div>
                    <input
                        placeholder="********"
                        type="password"
                        {...register('password', { required: true })}
                        className="add-input"
                    />
                    {errors.password && <span className="error-span">This field is required</span>}
                    {!errors.password && passwordError !== null && <span className="error-span">{passwordError}</span>}
                </div>
                <button type="submit" className="submit add-input">Submit</button>
            </form>
        </div>
    );
};

export default AddNewUserByClient;
