import UsaMap from '../../components/UsaMap';

const StatisticsRegion = () => {
	return (
		<div className="div content-wrapper">
			<h2 className='title-usa-map'>Client's Number Statistics by Regions in the USA</h2>
			<UsaMap />
		</div>
	);
};

export default StatisticsRegion;
