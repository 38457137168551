import React, { useEffect, useState } from 'react';
import './ManageNumbers.css';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IoIosSave } from '../../icons';
import { insertMany, insert } from '../../slice/dashboardPhoneSlice';
import toast, { Toaster } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import Loader from '../Loader';

const ManageNumbers = () => {
    const [data, setData] = useState('');
    const [telephoneNumbers, setTelephoneNumbers] = useState('');
    const dispatch = useDispatch();
    const { id } = useParams();
    const loading = useSelector(state => state.dashboardPhones.loading);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        await dispatch(insert({ id: id, phoneData: data }))
            .unwrap()
            .then(() => {
                toast.success('Number created successfully.', {
                    position: 'top-center',
                    duration: 3000
                });
            })
            .catch((error) => {
                toast.error('Failed to create a new number.', {
                    position: 'top-center',
                    duration: 3000
                });
            });

        reset();
    };

    const handleAddNumbers = async () => {
        const textareaContent = document.getElementById('telephoneNumbers').value;
        const numbersArray = textareaContent.split('\n');
        const numbersSeparatedByComma = numbersArray.join(',');
        const body = {
            Id: id,
            Data: numbersSeparatedByComma
        };
        await dispatch(insertMany(body))
            .unwrap()
            .then(() => {
                toast.success('All numbers have been created.', {
                    position: 'top-center',
                    duration: 3000
                });
            })
            .catch(() => {
                toast.error('Failed to create new numbers.', {
                    position: 'top-center',
                    duration: 3000
                });
            });

        setTelephoneNumbers('');
    };

    const handleChange = (event) => {
        setTelephoneNumbers(event.target.value);
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <Toaster position="top-right" reverseOrder={false} />
                    <div>
                        <h2>Add an unique telephone Number</h2>
                        <form onSubmit={handleSubmit(onSubmit)} className="form-manage-numbers">
                            <div className="input-item">
                                <div className="input-title">Telephone Number</div>
                                <div className="input-wrapper">
                                    <InputMask mask="(999) 999-9999" {...register('telephoneNumber', { required: true })} placeholder="(123) 456-7890">
                                        {(inputProps) => <input type="text" {...inputProps} className="add-input" />}
                                    </InputMask>
                                </div>
                                {errors.telephoneNumber && <span className="error-span">This field is required</span>}
                            </div>
                            <div className="input-item">
                                <div className="input-title">AT&T Status</div>
                                <div className="input-wrapper">
                                    <select
                                        name="atandTStatus"
                                        {...register('atandTStatus')}
                                        value={data.atandTStatus || ''}
                                        onChange={e => setData({ ...data, atandTStatus: e.target.value })}
                                        className="input-selector">
                                        <option value={1} className="option">
                                            Clean
                                        </option>
                                        <option value={2} className="option">
                                            Spam
                                        </option>
                                        <option value={3} className="option">
                                            DNO
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="input-title">Verizon Status</div>
                                <div className="input-wrapper">
                                    <select
                                        name="verizonStatus"
                                        {...register('verizonStatus')}
                                        value={data.verizonStatus || ''}
                                        onChange={e => setData({ ...data, verizonStatus: e.target.value })}
                                        className="input-selector">
                                        <option value={1} className="option">
                                            Clean
                                        </option>
                                        <option value={2} className="option">
                                            Spam
                                        </option>
                                        <option value={3} className="option">
                                            DNO
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="input-title">T-Mobile Status</div>
                                <div className="input-wrapper">
                                    <select
                                        name="tmobileStatus"
                                        {...register('tmobileStatus')}
                                        value={data.tmobileStatus || ''}
                                        onChange={e => setData({ ...data, tmobileStatus: e.target.value })}
                                        className="input-selector">
                                        <option value={1} className="option">
                                            Clean
                                        </option>
                                        <option value={2} className="option">
                                            Spam
                                        </option>
                                        <option value={3} className="option">
                                            DNO
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="input-title">FTC Strikes</div>
                                <div className="input-wrapper">
                                    <select
                                        name="ftcStrikes"
                                        {...register('ftcStrikes')}
                                        value={data.ftcStrikes || ''}
                                        onChange={e => setData({ ...data, ftcStrikes: e.target.value })}
                                        className="input-selector">
                                        <option value={true} className="option">
                                            Yes
                                        </option>
                                        <option value={false} className="option">
                                            No
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div className="input-item">
                                <div className="input-title">AT&T Activation</div>
                                <div className="input-wrapper">
                                    <select
                                        name="atandTActivation"
                                        {...register('atandTActivation')}
                                        value={data.atandTActivation || ''}
                                        onChange={e => setData({ ...data, atandTActivation: e.target.value })}
                                        className="input-selector">
                                        <option value={2} className="option">
                                            Yes
                                        </option>
                                        <option value={1} className="option">
                                            No
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="input-title">T-Mobile Activation</div>
                                <div className="input-wrapper">
                                    <select
                                        name="tmobileActivation"
                                        {...register('tmobileActivation')}
                                        value={data.tmobileActivation || ''}
                                        onChange={e => setData({ ...data, tmobileActivation: e.target.value })}
                                        className="input-selector">
                                        <option value={2} className="option">
                                            Yes
                                        </option>
                                        <option value={1} className="option">
                                            No
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="input-item">
                                <div className="input-title">Verizon Activation</div>
                                <div className="input-wrapper">
                                    <select
                                        name="verizonActivation"
                                        {...register('verizonActivation')}
                                        value={data.verizonActivation || ''}
                                        onChange={e => setData({ ...data, verizonActivation: e.target.value })}
                                        className="input-selector">
                                        <option value={2} className="option">
                                            Yes
                                        </option>
                                        <option value={1} className="option">
                                            No
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <button type="submit" className="create-number">
                                Create Unique Number
                                <IoIosSave />
                            </button>
                        </form>
                        <h2>Add multiples telephone numbers</h2>
                        <div class="number-input-section">
                            <textarea id="telephoneNumbers" value={telephoneNumbers} onChange={handleChange} placeholder="Copy and paste all telephone numbers"></textarea>
                            <button onClick={handleAddNumbers}>Add Numbers</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ManageNumbers;
