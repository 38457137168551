import { React } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './VerizonFeedbackBox.css';

const VerizonFeedbackBox = ({ verizonFeedbacks }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.userInfo);
    const companyContract = useSelector(state => state.dashboard.companyContract);

    const redirectVerizonFeedbackPage = () => {
        if (user?.admin ?? false) {
            navigate(`/client-view/${id}/verizon-feedback`);
            return;
        }

        navigate(`/client-view/${companyContract?.companyId}/verizon-feedback`);
    };

    return <div className="wrapper-chart" onClick={() => redirectVerizonFeedbackPage()}>
        <div className="container-feedback-info">
            <span className="feeback-title-client">Verizon Feedback:</span>
            <span className="feeback-value-client">{verizonFeedbacks.length}</span>
        </div>
    </div>
};

export default VerizonFeedbackBox;