import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { insertDevices, fetchDevices } from '../../slice/devicesSlice';

const RegisterDevices = () => {
    const dispatch = useDispatch();
    const phoneInputRef = useRef(null);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue
    } = useForm();

    const onSubmit = async (data) => {
        data.carrier = parseInt(data.carrier);
        await dispatch(insertDevices({ data }));
        dispatch(fetchDevices());
        reset();

        setValue('number', '');
        if (phoneInputRef.current) {
            phoneInputRef.current.getInputDOMNode().value = '';
        }

        toast.success('The device has been successfully created.', {
            position: 'top-center',
            duration: 3000
        });
    };

    return (
        <div className="content-wrapper">
            <Toaster position="top-right" reverseOrder={false} />
            <h2>Add New Device Record</h2>
            <form className="form-add" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-item">
                    <div className="input-title">
                        Carrier <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <select {...register('carrier', { required: true })} className="add-input">
                            <option value="">Select a carrier</option>
                            <option value="0">AT&T</option>
                            <option value="1">Verizon</option>
                            <option value="2">T-Mobile</option>
                        </select>
                    </div>
                    {errors.carrier && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Device Name <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="AT&T 1"
                            type="text"
                            {...register('deviceName', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.deviceName && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Phone <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <InputMask
                            mask="999-999-9999"
                            maskChar="_"
                            placeholder="123-456-7890"
                            type="phone"
                            {...register('number', { required: true })}
                            className="add-input"
                            inputRef={phoneInputRef}
                        />
                    </div>
                    {errors.number && <span className="error-span">This field is required</span>}
                </div>
                <button type="submit" className="submit add-input">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default RegisterDevices;
