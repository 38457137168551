import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const insertDevices = createAsyncThunk('devices/insertDevices', async ({ data }) => {
    return axios
        .post(getApiEndpoint('devices', 'insert'), {
            carrier: data.carrier,
            deviceName: data.deviceName,
            number: data.number
        })
        .then(res => res.json());
});

export const fetchDevices = createAsyncThunk('devices/fetchDevices', async () => {
    return axios
        .get(getApiEndpoint('devices', 'list'))
        .then(res => res.data);
});

export const deleteDevice = createAsyncThunk('devices/deleteDevice', async ({ id }) => {
    return axios
        .delete(getApiEndpoint('devices', `delete/${id}`))
        .then(res => res.json());
});


const devicesSlices = createSlice({
    name: 'devices',
    initialState: {
        loading: false,
        carriers: [],
        error: '',
    },

    extraReducers: builder => {
        // Insert
        builder.addCase(insertDevices.pending, state => {
            state.loading = true;
        });

        builder.addCase(insertDevices.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(insertDevices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // Fetch
        builder.addCase(fetchDevices.pending, state => {
            state.loading = true;
        });

        builder.addCase(fetchDevices.fulfilled, (state, action) => {
            state.loading = false;
            state.carriers = action.payload.carriers;
            state.error = '';
        });

        builder.addCase(fetchDevices.rejected, (state, action) => {
            state.loading = false;
            state.carriers = [];
            state.error = action.error.message;
        });

        // Delete
        builder.addCase(deleteDevice.pending, state => {
            state.loading = true;
        });

        builder.addCase(deleteDevice.fulfilled, (state) => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(deleteDevice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

    }
});

export default devicesSlices.reducer;
