import React from 'react';
import './SideBar.css';
import logo from '../../logo.svg';
import SideBarMenu from '../../components/SideBarMenu';
import { IoMenu } from '../../icons';

const SideBar = ({ menuOpen, handleToggleMenu }) => {
    return (
        <div className={`${!menuOpen ? 'hidden' : ''} sidebar`}>
            <div className="header-sidebar">
                <div className="header-wrapper-image">
                    <img src={logo} alt="logo" />
                </div>
                <div
                    className={`${!menuOpen ? 'hidden' : ''} hamburger-menu-sidebar`}
                    onClick={() => handleToggleMenu()}>
                    <IoMenu />
                </div>
            </div>
            <div className='version-number'>
                  <span>Version: 3-060624-01</span>
            </div>
            <div className="line"></div>
            <SideBarMenu handleToggleMenu={handleToggleMenu} menuOpen={menuOpen} />
        </div>
    );
};

export default SideBar;