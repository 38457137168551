import React from 'react';
import './Checkbox.css';

const SingleCheckBox = ({ title, selectedOption, onOptionChange }) => {
  const handleOptionChange = (event) => {
    onOptionChange(event.target.checked);
  };

  return (
    <div className='checkboxes'>
        <label>
          <input
            className={`checkbox $`}
            type="checkbox"
            checked={selectedOption}
            onChange={handleOptionChange}
          />
          <span className={`text ${selectedOption  ? 'selected' : ''} ${selectedOption}`}>{title}</span>
        </label>
    </div>
  );
};

export default SingleCheckBox;
