import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const PieChart = ({ data, width, height, colors }) => {
    const pieChartRef = useRef();

    useEffect(() => {
        createChart();
    }, []);

    const createChart = () => {
        const radius = Math.min(width, height) / 2;

        const arc = d3
            .arc()
            .cornerRadius(15)
            .innerRadius(radius - 30)
            .outerRadius(radius);

        const pie = d3
            .pie()
            .startAngle(0)
            .endAngle(2 * Math.PI)
            .padAngle(0.02)
            .sort(null)
            .value(d => d.value);

        const pie2 = d3
            .pie()
            .startAngle(-Math.PI / 2 - 0.01)
            .endAngle(2 * Math.PI)
            .sort(null)
            .value(d => d.value);

        const arc2 = d3
            .arc()
            .cornerRadius(10)
            .outerRadius(radius - 45);

        const color = d3
            .scaleOrdinal()
            .domain(data?.map(d => d.key))
            .range(d3.quantize(t => d3.interpolateRgbBasis(colors)(t), data?.length));

        const svg = d3
            .select(pieChartRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [-width / 2, -height / 2, width, height])
            .attr('style', 'max-width: 100%; height: auto;');
        
        const spamRateData = [
            { key: 1, value: 100 - data[1].value },
            { key: 2, value: data[1].value }
        ];

        svg.append('g')
            .selectAll()
            .data(pie2([{ name: 'border', value: 1 }]))
            .join('path')
            .attr('stroke', '#E1E1E1')
            .attr('stroke-dasharray', '10,10')
            .attr('stroke-width', 5)
            .attr('fill', 'transparent')
            .attr('d', arc2);

        svg.append('g')
            .selectAll()
            .data(pie(spamRateData))
            .join('path')
            .attr('fill', d => color(d.data?.key))
            .attr('d', arc);

        svg.append('text')
            .attr('text-anchor', 'middle')
            .text('Spam Rate')
            .attr('class', 'spam-rate-text')
            .attr('dy', '-1em');

        svg.append('text')
            .attr('text-anchor', 'middle')
            .text(data[1].value + '%')
            .attr('dy', '.8em')
            .attr('class', 'percent-text');
    };

    return <div ref={pieChartRef} />;
};

export default PieChart;
