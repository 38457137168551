import React from 'react';
import './Checkboxes.css';

const Checkboxes = ({ options, selectedOption, onOptionChange }) => {
  const handleOptionChange = (option) => {
    onOptionChange(option);
  };

  return (
    <div className='checkboxes'>
      {options.map((option, index) => (
        <label key={index}>
          <input
            className={`checkbox ${option}`}
            type="checkbox"
            checked={selectedOption === option}
            onChange={() => handleOptionChange(option)}
          />
          <span className={`text ${selectedOption === option ? 'selected' : ''} ${option}`}>{option}</span>
        </label>
      ))}
    </div>
  );
};

export default Checkboxes;
