import { getAdminFilter } from '../models/activationStatus';
import { getClientFilter } from '../models/clientActivationStatus';
import { getBrandedFilter } from '../models/brandedActivationStatus';

const initialState = {
    hasFilter: false,
    activeFilter: null,
}

let filters = initialState;

export const getFilters = () => filters;

export const updateFilters = (id, pageSize, page, adminFilters, clientFilters, brandedFilters, selectedState, isBranded, ftcFilter) => {
    const atandtAdminStatus = adminFilters['AT&T'].map(_ => getAdminFilter(_, 'att'));
    const tmobileAdminStatus = adminFilters['T Mobile'].map(_ => getAdminFilter(_, 'tmobile'));
    const verizonAdminStatus = adminFilters['Verizon'].map(_ => getAdminFilter(_, 'verizon'));

    const atandtClientStatus = clientFilters['AT&T'].map(_ => getClientFilter(_, 'att'));
    const tmobileClientStatus = clientFilters['T Mobile'].map(_ => getClientFilter(_, 'tmobile'));
    const verizonClientStatus = clientFilters['Verizon'].map(_ => getClientFilter(_, 'verizon'));

    const atandtBrandedStatus = brandedFilters['AT&T'].map(_ => getBrandedFilter(_, 'att'));
    const tmobileBrandedStatus = brandedFilters['T Mobile'].map(_ => getBrandedFilter(_, 'tmobile'));
    const verizonBrandedStatus = brandedFilters['Verizon'].map(_ => getBrandedFilter(_, 'verizon'));

    filters = {
        hasFilter: true,
        activeFilter: {
            companyId: id,
            pageSize: pageSize,
            page: page,
            atandtAdminStatus,
            tmobileAdminStatus,
            verizonAdminStatus,
            atandtClientStatus,
            tmobileClientStatus,
            verizonClientStatus,
            atandtBrandedStatus,
            tmobileBrandedStatus,
            verizonBrandedStatus,
            state: selectedState,
            branded: isBranded,
            ftcStatus: ftcFilter
        }
    };
};

export const resetFilter = () => {
    filters = initialState;
};