import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DevicesView.css';
import { deleteDevice, fetchDevices } from '../../slice/devicesSlice';
import Loader from '../Loader';
import PopupConfirmation from '../PopupConfirmation';

const DevicesView = () => {
    const dispatch = useDispatch();
    const carriers = useSelector(state => state.devices.carriers);
    const loading = useSelector(state => state.devices.loading);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [devices, setDevices] = useState(null);

    useEffect(() => {
        dispatch(fetchDevices());
    }, []);

    useEffect(() => {
        setDevices(carriers);
    }, [carriers]);

    const handleDeleteClick = async () => {
        await dispatch(deleteDevice({ id: deviceInfo.id }));
        dispatch(fetchDevices());
        setPopupConfirmation(false);
    }

    const handleDeleteConfirmationPopup = (device) => {
        setPopupConfirmation(true);
        setDeviceInfo(device);
    }

    return (
        <>
            <div className="call-logs-container">
                {!loading && devices?.length > 0 && (
                    <>
                        <div className="call-logs-table">
                            <table className="logs-table">
                                <thead>
                                    <tr>
                                        <th>Carrier</th>
                                        <th>Device Name</th>
                                        <th>Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {devices?.map(carrier => (
                                        carrier.devices.map(obj => {
                                            const { deviceName, number } = obj;
                                            return (
                                                <tr>
                                                    <td>{carrier.carrierName}</td>
                                                    <td>{deviceName}</td>
                                                    <td>{number}</td>
                                                    <td className='call-logs-buttons'>
                                                        <div className="delete-button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteConfirmationPopup(obj);
                                                            }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path fillRule="evenodd" clip-rule="evenodd" d="M8.49422 6.37364H16.5311L15.474 17.8031C15.4241 18.3411 14.9639 18.75 14.4082 18.75H6.53277C5.97705 18.75 5.51681 18.3411 5.46698 17.8031L4.50805 7.43436L8.49422 6.37364ZM4.06056 4.57897C3.50827 4.72593 3.02388 5.17664 3.14326 5.61914L3.50329 6.95345C3.52412 7.03067 3.59577 7.08313 3.69114 7.05774L16.7591 3.5804C16.8545 3.55501 16.8901 3.47396 16.8692 3.39682L16.5092 2.06251C16.3888 1.6161 15.7434 1.47025 15.1911 1.61717L11.7754 2.52609L11.5675 1.75571C11.47 1.39426 11.0903 1.17614 10.7262 1.27301L7.75358 2.06405C7.38933 2.16138 7.17071 2.53764 7.26828 2.89931L7.47625 3.67012L4.06056 4.57897ZM8.03362 3.52173L7.82577 2.75131C7.8182 2.72333 7.82212 2.69352 7.83666 2.6684C7.8512 2.64329 7.87518 2.62494 7.90333 2.61736L10.8752 1.82654C10.8892 1.82268 10.9037 1.82162 10.9181 1.82342C10.9325 1.82522 10.9463 1.82983 10.9589 1.837C10.9714 1.84417 10.9824 1.85375 10.9912 1.86518C11 1.8766 11.0064 1.88965 11.0101 1.90357L11.2181 2.67435L8.03362 3.52173ZM6.5275 8.83574L7.11748 15.2148H8.31645L7.7265 8.83574H6.5275ZM11.0758 8.83574H9.88183L9.86562 15.2148H11.0596L11.0758 8.83574ZM14.1994 8.83574H13.0004L12.4105 15.2148H13.6095L14.1994 8.83574Z" fill="#3C5163" />
                                                            </svg>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                {!loading && devices?.length === 0 && (
                    <div className="no-data-message">No devices available</div>
                )}
                {loading && <Loader />}
            </div>
            <div>
                {popupConfirmation &&
                    (
                        (
                            <PopupConfirmation
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={handleDeleteClick}
                                title={'Are you sure you want to delete this call logger?'}
                            />
                        )
                    )
                }
            </div>
        </>
    );
};

export default DevicesView;
