import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

let retry = false;

export const configureResponseInterceptor = () => {
  axios.interceptors.response.use(
    async (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && isLoginEndpoint(originalRequest, 'login')) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !retry) {
        retry = true;

        if (await refreshToken()) {
          return axios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
};

export const configureAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      const storedToken = JSON.parse(window.localStorage.getItem('authToken'))?.token;
      if (storedToken) {
        config.headers['Authorization'] = `Bearer ${storedToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const isLoginEndpoint = (request, endpoint) =>
  request.url === getApiEndpoint('users', endpoint);

const getStoredAuthToken = () => JSON.parse(window.localStorage.getItem('authToken'));

const refreshToken = async () => {
  const storedToken = getStoredAuthToken();
  const url = getApiEndpoint('users', 'refreshtoken');
  const body = { accessToken: storedToken.token, refreshToken: storedToken.refreshToken };

  try {
    const response = await axios.post(url, body);
    if (response.status === 200) {
      const newRefreshToken = response.data;
      window.localStorage.setItem('authToken', JSON.stringify(newRefreshToken));
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + newRefreshToken.token;
      return true;
    }
    else if (response.status === 401) {
      window.localStorage.setItem('unauthorized', 'true');
    }
  } catch (error) {
    return false;
  }
};
