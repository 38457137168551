import React, { useEffect, useState } from 'react';
import './CardsNumbers.css';
import { getTotalSpamNumbers, getTotalSpamNumbersByCompanyId } from '../../slice/companiesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import NumberDetails from '../NumberDetails';
import { getSpamNumbersAdmin, getSpamNumbersNonAdmin } from '../../slice/dashboardPhoneSlice';

const CardsNumbers = () => {
    const dispatch = useDispatch();
    const companies = useSelector(state => state.companies.companiesData);
    const { id } = useParams();
    const [selectedCompanyName, setSelectedCompanyName] = useState(null);
    const user = useSelector(state => state.user.userInfo);

    useEffect(() => {
        getPromise();
    }, []);

    const getPromise = async () => {
        if (id) {
            await dispatch(getTotalSpamNumbersByCompanyId(id));
            return;
        }

        await dispatch(getTotalSpamNumbers());
    };

    const handleCompanyClick = (companyName) => {
        setSelectedCompanyName(companyName);

        if (user?.admin) {
            dispatch(getSpamNumbersAdmin({id: id, branded: 'any', carrier: companyName}));
            return;
        }

        dispatch(getSpamNumbersNonAdmin({branded: 'any', carrier: companyName}));
    };

    return (
        <>
            <div className="list-cards">
                {companies?.map(item => (
                    <div key={item.id} className="content-wrapper card" onClick={() => handleCompanyClick(item.name)}>
                        <div className="card-header">
                            <img src={item.icon} alt={item.name} className="card-icon" />
                        </div>
                        <div className="total-numbers">
                            {item.totalNumbers}
                        </div>
                    </div>
                ))}
            </div>
            {selectedCompanyName !== null && (
                <NumberDetails title={selectedCompanyName} onCloseModal={() => setSelectedCompanyName(null)} />
            )}
        </>
    );
};

export default CardsNumbers;
