import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './ManageDevices.css';
import RegisterDevices from '../../components/RegisterDevices';
import DevicesView from '../../components/DevicesView';
import { getUserInformation } from '../../slice/UserSlice';

const ManageDevices = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInformation());
  }, []);

  return (
    <div className="test-phone-number">
      <span className="test-phone-title">Manage Devices</span>
      <div className="rectangle-container">
        <div className="rectangle">
          <span className="test-phone-section register-number">Register Number</span>
          <RegisterDevices />
        </div>
      </div>
      <div className="rectangle-container">
        <div className="rectangle">
          <span className="test-phone-section">Manage Numbers</span>
          <DevicesView />
        </div>
      </div>
    </div>
  );
};

export default ManageDevices;
