import React from 'react';
import './Dropdown.css';

const Dropdown = ({ title, options, selectedOption, setSelectedOption, useDefaultOption = true }) => {
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <div className='dropdown'>
            {title && <span className='filter-title'>{title}</span>}
            <select className='select' value={selectedOption} onChange={handleOptionChange}>
                {useDefaultOption && <option value="">All</option>}
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default Dropdown;