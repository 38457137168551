import React, { useEffect, useState } from 'react';
import './View.css';

import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchClients } from '../../slice/dashboardSlice';
import { BsSearch } from '../../icons';
import { FaSpinner } from 'react-icons/fa';

const View = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const searchItems = useSelector(state => state.dashboard.data);
    const loading = useSelector(state => state.dashboard.loading);
    const [searchVariables, setSearchVariables] = useState('');
    const [value, setValue] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isSearchApplied, setSearchApplied] = useState(false);
    const { pathname } = location;
    const isViewPath = pathname === '/view';

    const onChange = e => {
        setValue(e.target.value);
        setDropdownVisible(false);

        if (value === '') {
            setSearchApplied(false);
        }
    };

    const onSearch = (searchTerm, id) => {
        setValue(searchTerm);
        navigate(`./${id}/edit`);
        setDropdownVisible(false);
    };

    const handleSearchClick = async () => {
        if (searchEmpty())
        {
            return;
        }
        setSearchApplied(true);
        setDropdownVisible(true);
        dispatch(searchClients({ clientName: value }));
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    const searchEmpty = () => {
        return value.length === 0;
    };

    useEffect(() => {
        if (searchItems?.length > 0) {
            setSearchVariables(searchItems);
            (value === '' ) ? setDropdownVisible(false) : setDropdownVisible(true);
            return;
        }

        navigate(`/view`);
    }, [searchItems]);

    return (
        <div className="content-wrapper">
            <h2>View / Modify Existing Client</h2>
            <div className="search-wrapper">
                <div className="search">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search..."
                        value={value}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                    {loading ? (
                        <FaSpinner className="search-loading-icon" />
                    ) : (
                        <BsSearch className={`search-icon ${searchEmpty() ? 'search-icon-disabled' : ''}`} onClick={handleSearchClick}/>
                    )}
                </div>
                {dropdownVisible && <div className="dropdown-search">
                    {searchVariables?.length > 0 && (
                            searchVariables.map(value => (
                                <div
                                    className="dropdown-row"
                                    key={value._id}
                                    onClick={() => onSearch(value.companyName, value.id)}>
                                    {value.companyName}
                                </div>
                            ))
                        )
                    }
                </div>}
            </div>
            <div className='feedback-messages'>
                {isViewPath && value === '' && <div className='search-instruction'>Please use the search to find a client.</div>}
                {searchItems?.length === 0 && !loading && isSearchApplied && value !== '' && <div className='no-results-message'>No clients found that match the search criteria.</div>}
            </div>
            <Outlet />
        </div>
    );
};

export default View;
