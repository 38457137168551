import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { area, line } from 'd3-shape';
import './DisplayLineChart.css';

const LineChart = ({ data }) => {
	const lineChartRef = useRef();
	const [width, setWidth] = useState(0);
	const height = 400;
	const margin = {
		left: 40,
		right: 20,
		top: 20,
		bottom: 50
	};

	useEffect(() => {
		const handleResize = () => {
			setWidth(lineChartRef.current.clientWidth);
		};
		setWidth(lineChartRef.current.clientWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (!width) return;

		d3.select(lineChartRef.current).selectAll('svg').remove();

		const numberOfXTicks = data.x.data.length;
		let calculatedWidth = width;

		const minWidth = numberOfXTicks * 30;
		if (calculatedWidth < minWidth) {
			calculatedWidth = minWidth;
		}

		const svg = d3.select(lineChartRef.current).append('svg').attr('width', calculatedWidth).attr('height', height);

		const currentWidth = calculatedWidth - margin.left - margin.right;
		const currentHeight = height - margin.bottom - margin.top;

		const g = svg.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

		const xScale = d3
			.scaleLinear()
			.domain([0, data.x.data.length - 1])
			.range([0, currentWidth]);

		const maxY = Math.max(...data.source.map(item => Math.max(...item.data))) + 1;
		const numberOfYTicks = Math.min(10, Math.ceil(maxY));
		const yScale = d3.scaleLinear().range([currentHeight, 0]).domain([0, maxY]);
		yScale.tickFormat(d3.format('.0f'));

		const xGrid = g
			.selectAll('g.verticalGrid')
			.data(xScale.ticks(data.x.data.length - 1))
			.enter()
			.append('g')
			.attr('class', 'verticalGrid');

		xGrid
			.append('line')
			.attr('x1', d => xScale(d))
			.attr('x2', d => xScale(d))
			.attr('y1', 0)
			.attr('y2', currentHeight)
			.style('stroke', '#f5f5f5');

		xGrid
			.append('text')
			.text((d, index) => data.x.data[index])
			.attr('x', d => xScale(d))
			.attr('y', currentHeight + 30)
			.attr('text-anchor', 'middle')
			.attr('font-size', '1.16em')
			.attr('fill', '#A2A2AC');

		const yGrid = g
			.selectAll('g.horizontalGrid')
			.data(yScale.ticks(numberOfYTicks))
			.enter()
			.append('g')
			.attr('class', 'horizontalGrid');

		yGrid
			.append('line')
			.attr('x1', 0)
			.attr('x2', currentWidth)
			.attr('y1', d => yScale(d))
			.attr('y2', d => yScale(d))
			.style('stroke', '#f5f5f5');

		yGrid
			.append('text')
			.text(d => d)
			.attr('x', -20)
			.attr('y', d => yScale(d))
			.attr('text-anchor', 'end')
			.attr('font-size', '1.16em')
			.attr('fill', '#A2A2AC');

		const areaGenerator = area()
			.x((d, index) => xScale(index))
			.y0(yScale(0))
			.y1(d => yScale(d))
			.curve(d3.curveLinear);

		g.append('path')
			.attr('d', areaGenerator(data.source[0].data))
			.attr('opacity', 0.3)
			.attr('fill', '#f0f0f0')
			.attr('stroke', 'none');

		data.source.forEach((source, indexSource) => {
			const d = source.data;
			const color = source.color || '#7fa1f7';

			const seriesGroup = g.append('g');

			const tooltip = d3
				.select('body')
				.append('div')
				.style('position', 'absolute')
				.style('visibility', 'hidden')
				.style('background-color', '#fff')
				.style('border', '1px solid #ccc')
				.style('padding', '10px')
				.style('border-radius', '4px')
				.style('box-shadow', '2px 2px 4px rgba(0, 0, 0, 0.2)')
				.style('text-align', 'center')
				.style('font-size', '1.16em')
				.style('color', '#333')
				.style('max-width', '200px');

			const lineGenerator = line()
				.x((d, index) => xScale(index))
				.y(d => yScale(d))
				.curve(d3.curveLinear);

			seriesGroup
				.append('path')
				.attr('d', lineGenerator(d))
				.attr('opacity', 1)
				.attr('fill', 'none')
				.attr('stroke', color)
				.attr('stroke-width', 4);

			const circleGroup = seriesGroup
				.selectAll('.group-circle')
				.data(d)
				.enter()
				.append('g')
				.attr('class', 'group-circle')
				.on('mouseover', (e, d) => {
					const currentElement = d3.select(e.target);
					currentElement.selectAll('circle').attr('r', 14).attr('fill', '#023e8a');

					const index = currentElement.datum();
					const xPosition = xScale(index);

					d3.select(lineChartRef.current)
						.select('.x-line')
						.attr('x1', xPosition)
						.attr('x2', xPosition)
						.style('visibility', 'visible');

					let content = `<div class="tooltip-content">${d} (${data.source[indexSource].label})</div>`;

					tooltip
						.html(content)
						.style('top', e.clientY - 50 + 'px')
						.style('left', e.clientX - 40 + 'px')
						.style('visibility', 'visible')
						.style('background-color', '#3C5163')
						.style('color', '#ffffff')
						.style('padding', '10px 15px')
						.style('border-radius', '20px')
						.style('font-size', '1.16em');
				})
				.on('mousemove', e => {
					tooltip.style('top', e.clientY - 50 + 'px').style('left', e.clientX - 40 + 'px');
				})
				.on('mouseout', e => {
					d3.select(e.target).selectAll('circle').attr('r', 8).attr('fill', color);
					d3.select(lineChartRef.current).select('.x-line').style('visibility', 'hidden');
					tooltip.style('visibility', 'hidden');
				});

			circleGroup
				.append('circle')
				.attr('cx', (d, index) => xScale(index))
				.attr('cy', d => yScale(d))
				.attr('r', (d, index) => (index >= 0 && d > 0 ? 12 : 0))
				.attr('fill', 'white')
				.attr('stroke', color)
				.attr('stroke-width', 1);

			circleGroup
				.append('circle')
				.attr('cx', (d, index) => xScale(index))
				.attr('cy', d => yScale(d))
				.attr('r', (d, index) => (index >= 0 && d > 0 ? 8 : 0))
				.attr('fill', color);
		});
	}, [width, data]);

	return <div ref={lineChartRef} style={{ overflowY: 'auto' }} />;
};

export default LineChart;
