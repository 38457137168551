import React, { useState } from 'react';
import './OverusedTable.css';
import PopupConfirmation from '../PopupConfirmation';
import PhoneEditModal from '../PhoneEditModal';
import { IoMdCreate } from '../../icons';
import { getActivationStatusString } from '../../models/activationStatus';
import { isBranded, isTrial } from '../../models/brandedActivationStatus';
import { getClientActivationStatusString, isSpamStatus } from '../../models/clientActivationStatus';
import useSorter from '../../service/sorterService';
import { useSelector } from 'react-redux';

const OverusedTable = ({
    config,
    redirect,
    data,
    page,
    pageSize,
    setPage,
    redirectNumber,
    totalRegisters,
    onDelete,
    onUpdate,
    selectedNumbers,
    handleSelectedNumbers,
    onSort,
    onSelectAll
}) => {
    const user = useSelector(state => state.user.userInfo);
    const [phoneModalOpened, setPhoneModalOpened] = useState(false);
    const [popupConfirmation, setPopupConfirmation] = useState(false);
    const [selectedPhoneInfo, setSelectedPhoneInfo] = useState(null);
    const { sortingOptions, setSortingOptions, updateCurrentSorter } = useSorter();

    let totalPages = 0;

    const calculateShowingContent = (total, page, pageSize) => {
        if (total === 0) {
            return `Showing all ${total} entries`;
        } else {
            totalPages = Math.floor(total / pageSize);
            const startRange = page * pageSize + 1;
            const endRange = Math.min((page + 1) * pageSize, total);
            return `Showing ${startRange}-${endRange} of ${total} entries`;
        }
    };

    const handleEditClick = phoneInfo => {
        setPhoneModalOpened(true);
        setSelectedPhoneInfo(phoneInfo);
    };

    const handleDeleteClick = phoneInfo => {
        setPopupConfirmation(true);
        setSelectedPhoneInfo(phoneInfo);
    };

    const handleSort = key => {
        const isAscending = sortingOptions[key];
        updateCurrentSorter({ sortBy: key, isAscending });
        setSortingOptions({ ...sortingOptions, [key]: !isAscending });
        onSort();
    };

    const handleCheckboxChange = (event, number) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            handleSelectedNumbers(prevSelectedNumbers => [...prevSelectedNumbers, number]);
        } else {
            handleSelectedNumbers(prevSelectedNumbers => prevSelectedNumbers.filter((num) => num !== number));
        }
    };

    return (
        <>
            <div className="table">
                <table className="dash-table">
                    <thead>
                        {config.hasOwnProperty('businessName') ? (
                            <tr>
                                <th onClick={() => handleSort('businessName')}>{config.businessName.title} {sortingOptions.businessName ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('callerNumber')}>{config.callerNumber.title} {sortingOptions.callerNumber ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('status')}>{config.status.title} {sortingOptions.status ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('state')}>{config.state.title} {sortingOptions.state ? '▲' : '▼'}</th>
                            </tr>
                        ) : (
                            <tr>
                                <th className="center-checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={onSelectAll}
                                        checked={selectedNumbers.length === data.length && data.length > 0}
                                    />
                                </th>
                                <th onClick={() => handleSort('number')}>{config.callerNumber.title} {sortingOptions.number ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('state')}>{config.state.title} {sortingOptions.state ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('attstatus')}>{config.atAndT.title} {sortingOptions.attstatus ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('tmobilestatus')}>{config.tMobile.title} {sortingOptions.tmobilestatus ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('verizonstatus')}>{config.verizon.title} {sortingOptions.verizonstatus ? '▲' : '▼'}</th>
                                <th onClick={() => handleSort('ftcstatus')}>{config.ftcStrike?.title} {sortingOptions.ftcstatus ? '▲' : '▼'}</th>
                                {user?.admin ? <th>{config.action.title}</th> : null}
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {data?.length !== 0 ? (
                            data?.map(obj => {
                                if (obj.hasOwnProperty('companyName')) {
                                    const { companyName, number, state, status } = obj;
                                    return (
                                        <tr key={obj.id} onClick={() => redirect(obj.id)} >
                                            <td>{companyName}</td>
                                            <td>{number}</td>
                                            <td
                                                className="status-capitalize"
                                                style={
                                                    obj.status === 'active'
                                                        ? { color: `#3ed48c` }
                                                        : obj.status === 'inactive'
                                                            ? { color: `#f2c056` }
                                                            : { color: `#f56666` }
                                                }>
                                                {status}
                                            </td>
                                            <td>{state}</td>
                                        </tr>
                                    );
                                } else {
                                    const { tfn, state, tmobile, aTandT, verizon, ftcStrike, aTandTStatus, tmobileStatus, verizonStatus } = obj;
                                    return (
                                        <tr key={obj.id} onClick={(event) => {
                                            if (event.target.tagName.toLowerCase() === 'input') {
                                                return;
                                            }
                                            redirectNumber(tfn)
                                        }}  >
                                            <td className='center-checkbox'>
                                                <input
                                                    type='checkbox'
                                                    onChange={(event) => {
                                                        event.stopPropagation();
                                                        handleCheckboxChange(event, obj.id)
                                                    }}
                                                    checked={selectedNumbers.includes(obj.id)}
                                                />
                                            </td>
                                            <td>{tfn}</td>
                                            <td>{state}</td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(aTandTStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(aTandTStatus)}</div>
                                                    {isBranded(obj.aTandTBranded) && <div className="status branded">Branded</div>}
                                                    {user?.admin && isTrial(obj.aTandTBranded) && <div className="status trial">Trial</div>}
                                                    {user?.admin && <div className="status admin">Admin Status: {getActivationStatusString(aTandT)}</div>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(tmobileStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(tmobileStatus)}</div>
                                                    {isBranded(obj.tmobileBranded) && <div className="status branded">Branded</div>}
                                                    {user?.admin && isTrial(obj.tmobileBranded) && <div className="status trial">Trial</div>}
                                                    {user?.admin && <div className="status admin">Admin Status: {getActivationStatusString(tmobile)}</div>}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(verizonStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(verizonStatus)}</div>
                                                    {isBranded(obj.verizonBranded) && <div className="status branded">Branded</div>}
                                                    {user?.admin && isTrial(obj.verizonBranded) && <div className="status trial">Trial</div>}
                                                    {user?.admin && <div className="status admin">Admin Status: {getActivationStatusString(verizon)}</div>}
                                                </div>
                                            </td>
                                            <td>
                                                <p style={{ color: ftcStrike ? "red" : "green" }}>{ftcStrike ? "YES" : "NO"}</p>
                                                <br />
                                            </td>
                                            {user?.admin ? <td className="actions-buttons">
                                                <div className="edit-button" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditClick(obj);
                                                }}>
                                                    <IoMdCreate className='edit-icon' />
                                                </div>
                                                <div className="delete-button" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(obj);
                                                }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path fillRule="evenodd" clip-rule="evenodd" d="M8.49422 6.37364H16.5311L15.474 17.8031C15.4241 18.3411 14.9639 18.75 14.4082 18.75H6.53277C5.97705 18.75 5.51681 18.3411 5.46698 17.8031L4.50805 7.43436L8.49422 6.37364ZM4.06056 4.57897C3.50827 4.72593 3.02388 5.17664 3.14326 5.61914L3.50329 6.95345C3.52412 7.03067 3.59577 7.08313 3.69114 7.05774L16.7591 3.5804C16.8545 3.55501 16.8901 3.47396 16.8692 3.39682L16.5092 2.06251C16.3888 1.6161 15.7434 1.47025 15.1911 1.61717L11.7754 2.52609L11.5675 1.75571C11.47 1.39426 11.0903 1.17614 10.7262 1.27301L7.75358 2.06405C7.38933 2.16138 7.17071 2.53764 7.26828 2.89931L7.47625 3.67012L4.06056 4.57897ZM8.03362 3.52173L7.82577 2.75131C7.8182 2.72333 7.82212 2.69352 7.83666 2.6684C7.8512 2.64329 7.87518 2.62494 7.90333 2.61736L10.8752 1.82654C10.8892 1.82268 10.9037 1.82162 10.9181 1.82342C10.9325 1.82522 10.9463 1.82983 10.9589 1.837C10.9714 1.84417 10.9824 1.85375 10.9912 1.86518C11 1.8766 11.0064 1.88965 11.0101 1.90357L11.2181 2.67435L8.03362 3.52173ZM6.5275 8.83574L7.11748 15.2148H8.31645L7.7265 8.83574H6.5275ZM11.0758 8.83574H9.88183L9.86562 15.2148H11.0596L11.0758 8.83574ZM14.1994 8.83574H13.0004L12.4105 15.2148H13.6095L14.1994 8.83574Z" fill="#3C5163" />
                                                    </svg>
                                                </div>
                                            </td> : null}
                                        </tr>
                                    );
                                }
                            })
                        ) : (
                            <>You don't have numbers</>
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                {phoneModalOpened &&
                    (
                        <PhoneEditModal
                            onCloseModal={() => setPhoneModalOpened(false)}
                            selectedPhoneInfo={selectedPhoneInfo}
                            onUpdate={onUpdate}
                        />
                    )
                }
            </div>
            <div>
                {popupConfirmation &&
                    (
                        (
                            <PopupConfirmation
                                selectedPhoneInfo={selectedPhoneInfo}
                                onClosePopup={() => setPopupConfirmation(false)}
                                onDelete={onDelete}
                                title={'Are you sure you want to delete this number?'}
                            />
                        )
                    )
                }
            </div>
            <div className="buttons">
                {totalRegisters > pageSize ? (
                    <p className="info">
                        {calculateShowingContent(totalRegisters, page, pageSize)}
                    </p>
                ) : (
                    <p className="info">
                        Showing all {totalRegisters} entries
                    </p>
                )}
                <div>
                    {page === 0 ? (
                        <button disabled>Prev</button>
                    ) : (
                        <button onClick={() => setPage(-1)}>Prev</button>
                    )}
                    {page + 1 <= totalPages ? (
                        <button onClick={() => setPage(1)}>Next</button>
                    ) : (
                        <button disabled>Next</button>
                    )}
                </div>
            </div>
        </>
    );
};

export default OverusedTable;
