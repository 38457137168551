import React, { useEffect } from 'react';
import './ClientView.css';
import CardsNumbers from '../CardsNumbers';
import StatisticsRegion from '../StatisticsRegion';
import ListCharts from '../ListCharts';

const ClientView = () => {
    return (
        <div className="charts">
            <CardsNumbers/>
            <StatisticsRegion/>
            <ListCharts />
        </div>
    );
};

export default ClientView;
