import React, { useState } from 'react';
import './Header.css';
import { IoMenu } from '../../icons';
import {IoIosCog, IoIosNotificationsOutline} from 'react-icons/io';
import NotificationsComponent from '../../components/NotificationsComponent';
import SystemConfigComponent from '../../components/SystemConfigComponent';
import { useSelector } from 'react-redux';

const Header = ({ handleToggleMenu }) => {
	const user = useSelector(state => state.user.userInfo);
	const [notificationsOptions, setNotificationsOptions] = useState(false);
	const [systemOptions, setSystemsOptions] = useState(false);
	const handleNotificationsOptionsPopup = () => {
		setNotificationsOptions(true);
	}
	const handleSystemConfigOptionsPopup = () => {
		setSystemsOptions(true);
	}

	return (
		<>
			{ user &&
				<div className="header">
					<div className="hamburger-menu" onClick={() => handleToggleMenu()}>
						<IoMenu />
					</div>
					<div
						className="notification-icon"
						onClick={() => handleNotificationsOptionsPopup()}>
							<IoIosNotificationsOutline />
					</div>
					{user && user.admin &&  <div
							className="notification-icon"
							onClick={() => handleSystemConfigOptionsPopup()}>
								<IoIosCog />
						</div>
					}
					<div className="header-user">
						{ user.avatar &&
							<div className="header-avatar">
								<img src={user.avatar} alt="User Avatar" />
							</div>
						}
						<div className="header-items">
							<div className="header-welcome">Welcome,</div>
							<div className="header-item">
								{`${user.firstName} ${user.lastName}`}
							</div>
						</div>
					</div>
				</div>
			}
			<div>
				{ notificationsOptions &&
					<NotificationsComponent
						onCloseModal={() => setNotificationsOptions(false)}
					/>
				}
			</div>
			<div>
				{ systemOptions &&
					<SystemConfigComponent
						onCloseModal={() => setSystemsOptions(false)}
					/>
				}
			</div>
		</>
	);
};

export default Header;