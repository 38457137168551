import React, { useEffect, useState } from 'react';
import DisplayLineChart from '../../components/DisplayLineChart';
import { useDispatch, useSelector } from 'react-redux';
import { getChartData } from '../../slice/chartSlice';
import { IoIosArrowDown } from '../../icons';
import './DisplayQuantity.css';

const getYears = () => {
	const initialYear = 2022;
	const currentYear = new Date().getFullYear();
	const listYears = [];
	for (let i = initialYear; i <= currentYear; i++) {
		listYears.push(i);
	}
	return listYears;
};

const DisplayQuantity = () => {
	const [filter, setFilter] = useState(1);
	const [year, setYear] = useState(null);
	const [yearSelectOpen, setYearSelectOpen] = useState(false);
	const listYears = getYears();
	const dispatch = useDispatch();
	const chartData = useSelector(state => state.chart.chartData);

	useEffect(() => {
		getPromise();
	}, [filter]);

	const getPromise = async () => {
		await dispatch(getChartData({ filter }));
	};

	const displayFilters = [
		{ label: '24 hours', value: 1 },
		{ label: 'Week', value: 2 },
		{ label: 'Month', value: 3 }
	];

	const handleSelectOption = value => {
		setYear(value);
		setYearSelectOpen(false);
		setFilter(value);
	};

	return (
		<div className="content-wrapper">
			<div className="container">
				<div className="list-filter">
					{displayFilters.map(displayFilter => (
						<button
							type="button"
							key={displayFilter.value}
							className={`${filter === displayFilter.value && 'active'} display-filter-label`}
							onClick={() => {
								setFilter(displayFilter.value);
								setYearSelectOpen(false);
								setYear(null);
							}}>
							{displayFilter.label}
						</button>
					))}
					<div
						className={`${year && 'active'} ${
							yearSelectOpen && 'open'
						} wrapper-year-filter display-filter-label`}
						onClick={() => setYearSelectOpen(!yearSelectOpen)}>
						<span>{year || 'Year'}</span>
						<div className="arrow-icon">
							<IoIosArrowDown />
						</div>
						{yearSelectOpen && (
							<div className={`${year && 'activeYear'} custom-dropdown`}>
								{listYears.map(year => (
									<div className="dropdown-option" onClick={() => handleSelectOption(year)}>
										{year}
									</div>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="list-chart-label">
					<div className="chart-label green">Active</div>
					<div className="chart-label yellow">Inactive</div>
					<div className="chart-label red">Churned</div>
				</div>
			</div>
			{chartData.source && <DisplayLineChart data={chartData} />}
		</div>
	);
};

export default DisplayQuantity;
