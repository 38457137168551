import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getApiEndpoint } from '../utils/apiUtils';
import axios from 'axios';

export const getChartData = createAsyncThunk('chart/getChartData', async ({ filter = 1 }) => {
	return axios
		.get(getApiEndpoint('stats',`chartdata/${filter}`))
		.then(res => res.data);
});

const chartSlice = createSlice({
	name: 'chart',
	initialState: {
		loading: false,
		chartData: [],
		error: '',
		isSuccess: ''
	},
	extraReducers: builder => {
		builder.addCase(getChartData.pending, state => {
			state.loading = true;
		});

		builder.addCase(getChartData.fulfilled, (state, action) => {
			state.loading = false;
			state.chartData = action.payload;
			state.error = '';
		});

		builder.addCase(getChartData.rejected, (state, action) => {
			state.loading = false;
			state.chartData = [];
			state.error = action.error.message;
		});
	}
});

export default chartSlice.reducer;
