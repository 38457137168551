import React, { useEffect, useState } from 'react';
import './FileUploadModal.css';

import { useDispatch, useSelector } from 'react-redux';
import {
    addNumbers,
    resetAddNumbersState,
    getOverrideNumbers,
    resetDuplicatedNumbers,
    overrideNumbers,
    discardOverrideNumbers,
    uploadNumbersForCurrentClient,
    pendingOverrideByCurrentClient
} from '../../slice/dashboardPhoneSlice';
import { FaExclamationCircle, AiFillPlusCircle, FaFileCsv, IoMdCloseCircle, FaCheckCircle } from '../../icons';
import Loader from '../Loader';
import { getActivationStatusString } from '../../models/activationStatus';
import { isBranded, isTrial } from '../../models/brandedActivationStatus';
import { getClientActivationStatusString, isSpamStatus } from '../../models/clientActivationStatus';

const FileUploadModal = ({ companyId, onCloseModal }) => {
    const [selectedFileName, setSelectedFileName] = useState('');
    const dispatch = useDispatch();
    const fileUploadResponseError = useSelector(state => state.dashboardPhones.isError);
    const fileUploadResponseSuccess = useSelector(state => state.dashboardPhones.isSuccess);
    const fileUploadLoading = useSelector(state => state.dashboardPhones.loading);
    const errorMessage = useSelector(state => state.dashboardPhones.error);
    const duplicatedNumbers = useSelector(state => state.dashboardPhones.duplicatedNumbers);

    const [numbersInfo, setNumbersInfo] = useState(null);

    const handleUpload = async (file) => {
        dispatch(resetAddNumbersState());

        if (!file) {
            return;
        }

        let formData = new FormData();
        formData.append('file', file);

        if (!companyId) {
            await dispatch(uploadNumbersForCurrentClient({ file: formData }));
        } else {
            await dispatch(addNumbers({ companyId, file: formData }));
        }

        await fetchOverrideNumbers();
    };

    const handleFileUpload = e => {
        if (!e.target.files || e.target.files.length === 0) {
            return;
        }

        const file = e.target.files[0];
        handleUpload(file);
    };

    const handleCloseModal = () => {
        dispatch(resetAddNumbersState());
        onCloseModal();
        dispatch(resetDuplicatedNumbers());
    };

    const handleDragOver = e => {
        e.preventDefault();
    };

    const handleDrop = e => {
        e.preventDefault();

        if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
            return;
        }

        const file = e.dataTransfer.files[0];
        handleUpload(file);
    };

    const handleApprove = async (id) => {
        await dispatch(overrideNumbers({ numberIds: [id] }));
        await fetchOverrideNumbers();
    };

    const handleApproveAll = async () => {
        const numbers = getAllNumbersId();
        await dispatch(overrideNumbers({ numberIds: numbers }));
        await fetchOverrideNumbers();
    };

    const handleDeny = async (id) => {
        await dispatch(discardOverrideNumbers({ numberIds: [id] }));
        await fetchOverrideNumbers();
    };

    const handleDenyAll = async () => {
        const numbers = getAllNumbersId();
        await dispatch(discardOverrideNumbers({ numberIds: numbers }));
        await fetchOverrideNumbers();
    };

    const getAllNumbersId = () => {
        return numbersInfo.map(numberInfo => numberInfo.id);
    }

    const fetchOverrideNumbers = async () => {
        if (!companyId) {
            await dispatch(pendingOverrideByCurrentClient());
            return;
        }

        await dispatch(getOverrideNumbers({ companyId }));
    }

    useEffect(() => {
        setNumbersInfo(duplicatedNumbers);
    }, [duplicatedNumbers]);

    return (
        <div className="file-upload-modal">
            <div className="file-upload-modal-content"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div className="file-upload-modal-header">
                    <h2>Add new numbers to an existing client</h2>
                    <button className="file-upload-modal-close-button" onClick={() => handleCloseModal()}>
                        <IoMdCloseCircle />
                    </button>
                </div>
                <div className="file-upload-wrapper">
                    <p className="upload-title">Select the CSV file</p>
                    <label className="custom">
                        {fileUploadLoading ? (
                            <Loader />
                        ) : (
                            <>
                                {selectedFileName !== '' ? <FaFileCsv /> : <AiFillPlusCircle style={{ width: '40px', height: '40px' }} />}
                                <input type="file" accept=".csv" onChange={handleFileUpload} />
                                <div className='custom-text'>{selectedFileName !== '' ? selectedFileName : 'Custom File'}</div>
                            </>
                        )}
                    </label>
                    {fileUploadResponseSuccess ? (
                        <div className="file-upload-success-message">
                            <span className="file-upload-success-icon"><FaCheckCircle /></span>
                            <p>File upload successful! Numbers have been added.</p>
                        </div>
                    ) : (
                        <div>
                            {fileUploadResponseError && (
                                numbersInfo && numbersInfo.length > 0 ? (
                                    <div className="file-upload-error-message">
                                        <span className="file-upload-error-icon"><FaExclamationCircle /></span>
                                        <p>Found some duplicated numbers, please check them in the section below.</p>
                                    </div>
                                ) : null)
                            }
                        </div>
                    )}
                </div>
                {numbersInfo?.length > 0 && (
                    <>
                        <div className="details-summary-header">
                            <h2>Duplicated Numbers</h2>
                            <div className="information-summary">
                                <span className="details-name">Select Numbers to Override</span>
                                <div>
                                    <button className="approve-all-button-modal" onClick={() => handleApproveAll()}>Approve All</button>
                                    <span>/</span>
                                    <button className="deny-all-button-modal" onClick={() => handleDenyAll()}>Deny All</button>
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <table className="dash-table">
                                <thead>
                                    <tr>
                                        <th>TFN</th>
                                        <th>AT&T</th>
                                        <th>T-Mobile</th>
                                        <th>Verizon</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {numbersInfo.map((numberInfo, index) => (
                                        <tr key={index}>
                                            <td>{numberInfo.tfn}</td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(numberInfo.aTandTStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(numberInfo.aTandTStatus)}</div>
                                                    {isBranded(numberInfo.aTandTBranded) && <div className="status branded">Branded</div>}
                                                    {isTrial(numberInfo.aTandTBranded) && <div className="status trial">Trial</div>}
                                                    <div className="status admin">Admin Status: {getActivationStatusString(numberInfo.aTandT)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(numberInfo.tmobileStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(numberInfo.tmobileStatus)}</div>
                                                    {isBranded(numberInfo.tmobileBranded) && <div className="status branded">Branded</div>}
                                                    {isTrial(numberInfo.tmobileBranded) && <div className="status trial">Trial</div>}
                                                    <div className="status admin">Admin Status: {getActivationStatusString(numberInfo.tmobile)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cell-container">
                                                    <div className="status client" style={{ color: isSpamStatus(numberInfo.verizonStatus) ? "#f56666" : "#0000FF" }}>{getClientActivationStatusString(numberInfo.verizonStatus)}</div>
                                                    {isBranded(numberInfo.verizonBranded) && <div className="status branded">Branded</div>}
                                                    {isTrial(numberInfo.verizonBranded) && <div className="status trial">Trial</div>}
                                                    <div className="status admin">Admin Status: {getActivationStatusString(numberInfo.verizon)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <button className="approve-button-modal" onClick={() => handleApprove(numberInfo.id)}>Approve</button>
                                                <span>/</span>
                                                <button className="deny-button-modal" onClick={() => handleDeny(numberInfo.id)}>Deny</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FileUploadModal;
