import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HalfPieChart from '../HalfPieChart';
import NumberDetails from '../NumberDetails';
import { getFtcFlaggedNumbersAdmin, getFtcFlaggedNumbersNonAdmin } from '../../slice/dashboardPhoneSlice';
import { useParams } from 'react-router-dom';

const FederalTradeCommission = ({ data }) => {
    const dispatch = useDispatch();
    const width = 800;
    const height = Math.min(width, 500);
    const value = data?.[0].value;
    const [showFtcNumbers, setShowFtcNumbers] = useState(false);
    const user = useSelector(state => state.user.userInfo);
    const { id } = useParams();

    const handleClick = () => {
        setShowFtcNumbers(true);

        if (user?.admin) {
            dispatch(getFtcFlaggedNumbersAdmin({id: id, branded: 'any' }));
            return;
        }

        dispatch(getFtcFlaggedNumbersNonAdmin({branded: 'any' }));
    };

    return (value !== undefined && value !== null) ? (
        <div className='ftc-container'>
            <div className="wrapper-chart" onClick={() => handleClick()}>
                <HalfPieChart value={value} width={width} height={height} colors={['#5FAEE3', '#F56666']}/>
                <div className="label-half-chart">
                    <span className="percentage-half-chart">{value}</span>
                    <div className="title-half-chart">
                    Federal Trade Commission
                    <br />
                    (FTC) Health
                    </div>
                </div>
            </div>
            {(showFtcNumbers) && (
                <NumberDetails title={'FTC Flagged numbers'} onCloseModal={() => setShowFtcNumbers(false)} />
            )}
        </div>
    ) : null;
};

export default FederalTradeCommission;
