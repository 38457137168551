import axios from 'axios';
import {
    createSlice,
    createAsyncThunk
} from '@reduxjs/toolkit';
import { getApiEndpoint } from '../utils/apiUtils';

export const getUsersList = createAsyncThunk('users/getuserslist', async () => {
    return axios
        .get(getApiEndpoint('users', 'getuserslist'))
        .then(res => res.data);
});

export const fetchDashboardById = createAsyncThunk('dashboard/fetchDashboardById', async ({ id }) => {
    return axios
        .get(getApiEndpoint('company', id))
        .then(res => res.data);
});

export const addDashboardItem = createAsyncThunk('dashboard/addDashboardItem', async ({ data }) => {
    return axios
        .post(
            getApiEndpoint('company', 'create'),
            {
                companyName: data.companyName,
                number: data.number,
                contactPerson: data.contactPerson,
                email: data.email,
                city: data.city,
                state: data.state,
                address: data.address,
                zipCode: data.zipCode,
                registrationDate: data.registrationDate
            }
        )
        .then(res => res.json());
});

export const updateDashboardItem = createAsyncThunk('dashboard/updateDashboardItem', async ({data}) => {
    return axios
        .put(getApiEndpoint('company', `update/${data.id}`), {
            companyName: data.companyName,
            number: data.number,
            contactPerson: data.contactPerson,
            email: data.email,
            city: data.city,
            state: data.state,
            address: data.address,
            zipCode: data.zipCode,
            registrationDate: data.registrationDate,
            status: data.status
        })
        .then(res => res.json());
});

export const zipCodeSearch = createAsyncThunk('dashboard/zipCodeSearch', async (zipCode) => {
    return axios
        .get(getApiEndpoint('zip', zipCode))
        .then(res => res.data);
});

export const getUserCompanyId = createAsyncThunk('dashboard/userCompanyId', async () => {
    return axios
        .get(getApiEndpoint('stats', `userCompanyId`))
        .then(res => res.data);
});

export const searchClients = createAsyncThunk('dashboardPhones/searchClients', async (data) => {
    const { page, pageSize, clientName, callerNumber, status, sorterInfo } = data;
    let urlQuery = `search?page=${page ? page : 0}&pageSize=${pageSize ? pageSize : 10}&clientName=${clientName ? clientName : ''}&callerNumber=${callerNumber ? callerNumber : ''}&status=${status ? status : ''}&`;
    if (sorterInfo) {
        urlQuery += `${sorterInfo}&`;
    }

    return axios
        .get(getApiEndpoint('company', urlQuery))
        .then(res => res.data);
});

export const deleteDashboardItem = createAsyncThunk('dashboard/deleteDashboardItem', async ({ id }) => {
    return axios
        .delete(getApiEndpoint('company', `delete/${id}`))
        .then(res => res.json());
});

const initialState = {
    loading: false,
    data: [],
    users: [],
    error: '',
    isSuccess: '',
    totalRegisters: 0,
    companyContract: {},
    deleteUserSuccess: false,
    deleteUserFailed: false
}


const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,

    extraReducers: builder => {
        // fetch dashboard by id
        builder.addCase(fetchDashboardById.pending, state => {
            state.loading = true;
        });

        builder.addCase(fetchDashboardById.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboard = action.payload;
            state.error = '';
        });

        builder.addCase(fetchDashboardById.rejected, (state, action) => {
            state.loading = false;
            state.dashboard = [];
            state.error = action.error.message;
        });

        // add dashboard item
        builder.addCase(addDashboardItem.pending, state => {
            state.loading = true;
            state.error = '';
        });
        
        builder.addCase(addDashboardItem.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboard = [];
            state.isSuccess = action.payload;
            state.error = '';
        });

        builder.addCase(addDashboardItem.rejected, (state, action) => {
            state.loading = false;
            state.dashboard = [];
            state.error = action.error.message;
        });

        // update dashboard item
        builder.addCase(updateDashboardItem.pending, state => {
            state.loading = true;
            state.error = '';
        });
        
        builder.addCase(updateDashboardItem.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboard = [];
            state.isSuccess = action.payload;
            state.error = '';
        });

        builder.addCase(updateDashboardItem.rejected, (state, action) => {
            state.loading = false;
            state.dashboard = [];
            state.error = action.error.message;
        });


        // Search Clients
        builder.addCase(searchClients.pending, state => {
            state.loading = true;
        });
        
        builder.addCase(searchClients.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data;
            state.totalRegisters = action.payload.totalRegisters;
            state.error = '';
        });

        builder.addCase(searchClients.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.totalRegisters = 0;
            state.error = action.error.message;
        });

        // Delete Client
        builder.addCase(deleteDashboardItem.pending, state => {
            state.loading = true;
        });
        
        builder.addCase(deleteDashboardItem.fulfilled, state => {
            state.loading = false;
            state.error = '';
        });

        builder.addCase(deleteDashboardItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        
        //SearchUsers
        builder.addCase(getUsersList.pending, state => {
            state.loading = true;
        });
        
        builder.addCase(getUsersList.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload;
            state.error = '';
        });

        builder.addCase(getUsersList.rejected, (state, action) => {
            state.loading = false;
            state.users = [];
            state.error = action.error.message;
        });

        builder.addCase(getUserCompanyId.pending, state => {
            state.loading = true;
        });
        
        builder.addCase(getUserCompanyId.fulfilled, (state, action) => {
            state.loading = false;
            state.companyContract = action.payload;
            state.error = '';
        });

        builder.addCase(getUserCompanyId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    }
});

export default dashboardSlice.reducer;
