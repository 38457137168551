import { React, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import './AddNew.css';
import { useDispatch } from 'react-redux';
import { addDashboardItem, zipCodeSearch } from '../../slice/dashboardSlice';
import { useNavigate } from 'react-router-dom';
import { BsSearch } from '../../icons';
import Loader from '../../components/Loader';
import { getUserInformation } from '../../slice/UserSlice';

const AddNew = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeNotFound, setZipCodeNotFound] = useState(false);
    const [zipCodeLoading, setZipCodeLoading] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        getValues,
        setValue
    } = useForm();

    const onSubmit = data => {
        dispatch(addDashboardItem({ data }));
        reset();
        navigate('/dashboard');
    };

    const onSearch = async () => {
        const zipCode = getValues('zipCode');
        if (!validateZipCode(zipCode)) {
            setZipCodeError(true);
            return;
        }

        setZipCodeLoading(true);
        setZipCodeNotFound(false);
        setZipCodeError(false);

        const response = await dispatch(zipCodeSearch(zipCode));
        if (!response.payload) {
            setZipCodeNotFound(true);
            setZipCodeLoading(false);
            return;
        }

        const zipCodeInfo = response.payload;
        setValue('city', zipCodeInfo.city.replace(/^"|"$/g, ''));
        setValue('state', zipCodeInfo.state);
        setZipCodeLoading(false);
    };

    const validateZipCode = value => {
        return /^\d{5}$/.test(value);
    };

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onSearch();
        }
    };

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    return (
        <div className="content-wrapper">
            <h2>Add New Client Record</h2>
            <form className="form-add" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-item">
                    <div className="input-title">
                        Company Name <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="ABC Company"
                            type="text"
                            {...register('companyName', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.companyName && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Address <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="123 Main Street"
                            type="text"
                            {...register('address', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.address && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Zip Code <p className="input-star">&nbsp;*</p>
                    </div>

                    <div className="input-wrapper">
                        <div className="zip-code">
                            <InputMask
                                mask="99999"
                                placeholder="12345"
                                type="text"
                                {...register('zipCode', { required: true })}
                                className="input"
                                onKeyDown={onKeyDown}
                            />
                            <BsSearch className="icon" onClick={() => onSearch()} />
                        </div>
                    </div>
                    {errors.zipCode && <span className="error-span">This field is required</span>}
                    {zipCodeError && <span className="error-span">Zip Code must have 5 digits</span>}
                    {zipCodeNotFound && <span className="error-span">Zip Code not found</span>}
                    {zipCodeLoading && <Loader />}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        City <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="New York"
                            type="text"
                            {...register('city', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.city && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        State <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="New York"
                            type="text"
                            {...register('state', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.state && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Contact Person <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="John Smith"
                            type="text"
                            {...register('contactPerson', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.contactPerson && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Phone <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <InputMask
                            mask="999-999-9999"
                            maskChar="_"
                            placeholder="123-456-7890"
                            type="phone"
                            {...register('number', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.number && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        E-mail <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="john@example.com"
                            type="email"
                            {...register('email', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.email && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item">
                    <div className="input-title">
                        Registration Date <p className="input-star">&nbsp;*</p>
                    </div>
                    <div className="input-wrapper">
                        <input
                            placeholder="123"
                            type="date"
                            {...register('registrationDate', { required: true })}
                            className="add-input"
                        />
                    </div>
                    {errors.registrationDate && <span className="error-span">This field is required</span>}
                </div>
                <div className="input-item"> </div>
                <button type="submit" className="submit add-input">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default AddNew;
