import React, { useState } from "react";
import PieChart from '../PieChart';
import NumberDetails from '../NumberDetails';
import { getSpamNumbersAdmin, getSpamNumbersNonAdmin } from '../../slice/dashboardPhoneSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const PureBrandedCallerID = ({ data }) => {
    const dispatch = useDispatch();
    const width = 1000;
    const height = Math.min(width, 500);
    const [showNumbersModal, setShowNumbersModal] = useState(false);
    const user = useSelector(state => state.user.userInfo);
    const { id } = useParams();

    const handleClick = () => {
        setShowNumbersModal(true);

        if (user?.admin) {
            dispatch(getSpamNumbersAdmin({id: id, branded: 'branded', carrier: 'any'}));
            return;
        }

        dispatch(getSpamNumbersNonAdmin({branded: 'branded', carrier: 'any'}));
    };

    return data ? (
        <div className='pure-branded-caller-id-container' style={{cursor: 'pointer'}}>
            <div className="wrapper-chart" style={{height: height - 200}} onClick={() => handleClick()}>
                <div className="title-wrapper-chart">
                    Total Numbers with
                    <br/>
                    Pure Branded Caller ID:
                    <span className="total-number">{data[0].value}</span>
                </div>
                <div className="title-wrapper-chart-spam">
                    Total Spam flagged numbers:
                    <span className="total-numbers-spam">{data[2].value}</span>
                </div>
                <PieChart data={data} width={width} height={height} colors={['#3ED48C', '#F56666']}/>
            </div>
            {(showNumbersModal) && (
                <NumberDetails title={'Pure Branded Caller ID (Spam Numbers)'}
                               onCloseModal={() => setShowNumbersModal(false)}/>
            )}
        </div>
    ) : null;
};

export default PureBrandedCallerID;
