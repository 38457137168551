export const BrandedActivationStatus = {
    1: "False",
    2: "True",
    3: "Trial",
};

export function isBranded(number) {
    return BrandedActivationStatus[number] === "True";
}

export function isTrial(number) {
    return BrandedActivationStatus[number] === "Trial";
}

export function getBrandedActivationStatusString(number) {
    return BrandedActivationStatus[number] || 'Unknown';
}

export function getBrandedActivationStatusNumber(string) {
    const key = Object.keys(BrandedActivationStatus).find((key) => BrandedActivationStatus[key] === string);
    return key ? parseInt(key, 10) : null;
}

export function getBrandedFilter(string, prefix) {
    const key = Object.keys(BrandedActivationStatus).find((key) => BrandedActivationStatus[key] === string);
    return key
        ? prefix + 'Branded' + string + '=true&'
        : null;
}