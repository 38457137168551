import React from 'react';
import './Filters.css';

const Filters = ({ title, filters, selectedFilters, setSelectedFilters }) => {
    const handleOptionClick = (filter) => {
        if (selectedFilters.includes(filter)) {
            setSelectedFilters(selectedFilters.filter((selected) => selected !== filter));
        } else {
            setSelectedFilters([...selectedFilters, filter]);
        }
    };

    return (
        <div className="filters">
            <span className='filter-title'>{title}</span>
            <div className="filter-options">
                {filters.map((filter, index) => (
                    <div
                        key={filter}
                        className={`${selectedFilters.includes(filter) ? 'active' : ''} option`}
                        onClick={() => handleOptionClick(filter)}
                    >
                        {filter}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Filters;
