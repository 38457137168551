import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";
import {getApiEndpoint, getMobileApiEndpoint} from "../utils/apiUtils";

export const insertNotificationConfiguration = createAsyncThunk('notifications/insert', async ({notificationDTO}) => {
    return axios
        .post(getApiEndpoint('notification', 'insert'), notificationDTO)
        .then(res => res.data);
});

export const getNotifications = createAsyncThunk('notifications/byUser', async () => {
    return axios
        .get(getApiEndpoint('notification', `bycurrentuser`))
        .then(res => res.data);
});

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        config: {
            daily: false,
            weekly: false,
            monthly: false,
            att: false,
            verizon: false,
            tmobile: false,
            ftc: false,
        },
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getNotifications.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(getNotifications.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.config = action.payload;
        });
        builder.addCase(getNotifications.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
        builder.addCase(insertNotificationConfiguration.pending, state => {
            state.status = 'loading';
        });
        builder.addCase(insertNotificationConfiguration.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.config = action.payload;
        });
        builder.addCase(insertNotificationConfiguration.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    }
});

export default notificationsSlice.reducer;