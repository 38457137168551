import React, { useEffect, useState } from 'react';
import './Changes.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { feedBackUpload } from '../../slice/feedbackSlice';
import toast, { Toaster } from 'react-hot-toast';
import { getUserInformation } from '../../slice/UserSlice';

const Changes = () => {
	const dispatch = useDispatch();
	const feedback = useSelector(state => state.feedback.feedback);
	const [selectedFile, setSelectedFile] = useState('');

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm();

	const handleFileChange = e => {
		const file = e.target.files[0];
		setSelectedFile(file);
	};

	const onSubmit = data => {
		const formData = new FormData();
		formData.append('file', selectedFile);
		formData.append('companyName', data.companyName);
		formData.append('firstName', data.firstName);
		formData.append('email', data.email);
		formData.append('date', data.date);
		formData.append('description', data.description);
		dispatch(feedBackUpload(formData));
		reset();
	};

	useEffect(() => {
        dispatch(getUserInformation());
    }, []);

	useEffect(() => {
		if (feedback.message) {
			toast.success(feedback.message);
		}
	}, [feedback]);

	return (
		<div className="content-wrapper">
			<Toaster position="top-center" reverseOrder={false} />
			<h2>Moves / Adds & Changes</h2>
			<p className="changes-info">
				If you would like to change your current enrollment in any way, please create a support ticket through
				the form below and we will make any changes for you. Please allow up to 72 hours for the changes to
				propagate and go live across all the major US wireless carrier networks.
			</p>
			<form className="form-add" onSubmit={handleSubmit(onSubmit)}>
				<div className="input-item">
					<div className="input-title">
						Company name <p className="input-star">&nbsp;*</p>
					</div>
					<div className="input-wrapper">
						<input
							placeholder="Your company name"
							type="text"
							name="companyName"
							{...register('companyName', { required: true })}
							className="add-input"
						/>
					</div>
					{errors.companyName && <span className="error-span">This field is required</span>}
				</div>
				<div className="input-item">
					<div className="input-title">
						First name <p className="input-star">&nbsp;*</p>
					</div>
					<div className="input-wrapper">
						<input
							placeholder="Your name"
							type="text"
							{...register('firstName', { required: true })}
							className="add-input"
						/>
					</div>
					{errors.firstName && <span className="error-span">This field is required</span>}
				</div>
				<div className="input-item">
					<div className="input-title">
						E-mail <p className="input-star">&nbsp;*</p>
					</div>
					<div className="input-wrapper">
						<input
							placeholder="Your e-mail"
							type="email"
							{...register('email', { required: true })}
							className="add-input"
						/>
					</div>
					{errors.email && <span className="error-span">This field is required</span>}
				</div>
				<div className="input-item">
					<div className="input-title">Go Live Date</div>
					<div className="input-wrapper">
						<input placeholder="" type="date" {...register('date')} className="add-input" />
					</div>
				</div>
				<div className="text-area-wrapper">
					<div className="input-title">
						Description of what you want changed<p className="input-star">&nbsp;*</p>
					</div>
					<div className="input-text-area-wrapper input-wrapper">
						<textarea
							placeholder="Brief note about what you are looking for..."
							type="text"
							className="text-area"
							{...register('description', { required: true })}
						/>
					</div>
					{errors.description && <span className="error-span">This field is required</span>}
				</div>
				<div className="footer-form">
					<div className="submit-container button-footer">
						<input type="submit" className="submit-footer" value="Submit"></input>
					</div>
					<label className="attach-file button-footer">
						<span>Upload File</span>
						<input
							placeholder="Upload File"
							type="file"
							accept=".csv, .xls, .xlsx, .doc, .docx, .txt"
							className="add-input"
							onChange={handleFileChange}
						/>
					</label>
				</div>
			</form>
		</div>
	);
};

export default Changes;
