import React, { useEffect, useState } from 'react';
import SideBar from './pages/SideBar';
import Footer from './components/Footer';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import { getUserInformation, revertAllUser } from './slice/UserSlice';
import { configureResponseInterceptor, configureAxiosInterceptors } from './service/authenticationService';
import { useDispatch, useSelector } from 'react-redux';

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userLocal, setUserLocal] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const userInfo = useSelector(state => state.user.userInfo);
    const user = useSelector(state => state.user.user);
    const loginRejectedReason = useSelector(state => state.user.loginRejectedReason);

    useEffect(() => {
        configureResponseInterceptor();
        configureAxiosInterceptors();
    }, []);

    useEffect(() => {
        async function getUserInfo() {
            try {
                await dispatch(getUserInformation());
            } catch (error) {
                if (error.response && error.response.status === 402) {
                    localStorage.setItem('lastUrl', window.location.pathname + window.location.search);
                    navigate('/login');
                }
            }
        }

        const isLoggedOut = JSON.parse(window.localStorage.getItem('isLoggedOut'));
        if (isLoggedOut === false && !loginRejectedReason) {
            getUserInfo();
        }
    }, [dispatch, navigate, loginRejectedReason]);

    useEffect(() => {
        if (user?.statusCode && user.statusCode !== 200) {
            window.localStorage.clear();
            window.localStorage.setItem('isLoggedOut', 'true');
            localStorage.setItem('lastUrl', window.location.pathname + window.location.search);
            navigate('/login');
            return;
        }

        const unauthorized = JSON.parse(window.localStorage.getItem('unauthorized'));
        if (unauthorized) {
            window.localStorage.setItem('unauthorized', 'false');
            localStorage.setItem('lastUrl', window.location.pathname + window.location.search);
            navigate('/login');
            return;
        }

        const isLoggedOut = JSON.parse(window.localStorage.getItem('isLoggedOut'));
        if (!userInfo?.email && !user?.token && isLoggedOut !== false) {
            window.localStorage.clear();
            dispatch(revertAllUser());
            localStorage.setItem('lastUrl', window.location.pathname + window.location.search);
            navigate('/login');
            return;
        }

        if (window.location.pathname === '/') {
            navigate('/dashboard');
        }

        if(userInfo?.email) {
            setUserLocal(userInfo);
        }
    }, [dispatch, navigate, userInfo, user]);

    return (
        <>
            {userLocal && userInfo && (
                <div className="wrapper">
                    <SideBar
                        className="sidebar-wrapper"
                        menuOpen={menuOpen}
                        handleToggleMenu={() => setMenuOpen(!menuOpen)}
                    />
                    <div className={`${menuOpen ? 'hidden' : ''} content`}>
                        <Header className="header-wrapper" handleToggleMenu={() => setMenuOpen(!menuOpen)} />
                        <Outlet />
                    </div>
                    <Footer className="footer-wrapper" menuOpen={menuOpen} />
                </div>
            )}
        </>
    );
}

export default App;
