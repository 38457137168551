import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const revertAllUser = createAction('REVERT_ALL_USER');

export const cleanDeleteInfo = createAction('CLEAN_DELETE_INFO');

export const login = createAsyncThunk('users/login', async (userData, { rejectWithValue }) => {
    return axios
        .post(getApiEndpoint('users', 'login'), {
            email: userData.email,
            password: userData.password
        })
        .then(res => res.data).catch(error => {
            return rejectWithValue({ payload: error.response?.data, statusCode: error.response?.status });
        });
});

export const getUserInformation = createAsyncThunk(
    'users/getUserInformation',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(getApiEndpoint('users', 'info'));
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const deleteUserById = createAsyncThunk('users/delete', async ({ id }) => {
    return axios
        .delete(getApiEndpoint('users', `delete/${id}`))
        .then(res => res.data);
});

export const registerUser = createAsyncThunk('users/registerUser', async ({ id, userData }) => {
    return axios
        .post(getApiEndpoint('users', 'register'), {
            email: userData.email, password: userData.password, firstName: userData.firstName, lastName: userData.lastName, companyId: id
        })
        .then(res => res.data);
});

export const logout = createAsyncThunk('users/logout', async (email) => {
    try {
        return axios
            .post(getApiEndpoint('users', `revoke/${email}`))
            .then(res => res.data);
    } catch (error) {
        throw error;
    }
});

export const forgotPassword = createAsyncThunk('users/passwordreset', async (email) => {
    try {
        return await axios
            .get(getApiEndpoint('users', `passwordreset?email=${email}`));
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error('Error when trying to send link to reset password');
        } else {
            throw error;
        }
    }
});

export const resetPassword = createAsyncThunk(
    'users/resetPassword',
    async (data, { rejectWithValue }) => {
        const { password, userId, code } = data;
        try {
            return await axios.post(
                getApiEndpoint('users', 'newpassword'),
                {
                    userId: userId,
                    code: code,
                    password: password,
                }
            )
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const changePassword = createAsyncThunk(
    'users/reset',
    async (data, { rejectWithValue }) => {
        const { email, password, firstName, lastName } = data;
        try {
            return await axios.post(
                getApiEndpoint('users', 'reset'),
                {
                    email: email,
                    password: password,
                    firstName: firstName,
                    lastName: lastName
                }
            );
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const uploadProfileImage = createAsyncThunk('users/uploadprofileimage', async (file) => {
    return axios
        .post(getApiEndpoint('users', `uploadprofileimage`), file)
        .then(res => res.data);
    }
);

export const confirmPasswordKey = createAsyncThunk('users/confirmPasswordKey', async (keyCode) => {
    try {
        return true;
    } catch (error) {
        throw error;
    }
});

const initialState = {
    loading: false,
    user: {},
    isSuccess: false,
    isError: false,
    loginRejectedReason: null,
    userInfo: null,
    isLoggedOut: true,
    registerUserSuccess: false,
    registerUserError: false,
    deleteUserSuccess: false,
    deleteUserFailed: false
}

const userSlice = createSlice({
    name: 'dashboard',
    initialState,

    reducers: {
        reset: () => initialState,
    },

    extraReducers: builder => {
        builder.addCase(revertAllUser, (state) => {
            state.loading = false;
            state.isSuccess = false;
            state.userInfo = null;
            state.isLoggedOut = true;
            state.registerUserSuccess = false;
            state.registerUserError = false;
        });

        builder.addCase(cleanDeleteInfo, (state) => {
            state.deleteUserSuccess = false;
            state.deleteUserFailed = false;
        });

        builder.addCase(login.pending, state => {
            state.loading = true;
        });

        builder.addCase(login.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isSuccess = true;
            state.isError = false;
            state.loginRejectedReason = null;
            state.isLoggedOut = false;

            window.localStorage.setItem('authToken', JSON.stringify(action.payload));
        });

        builder.addCase(login.rejected, (state, action) => {
            state.loading = false;
            state.isSuccess = false;
            state.isError = true;
            state.loginRejectedReason = action.payload.statusCode;
            state.user = action.payload;
            window.localStorage.clear();
        });

        builder.addCase(getUserInformation.fulfilled, (state, action) => {
            state.isLoggedOut = false;
            state.userInfo = action.payload;
        });

        builder.addCase(getUserInformation.rejected, (state, action) => {
            state.userInfo = action.payload;

            window.localStorage.clear();
        });

        builder.addCase(registerUser.pending, state => {
            state.loading = true;
        });

        builder.addCase(registerUser.fulfilled, (state) => {
            state.loading = false;
            state.registerUserSuccess = true;
            state.registerUserError = false;
        });

        builder.addCase(registerUser.rejected, (state) => {
            state.loading = false;
            state.registerUserSuccess = false;
            state.registerUserError = true;
        });

        builder.addCase(logout.pending, state => {
            state.loading = true;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.loading = false;
            state.user = {};
            state.isSuccess = false;
            state.isError = false;
            state.loginRejectedReason = {};
            state.userInfo = null;
            state.isLoggedOut = true;

            window.localStorage.clear();
        });

        builder.addCase(logout.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(uploadProfileImage.pending, state => {
            state.loading = true;
        });

        builder.addCase(uploadProfileImage.fulfilled, (state) => {
            state.loading = false;
            state.isSuccess = true;
        });

        builder.addCase(uploadProfileImage.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(deleteUserById.pending, state => {
            state.loading = true;
        });
        
        builder.addCase(deleteUserById.fulfilled, state => {
            state.loading = false;
            state.deleteUserSuccess = true;
            state.deleteUserFailed = false;
        });

        builder.addCase(deleteUserById.rejected, (state) => {
            state.loading = false;
            state.deleteUserSuccess = false;
            state.deleteUserFailed = true;
        });
    }
})

export default userSlice.reducer;