import React, { useState, useEffect  } from "react";
import Checkboxes from '../Checkboxes';
import SingleCheckBox from '../SingleCheckBox';
import "./PhoneEditModal.css";
import { IoMdCloseCircle, IoMdCreate, IoIosSave } from "../../icons";
import { ActivationStatus, getActivationStatusNumber, getActivationStatusString } from '../../models/activationStatus';
import { BrandedActivationStatus, getBrandedActivationStatusNumber, getBrandedActivationStatusString } from '../../models/brandedActivationStatus';
import { ClientActivationStatus, getClientActivationStatusNumber, getClientActivationStatusString } from '../../models/clientActivationStatus';

const PhoneEditModal = ({ onCloseModal, selectedPhoneInfo, onUpdate }) => {

    const [phoneInfo, setPhoneInfo] = useState(selectedPhoneInfo);

    const [adminStatus, setAdminStatus] = useState({
        aTandT: getActivationStatusString(phoneInfo.aTandT),
        tmobile: getActivationStatusString(phoneInfo.tmobile),
        verizon: getActivationStatusString(phoneInfo.verizon)
    });

    const [brandedStatus, setBrandedStatus] = useState({
        aTandTBranded: getBrandedActivationStatusString(phoneInfo.aTandTBranded),
        tmobileBranded: getBrandedActivationStatusString(phoneInfo.tmobileBranded),
        verizonBranded: getBrandedActivationStatusString(phoneInfo.verizonBranded)
    });

    const [clientStatus, setClientStatus] = useState({
        aTandTStatus: getClientActivationStatusString(phoneInfo.aTandTStatus),
        tmobileStatus: getClientActivationStatusString(phoneInfo.tmobileStatus),
        verizonStatus: getClientActivationStatusString(phoneInfo.verizonStatus)
    });

    const handleAdminStatusChange = (key, value) => {
        setAdminStatus({
            ...adminStatus,
            [key]: getActivationStatusString(value)
        });

        setPhoneInfo({
            ...phoneInfo,
            [key]: getActivationStatusNumber(value)
        });
    };

    const handleBrandedStatusChange = (key, value) => {
        setBrandedStatus({
            ...brandedStatus,
            [key]: getBrandedActivationStatusString(value)
        });

        setPhoneInfo({
            ...phoneInfo,
            [key]: getBrandedActivationStatusNumber(value)
        });
    };

    const handleClientStatusChange = (key, value) => {
        setClientStatus({
            ...clientStatus,
            [key]: getClientActivationStatusString(value)
        });

        setPhoneInfo({
            ...phoneInfo,
            [key]: getClientActivationStatusNumber(value)
        });
    };

    const handleFtcStrikeChange = (value) =>  {
        setPhoneInfo(prevPhoneInfo => ({ ...prevPhoneInfo, ftcStrike: value, ftcStrikeDate: new Date()}));
    }

    useEffect(() => {
        setAdminStatus({
            aTandT: getActivationStatusString(phoneInfo.aTandT),
            tmobile: getActivationStatusString(phoneInfo.tmobile),
            verizon: getActivationStatusString(phoneInfo.verizon)
        });

        setBrandedStatus({
            aTandTBranded: getBrandedActivationStatusString(phoneInfo.aTandTBranded),
            tmobileBranded: getBrandedActivationStatusString(phoneInfo.tmobileBranded),
            verizonBranded: getBrandedActivationStatusString(phoneInfo.verizonBranded)
        });

        setClientStatus({
            aTandTStatus: getClientActivationStatusString(phoneInfo.aTandTStatus),
            tmobileStatus: getClientActivationStatusString(phoneInfo.tmobileStatus),
            verizonStatus: getClientActivationStatusString(phoneInfo.verizonStatus)
        });

    }, [phoneInfo]);

    return(
        <div className="phone-modal">
            <div className="phone-modal-content">
                <div className="phone-modal-header">
                    <div className="phone-header-title">
                        <IoMdCreate/>
                        <span className="title">Edit:</span>
                        <span className="title">{phoneInfo.tfn.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}</span>
                    </div>
                    <button className="phone-modal-close-button" onClick={() => onCloseModal()}>
                        <IoMdCloseCircle />
                    </button>
                </div>
                <div className="area-settings">
                    <span className="title">Region</span>
                    <div className="region">
                        <input
                            placeholder="California (CA)"
                            type="text"
                            value={`${phoneInfo.region} (${phoneInfo.state})`}
                            className="input"
                            readOnly
                        />
                    </div>
                </div>
                <div className="edge-container">
                    <span className="name">Admin Status</span>
                    <div className="checkbox-group">
                        <div className="checkbox-section">
                            <span className="name">AT&T:</span>
                            <Checkboxes
                                options={Object.values(ActivationStatus)}
                                selectedOption={adminStatus.aTandT}
                                onOptionChange={(value) => handleAdminStatusChange("aTandT", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">T-Mobile:</span>
                            <Checkboxes
                                options={Object.values(ActivationStatus)}
                                selectedOption={adminStatus.tmobile}
                                onOptionChange={(value) => handleAdminStatusChange("tmobile", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">Verizon:</span>
                            <Checkboxes
                                options={Object.values(ActivationStatus)}
                                selectedOption={adminStatus.verizon}
                                onOptionChange={(value) => handleAdminStatusChange("verizon", value)}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="edge-container">
                    <span className="name">Branded Status</span>
                    <div className="checkbox-group">
                        <div className="checkbox-section">
                            <span className="name">AT&T:</span>
                            <Checkboxes
                                options={Object.values(BrandedActivationStatus)}
                                selectedOption={brandedStatus.aTandTBranded}
                                onOptionChange={(value) => handleBrandedStatusChange("aTandTBranded", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">T-Mobile:</span>
                            <Checkboxes
                                options={Object.values(BrandedActivationStatus)}
                                selectedOption={brandedStatus.tmobileBranded}
                                onOptionChange={(value) => handleBrandedStatusChange("tmobileBranded", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">Verizon:</span>
                            <Checkboxes
                                options={Object.values(BrandedActivationStatus)}
                                selectedOption={brandedStatus.verizonBranded}
                                onOptionChange={(value) => handleBrandedStatusChange("verizonBranded", value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="edge-container">
                    <span className="name">Client Status</span>
                    <div className="checkbox-group">
                        <div className="checkbox-section">
                            <span className="name">AT&T:</span>
                            <Checkboxes
                                options={Object.values(ClientActivationStatus)}
                                selectedOption={clientStatus.aTandTStatus}
                                onOptionChange={(value) => handleClientStatusChange("aTandTStatus", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">T-Mobile:</span>
                            <Checkboxes
                                options={Object.values(ClientActivationStatus)}
                                selectedOption={clientStatus.tmobileStatus}
                                onOptionChange={(value) => handleClientStatusChange("tmobileStatus", value)}
                            />
                        </div>

                        <div className="checkbox-section">
                            <span className="name">Verizon:</span>
                            <Checkboxes
                                options={Object.values(ClientActivationStatus)}
                                selectedOption={clientStatus.verizonStatus}
                                onOptionChange={(value) => handleClientStatusChange("verizonStatus", value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="ftc-container">
                    <div className="checkbox-group">
                        <div className="checkbox-section">
                            <span className="name">FTC Strike Status:</span>
                            <SingleCheckBox
                                title={"Strike"}
                                selectedOption={phoneInfo.ftcStrike}
                                onOptionChange={(value) => handleFtcStrikeChange(value)}
                            />
                        </div>
                    </div>
                </div>
                

                <div className="action-button">
                    <button type="submit" className="save-button" onClick={() => onUpdate(phoneInfo)}>
                        Save
                        <IoIosSave className="save-icon"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PhoneEditModal;