import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { getApiEndpoint } from '../utils/apiUtils';

export const uploadClientFeedback = createAsyncThunk('clientfeedback/uploadClientFeedback', async ({ file }) => {
    return axios
        .post(getApiEndpoint('clientfeedback', 'upload'), file)
        .then(res => res.data);
});

export const getClientFeedbacks = createAsyncThunk('clientfeedback/getClientFeedbacks', async (id) => {
    return axios
        .get(getApiEndpoint('clientfeedback', `list/${id}`))
        .then(res => res.data);
});

export const approvedClientFeedbacks = createAsyncThunk('clientfeedback/approvedClientFeedbacks', async (id) => {
    return axios
        .get(getApiEndpoint('clientfeedback', `approvedClientFeedbacks/${id}`))
        .then(res => res.data);
});

export const reproveNumber = createAsyncThunk('clientfeedback/reproveNumber', async (id) => {
    return axios
        .post(getApiEndpoint('clientfeedback', `reprove/${id}`))
        .then(res => res.data);
});

export const approveNumber = createAsyncThunk('clientfeedback/approveNumber', async (id) => {
    return axios
        .post(getApiEndpoint('clientfeedback', `approve/${id}`))
        .then(res => res.data);
});

export const reproveAll = createAsyncThunk('clientfeedback/reproveAll', async (id) => {
    return axios
        .post(getApiEndpoint('clientfeedback', `reproveAll/${id}`))
        .then(res => res.data);
});

export const approveAll = createAsyncThunk('clientfeedback/approveAll', async (id) => {
    return axios
        .post(getApiEndpoint('clientfeedback', `approveAll/${id}`))
        .then(res => res.data);
});

export const resetInfo = createAction('clientfeedback/resetInfo');

const clientFeedback = createSlice({
    name: 'clientFeedback',
    initialState: {
        loading: false,
        uploadResult: null,
        clientFeedbacks: [],
        isError: false,
        isUploadError: false
    },

    extraReducers: builder => {
        builder.addCase(uploadClientFeedback.pending, state => {
            state.loading = true;
        });

        builder.addCase(uploadClientFeedback.fulfilled, (state, action) => {
            state.loading = false;
            state.uploadResult = action.payload;
            state.isUploadError = false;
        });

        builder.addCase(uploadClientFeedback.rejected, (state) => {
            state.loading = false;
            state.uploadResult = null;
            state.isUploadError = true;
        });

        builder.addCase(getClientFeedbacks.pending, state => {
            state.loading = true;
            state.isError = false;
        });

        builder.addCase(getClientFeedbacks.fulfilled, (state, action) => {
            state.loading = false;
            state.clientFeedbacks = action.payload;
            state.isError = false;
        });

        builder.addCase(getClientFeedbacks.rejected, (state) => {
            state.loading = false;
            state.clientFeedbacks = [];
            state.isError = true;
        });

        builder.addCase(approvedClientFeedbacks.pending, state => {
            state.loading = true;
            state.isError = false;
        });

        builder.addCase(approvedClientFeedbacks.fulfilled, (state, action) => {
            state.loading = false;
            state.clientFeedbacks = action.payload;
            state.isError = false;
        });

        builder.addCase(approvedClientFeedbacks.rejected, (state) => {
            state.loading = false;
            state.clientFeedbacks = [];
            state.isError = true;
        });

        builder.addCase(resetInfo, state => {
			state.loading = false;
            state.isError = false;
            state.uploadResult = null;
            state.isUploadError = false;
        });

        builder.addCase(reproveNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(reproveNumber.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(reproveNumber.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(approveNumber.pending, state => {
            state.loading = true;
        });

        builder.addCase(approveNumber.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(approveNumber.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(reproveAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(reproveAll.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(reproveAll.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });

        builder.addCase(approveAll.pending, state => {
            state.loading = true;
        });

        builder.addCase(approveAll.fulfilled, (state) => {
            state.loading = false;
            state.isError = false;
        });

        builder.addCase(approveAll.rejected, (state) => {
            state.loading = false;
            state.isError = true;
        });
    }
});

export default clientFeedback.reducer;
