import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { searchClients, getUserCompanyId } from '../../slice/dashboardSlice';
import { approvedClientFeedbacks } from '../../slice/clientFeedbackSlice';
import OverusedTable from '../../components/OverusedTable';
import Loader from '../../components/Loader';
import { getUserInformation } from '../../slice/UserSlice';
import DisplayQuantity from '../../components/DisplayQuantity';
import CardsNumbers from '../../components/CardsNumbers';
import StatisticsRegion from '../../components/StatisticsRegion';
import ListCharts from '../../components/ListCharts';
import { IoIosSearch } from '../../icons';
import Dropdown from '../../components/Dropdown';
import useSorter from '../../service/sorterService';

const Dasboard = () => {
    const [page, setCurrentPage] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(state => state.user.userInfo);
    const data = useSelector(state => state.dashboard.data);
    const loading = useSelector(state => state.dashboard.loading);
    const totalRegisters = useSelector(state => state.dashboard.totalRegisters);
    const [inputValue, setInputValue] = useState('');
    const [status, setStatus] = useState(null);
    const [clientSearchInfo, setClientSearchInfo] = useState('Business Name');
    const [filterApplied, setFilterApplied] = useState(false);
    const { sortingOptions, resetSorter } = useSorter();
    const [pageSize, setPageSize] = useState(100);

    const fetchDashboardData = async () => {
        await dispatch(searchClients({ page: page, pageSize: pageSize, sorterInfo: sortingOptions.currentSorter }));
    };

    useEffect(() => {
        if (user?.admin) {
            fetchDashboardData();
            return;
        }

        dispatch(getUserCompanyId())
    }, [dispatch, page, pageSize]);

    useEffect(() => {
        resetSorter();
    }, []);

    useEffect(() => {
        dispatch(getUserInformation());
    }, []);

    const setPage = value => {
        setCurrentPage(page + value);
    };

    const redirect = id => {
        if (user?.admin) {
            navigate(`/view/${id}/edit`);
        }
    };

    const onKeyDown = event => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        handleSearchClient();
        setFilterApplied(true);
    };

    const reset = () => {
        setInputValue('');
        setStatus(null);
        setClientSearchInfo('Business Name');
        setFilterApplied(false);
        setCurrentPage(0);
        handleSearchClient();
    };

    const isAdmin = () => {
        return user?.admin ?? false;
    };

    const hasData = () => {
        return data?.length > 0;
    };

    const handleSearchClient = () => {
        dispatch(
            searchClients({
                page: page,
                pageSize: pageSize,
                clientName: clientSearchInfo === 'Business Name' ? inputValue : '',
                callerNumber: clientSearchInfo === 'Caller Number' ? inputValue : '',
                status: status || '',
                sorterInfo: sortingOptions.currentSorter
            })
        );
    };

    return (
        <div className="content-wrapper">
            {isAdmin() ? (
                <div className="admin-dashboard">
                    <h2>Client Dashboard</h2>
                    {(hasData() && loading === false) || filterApplied ? (
                        <>
                            <DisplayQuantity />
                            <div className="search-container">
                                <div className="search-wrapper">
                                    <div className="input-container">
                                        <div className="client-dropdown">
                                            <Dropdown
                                                options={['Business Name', 'Caller Number']}
                                                selectedOption={clientSearchInfo}
                                                setSelectedOption={setClientSearchInfo}
                                                useDefaultOption={false}
                                            />
                                        </div>
                                        <input
                                            placeholder="Enter text filter by..."
                                            type="text"
                                            className="search-input"
                                            value={inputValue}
                                            onChange={e => setInputValue(e.target.value)}
                                            onKeyDown={onKeyDown}
                                        />
                                        <div className="client-dropdown">
                                            <Dropdown
                                                options={['Active', 'Inactive', 'Churned']}
                                                selectedOption={status}
                                                setSelectedOption={setStatus}
                                            />
                                        </div>
                                    </div>
                                    <div className="action-buttons">
                                        <button onClick={reset} className="filter-action-button">
                                            <span className="filter-icon"></span>Reset
                                        </button>
                                        <button onClick={handleSearch} className="filter-action-button">
                                            <span className="filter-icon">
                                                <IoIosSearch />
                                            </span>{' '}
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="searching-result-dropdown">
                                    <span className="search-text">Results Per Page:</span>
                                    <Dropdown
                                        options={[10, 25, 50, 100]}
                                        selectedOption={pageSize}
                                        setSelectedOption={setPageSize}
                                        useDefaultOption={false}
                                    />
                                </div>

                                {data?.length > 0 ? (
                                    <div className="dash-table">
                                        <OverusedTable
                                            config={{
                                                businessName: { title: 'Business Name', type: 'text' },
                                                callerNumber: { title: 'Caller Number' },
                                                status: { title: 'Status', calculateCell: status => { } },
                                                state: { title: 'State' }
                                            }}
                                            redirect={redirect}
                                            data={data}
                                            page={page}
                                            pageSize={pageSize}
                                            setPage={setPage}
                                            totalRegisters={totalRegisters}
                                            onSort={handleSearchClient}
                                        />
                                    </div>
                                ) : (
                                    <div className="empty">No companies were found</div>
                                )}
                            </div>
                        </>
                    ) : loading === true ? (
                        <Loader />
                    ) : (
                        <div className="empty">No companies were found</div>
                    )}
                </div>
            ) : (
                <div className="charts">
                    <CardsNumbers />
                    <StatisticsRegion />
                    <ListCharts />
                </div>
            )}
        </div>
    );
};

export default Dasboard;
