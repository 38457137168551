import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import ReactDOMServer from 'react-dom/server';
import Indicator from '../../Indicator.svg';

const HalfPieChart = ({ value, percentage, width, height }) => {
    const pieChartRef = useRef();
    const data = [
        { key: 1, value: 50 },
        { key: 2, value: 20 },
        { key: 3, value: 15 },
        { key: 4, value: 15 }
    ];
    const colors = ['#F56666', '#F2C056', '#5FAEE3', '#3ED48C'];

    useEffect(() => {
        createChart();
    }, []);

    const calculeRotationAngle = () => {
        if (percentage) {
            return (value / 100) * 220 - 220;
        }
        return calculateRotationAngleValue(value);
    };

    const calculateRotationAngleValue = (value) => {
        if (value === 0) {
          return 0;
        }
        
        if (value >= 1 && value <= 9) {
          return -20 * value - 10;
        }

        return -220;
    };

    const calculateAngle = (value) => {
        if (value === 0) {
          return 98;
        } 

        if (value >= 10) {
          return 2;
        } 
        
        if (value >= 5) {
          return 45 - (value - 5) * 10;
        }

        return 100 - (value * 10) - 7;
    };

    const createChart = () => {
        const radius = Math.min(width, height) / 2;

        const arc = d3
            .arc()
            .cornerRadius(15)
            .innerRadius(radius - 30)
            .outerRadius(radius);

        const pie = d3
            .pie()
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2)
            .padAngle(0.02)
            .sort(null)
            .value(d => d.value);

        const pie2 = d3
            .pie()
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2)
            .sort(null)
            .value(d => d.value);

        const arc2 = d3
            .arc()
            .cornerRadius(10)
            .outerRadius(radius - 45);

        const color = d3
            .scaleOrdinal()
            .domain(data?.map(d => d.key))
            .range(colors);

        const svg = d3
            .select(pieChartRef.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height / 2)
            .attr('viewBox', [-width / 2, -height / 1.8, width, height / 1.7])
            .attr('style', 'max-width: 100%; height: auto;');

        svg.append('g')
            .selectAll()
            .data(pie2([{ name: 'border', value: 1 }]))
            .join('path')
            .attr('stroke', '#E1E1E1')
            .attr('stroke-dasharray', '5,20')
            .attr('stroke-width', 5)
            .attr('fill', 'transparent')
            .attr('d', arc2);

        svg.append('g')
            .selectAll()
            .data(pie(data))
            .join('path')
            .attr('fill', d => color(d.data?.key))
            .attr('d', arc);

        const foreignObjectWidth = 100;
        const foreignObjectHeight = 100;

        const x = -foreignObjectWidth / 2;
        const y = -foreignObjectHeight / 1.2;

        svg.append('g')
            .attr('text-anchor', 'middle')
            .append('foreignObject')
            .attr('width', foreignObjectWidth)
            .attr('height', foreignObjectHeight)
            .attr('x', x)
            .attr('y', y)
            .html(() => {
                return ReactDOMServer.renderToStaticMarkup(
                    <svg width={foreignObjectWidth} height={foreignObjectHeight}>
                        <image
                            xlinkHref={Indicator}
                            width={foreignObjectWidth}
                            height={foreignObjectHeight}
                            x={0}
                            y={0}
                            transform={`rotate(${calculeRotationAngle()} 50 50)`}
                        />
                    </svg>
                );
            });

        let valueAngle = value > 50 ? value - 2 : value + 2;
        if (!percentage) {
            valueAngle = calculateAngle(value);
        }

        const ang = (valueAngle / 100) * Math.PI;
        const scale = 0.95;
        const cx = -Number(radius * Math.cos(ang)) * scale;
        const cy = -Number(radius * Math.sin(ang)) * scale;

        const thresholds = [51, 71, 86, 100];

        const colorScale = d3.scaleThreshold().domain(thresholds).range(colors);

        svg.append('circle')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('r', 20)
            .attr('fill', 'white')
            .attr('stroke', colorScale(valueAngle))
            .attr('stroke-width', 16);
    };
    return <div ref={pieChartRef} />;
};

export default HalfPieChart;
