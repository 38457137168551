import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { FaTrash, IoMdCreate } from '../../icons';
import { insertConfig, getConfigsByCompany, deleteConfig } from '../../slice/reportsSlice';
import './Reports.css';
import toast, { Toaster } from 'react-hot-toast';
import { format } from 'date-fns';

const Reports = () => {
  const [frequencies, setFrequencies] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editingFrequencies, setEditingFrequencies] = useState([]);
  const [editingRecipient, setEditingRecipient] = useState('');
  const [recipientError, setRecipientError] = useState(false);
  const [frequencyError, setFrequencyError] = useState(false);
  const dispatch = useDispatch();
  const configs = useSelector(state => state.reports.configs);

  useEffect(() => {
    dispatch(getConfigsByCompany());
  }, [dispatch]);

  const handleFrequencyChange = (e) => {
    const value = e.target.value;
    setFrequencies((prev) =>
      prev.includes(value) ? prev.filter((f) => f !== value) : [...prev, value]
    );
    setFrequencyError(false);
  };

  const handleRecipientsChange = (tags) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = tags.filter(tag => !emailRegex.test(tag));

    if (invalidEmails.length > 0) {
      setRecipientError(true);
      toast.error('Please enter valid email addresses only!', {
        position: 'top-center',
        duration: 3000
      });
    } else {
      setRecipients(tags);
      setRecipientError(false);
    }
  };


  const handleSave = () => {
    if (recipients.length === 0) {
      setRecipientError(true);
    }
    if (frequencies.length === 0) {
      setFrequencyError(true);
    }
    if (recipients.length === 0 || frequencies.length === 0) {
      toast.error('fill the fields correctly!', {
        position: 'top-center',
        duration: 3000
      });
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const invalidEmails = recipients.filter(recipient => !emailRegex.test(recipient));

    if (invalidEmails.length > 0) {
      setRecipientError(true);
      toast.error('Please enter valid email addresses only!', {
        position: 'top-center',
        duration: 3000
      });
      return;
    }
    const newConfig = {
      daily: frequencies.includes('daily'),
      weekly: frequencies.includes('weekly'),
      monthly: frequencies.includes('monthly'),
      recipients: recipients
    };

    dispatch(insertConfig(newConfig)).then(() => {
      setFrequencies([]);
      setRecipients([]);
      dispatch(getConfigsByCompany());
      toast.success('Report saved successfully!', {
        position: 'top-center',
        duration: 3000
      });
    });
  };

  const handleEdit = (config) => {
    setEditingId(config.id);
    setEditingFrequencies([
      ...(config.daily ? ['daily'] : []),
      ...(config.weekly ? ['weekly'] : []),
      ...(config.monthly ? ['monthly'] : [])
    ]);
    setEditingRecipient(config.userEmail);
  };

  const handleUpdate = () => {
    const updatedConfig = {
      id: editingId,
      daily: editingFrequencies.includes('daily'),
      weekly: editingFrequencies.includes('weekly'),
      monthly: editingFrequencies.includes('monthly'),
      recipients: [editingRecipient]
    };
    dispatch(insertConfig(updatedConfig)).then(() => {
      setEditingId(null);
      setEditingFrequencies([]);
      setEditingRecipient('');
      dispatch(getConfigsByCompany());
      toast.success('Report updated successfully!', {
        position: 'top-center',
        duration: 3000
      });
    });
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setEditingFrequencies([]);
    setEditingRecipient('');
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this configuration?");
    if (confirmDelete) {
      await dispatch(deleteConfig(id));
      dispatch(getConfigsByCompany());
    }
  };

  const handleEditFrequencyChange = (e) => {
    const value = e.target.value;
    setEditingFrequencies((prev) =>
      prev.includes(value) ? prev.filter((f) => f !== value) : [...prev, value]
    );
  };

  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'dd/MM/yyyy');
    } catch (error) {
      return '';
    }
  };

  const isSaveDisabled = recipientError || frequencyError || recipients.length === 0 || frequencies.length === 0;

  return (
    <>
      <Toaster />
      <div className="reports-container">
        <h2>Reports</h2>
        <div className="reports-form">
          <label>
            Frequency:
            <div className={`checkbox-group ${frequencyError ? 'error' : ''}`}>
              <label>
                <input
                  type="checkbox"
                  value="daily"
                  checked={frequencies.includes('daily')}
                  onChange={handleFrequencyChange}
                  disabled={editingId !== null}
                />
                Daily
              </label>
              <label>
                <input
                  type="checkbox"
                  value="weekly"
                  checked={frequencies.includes('weekly')}
                  onChange={handleFrequencyChange}
                  disabled={editingId !== null}
                />
                Weekly
              </label>
              <label>
                <input
                  type="checkbox"
                  value="monthly"
                  checked={frequencies.includes('monthly')}
                  onChange={handleFrequencyChange}
                  disabled={editingId !== null}
                />
                Monthly
              </label>
            </div>
          </label>
          <label>
            Recipients:
            <TagsInput
              value={recipients}
              onChange={handleRecipientsChange}
              inputProps={{ placeholder: 'Enter email and press enter', className: recipientError ? 'error' : '' }}
              className={recipientError ? 'error' : ''}
              maxTags={10}
            />
          </label>
          <button onClick={handleSave} className={`save-button ${isSaveDisabled ? 'disabled' : ''}`} disabled={isSaveDisabled}>Save</button>
        </div>
        {configs.length > 0 && (
          <div className="history-container">
            <h3>History</h3>
            <table className="reports-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Recipient</th>
                  <th>D</th>
                  <th>W</th>
                  <th>M</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {configs.map((entry, index) => (
                  <tr key={index}>
                    <td>{formatDate(entry.changedAt)}</td>
                    <td>{entry.userEmail}</td>
                    <td>
                      {editingId === entry.id ? (
                        <input
                          type="checkbox"
                          value="daily"
                          checked={editingFrequencies.includes('daily')}
                          onChange={handleEditFrequencyChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={entry.daily}
                          readOnly
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {editingId === entry.id ? (
                        <input
                          type="checkbox"
                          value="weekly"
                          checked={editingFrequencies.includes('weekly')}
                          onChange={handleEditFrequencyChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={entry.weekly}
                          readOnly
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {editingId === entry.id ? (
                        <input
                          type="checkbox"
                          value="monthly"
                          checked={editingFrequencies.includes('monthly')}
                          onChange={handleEditFrequencyChange}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={entry.monthly}
                          readOnly
                          disabled
                        />
                      )}
                    </td>
                    <td>
                      {editingId === entry.id ? (
                        <div>
                          <button onClick={handleUpdate} className="small-button">Update</button>
                          <button onClick={handleCancelEdit} className="small-button cancel-button">Cancel</button>
                        </div>
                      ) : (
                        <>
                          <IoMdCreate
                            onClick={() => handleEdit(entry)}
                            className="icon"
                          />
                          <FaTrash
                            onClick={() => handleDelete(entry.id)}
                            className="icon"
                          />
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default Reports;
