import { React, useEffect, useState } from 'react';
import './UserManagement.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from '../../slice/dashboardSlice';
import { deleteUserById, cleanDeleteInfo } from '../../slice/UserSlice';
import { FaTrash } from '../../icons';
import Loader from '../../components/Loader';
import PopupConfirmation from '../../components/PopupConfirmation';
import toast, { Toaster } from 'react-hot-toast';

const UserManagement = () => {
	const dispatch = useDispatch();

	const user = useSelector(state => state.user.userInfo);
	const data = useSelector(state => state.dashboard.users);
	const loading = useSelector(state => state.dashboard.loading);
	const deleteUserSuccess = useSelector(state => state.user.deleteUserSuccess);
	const deleteUserFailed = useSelector(state => state.user.deleteUserFailed);

	const [popupConfirmation, setPopupConfirmation] = useState(false);
	const [id, setId] = useState('');

	const fetchData = async () => {
		await dispatch(getUsersList());
	};

	useEffect(() => {
		dispatch(cleanDeleteInfo());
		if (user?.admin) {
			fetchData();
		}
	}, []);

	const hasData = () => {
		return data?.length > 0;
	};

	const handleDeletePopup = id => {
		setId(id);
		setPopupConfirmation(true);
	};

	const handleDeleteClick = async () => {
		await dispatch(deleteUserById({ id }));
		setPopupConfirmation(false);
		await fetchData();
	}

	useEffect(() => {
        if (deleteUserFailed) {
            toast.error('The User has not been deleted.', {
                position: 'top-center',
                duration: 3000
            });

			dispatch(cleanDeleteInfo());
        }
    }, [deleteUserFailed]);

    useEffect(() => {
        if (deleteUserSuccess) {
            toast.success('The User has been successfully deleted.', {
                position: 'top-center',
                duration: 3000
            });

			dispatch(cleanDeleteInfo());
        }
    }, [deleteUserSuccess]);

	return (
		<div className="content-wrapper">
			<Toaster position="top-right" reverseOrder={false} />
			<div className="admin-dashboard">
				<h2>User Management</h2>
				{(hasData() && loading === false) ? (
					<>
						<div className="search-container">
							<div className="dash-table">
								<div className="table">
									<table className="dash-table">
										<thead>
											<tr>
												<th>Name</th>
												<th>E-mail</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{data?.map(obj => {
												const { id, firstName, lastName, email } = obj;
												return (
													<tr key={id} >
														<td>{`${firstName} ${lastName}`}</td>
														<td>{email}</td>
														<td className='center-icon' onClick={() => handleDeletePopup(id)}><FaTrash /></td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</>
				) : loading === true ? (
					<Loader />
				) : (
					<div className="empty">No Users were found</div>
				)}
			</div>
			<div>
				{popupConfirmation &&
					(
						(
							<PopupConfirmation
								onClosePopup={() => setPopupConfirmation(false)}
								onDelete={handleDeleteClick}
								title={'Are you sure you want to delete this user?'}
							/>
						)
					)
				}
			</div>
		</div>
	);
};

export default UserManagement;