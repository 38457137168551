import { React } from 'react';
import './SideBarMenu.css';

import {
    FaHome,
    BiSolidAddToQueue,
    BiSolidPlusCircle,
    IoIosEye,
    BiSolidPhoneCall,
    PiFileArrowDownFill,
    IoIosLogOut,
    BsFillPhoneVibrateFill,
    BsSearch,
    FaLifeRing,
    FaRegChartBar,
    IoIosPerson,
    BsTelephoneForwardFill,
    BsDeviceSsd
} from '../../icons';

import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../slice/UserSlice';
import { IoIosKey } from 'react-icons/io';

const SideBarMenu = ({ menuOpen, handleToggleMenu }) => {
    const user = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLogout = async () => {
        window.localStorage.setItem('isLoggedOut', 'true');
        
        if (user && user.email) {
            await dispatch(logout(user.email));
            navigate('/login');
            window.localStorage.removeItem('lastUrl');
        }
    };

    const isAdmin = () => {
        return user?.admin ?? false
    };

    return (
        <div className="menu-wrapper">
            {user ? (
                isAdmin() ? (
                    <>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/dashboard'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'dashboard' : isActive ? 'active dashboard' : 'dashboard'
                            }>
                            <div className="dashboard-info">
                                <FaHome />
                                Client Dashboard
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/user-management'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'menu-item active' : 'menu-item'
                            }>
                            <IoIosPerson />
                            User Management
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/add-new'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'menu-item active' : 'menu-item'
                            }>
                            <BiSolidAddToQueue />
                            Add New Client Record
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/add-new-user'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'menu-item active' : 'menu-item'
                            }>
                            <BiSolidPlusCircle />
                            Add New User By Client
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/view'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <IoIosEye />
                            View / Modify Existing Client
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/phone'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BiSolidPhoneCall />
                            Telephone Number Search
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/upload'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <PiFileArrowDownFill />
                            Tools
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/manage-devices'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BsDeviceSsd />
                            Manage Devices
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/test-phone-numbers'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BsTelephoneForwardFill />
                            TFN Check Dashboard
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/user-configurations'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <IoIosKey />
                            User Configurations
                        </NavLink>
                            <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/reports'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <FaRegChartBar />
                            Reports
                        </NavLink>
                    </>
                ) : (
                    <>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/dashboard'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'dashboard' : isActive ? 'active dashboard' : 'dashboard'
                            }>
                            <div className="dashboard-info">
                                <FaHome />
                                Dashboard
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/pure-caller-id'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BsFillPhoneVibrateFill />
                            Pure Caller ID
                        </NavLink>
                        <NavLink
                            to={'/pure-branded-caller-id'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BiSolidPhoneCall />
                            Pure Branded Caller ID
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/phone'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <BsSearch />
                            Search number
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/changes'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <FaLifeRing />
                            Move's / Add's / Changes
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/reports'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <FaRegChartBar />
                            Reports
                        </NavLink>
                        <NavLink
                            onClick={() => menuOpen && handleToggleMenu(false)}
                            to={'/user-configurations'}
                            className={({ isActive, isPending }) =>
                                isPending ? 'menu-item' : isActive ? 'active menu-item' : 'menu-item'
                            }>
                            <IoIosKey />
                            User Configurations
                        </NavLink>
                    </>
                )
            ) : (
                <></>
            )}
            <div className="menu-line"></div>
            <button className="logout" onClick={onLogout}>
                <IoIosLogOut />
                <h3 className="logout-title">Log out</h3>
            </button>
        </div>
    );
};

export default SideBarMenu;