const defaultSorter = 'orderbyasc=companyname';
let sortingOptions = {
    businessName: false,
    callerNumber: false,
    status: false,
    state: false,
    number: false,
    attstatus: false,
    verizonstatus: false,
    tmobilestatus: false,
    ftcstatus: false,
    currentSorter: defaultSorter
};

const useSorter = () => {
  const updateCurrentSorter = ({ sortBy, isAscending }) => {
    sortingOptions.currentSorter = isAscending ? `orderbyasc=${sortBy}` : `orderbydesc=${sortBy}`;
  };

  const setSortingOptions = (optionsToUpdate) => {
    Object.keys(optionsToUpdate).forEach((key) => {
      if (sortingOptions.hasOwnProperty(key)) {
        sortingOptions[key] = optionsToUpdate[key];
      }
    });
  };

  const resetSorter = () => {
    setSortingOptions({
      businessName: false,
      callerNumber: false,
      status: false,
      state: false,
      number: false,
      attstatus: false,
      verizonstatus: false,
      tmobilestatus: false,
      ftcstatus: false,
      currentSorter: defaultSorter
    });
  };

  return {
    sortingOptions,
    setSortingOptions,
    updateCurrentSorter,
    resetSorter,
  };
};

export default useSorter;